import { combineReducers } from 'redux';
import {
	SET_GAME,
	SET_GROUP,
	UPDATE_SCORE,
	UPDATE_TIME,
	RESET_STATS,
	SUM_SCORES,
} from './actions';

const initialState = {
	game: null,
	group: null,
};

function game(state = initialState.game, action) {
	switch (action.type) {
		case SET_GAME:
			return action.game;
		default:
			return state;
	}
}

function group(state = initialState.group, action) {
	switch (action.type) {
		case SET_GROUP:
			return action.group;
		case RESET_STATS:
			let newStats = {
				...state,
				score: 0,
				penalty: 0,
				bonus: 0,
				time: 0,
			};
			return newStats;
		case SUM_SCORES:
			let newScores = {
				...state,
				score: state.scoreCase1 + state.scoreCase2,
			};
			return newScores;
		case UPDATE_SCORE:
			let newScore = 0 || state.score + action.score;
			let newPenalty =
				action.typeOfScore === 'PENALTY'
					? 0 || state.penalty + action.score
					: state.penalty;
			let newBonus =
				action.typeOfScore === 'BONUS'
					? 0 || state.bonus + action.score
					: state.bonus;

			let newState = {
				...state,
				score: newScore,
				penalty: newPenalty,
				bonus: newBonus,
			};
			newState[`scoreCase${action.actualCase}`] = newScore;
			newState[`penaltyCase${action.actualCase}`] = newPenalty;
			newState[`bonusCase${action.actualCase}`] = newBonus;
			return newState;
		case UPDATE_TIME:
			let newState2 = {
				...state,
				time: action.time,
			};
			newState2[`timeCase${action.actualCase}`] = action.time;
			return newState2;
		default:
			return state;
	}
}

export default combineReducers({ game, group });
