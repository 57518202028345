import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import {
	alertError,
	modalAnswer,
	modalImage,
	modalDiagnostic,
} from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import img1 from '../../../assets/images/escenario-52-recortes-1.svg';
import img2 from '../../../assets/images/escenario-52-recortes-2.svg';
import img3 from '../../../assets/images/escenario-52-recortes-3.svg';
import img4 from '../../../assets/images/escenario-52-recortes-4.svg';
import img5 from '../../../assets/images/escenario-52-recortes-5.svg';
import img6 from '../../../assets/images/escenario-52-recortes-6.svg';
import crackIcon from '../../../assets/crack-icon.svg';
import crossIcon from '../../../assets/cross-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import paperIcon from '../../../assets/diagnostic-paper-icon.svg';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '6310cb3c0e9a26315a833398';
const scenarioId = '62d7fd1db260ea1d73de0d38';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [CHOICE, setCHOICE] = useState(null);

	const RIGHT_ANSWER = 5;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const FEEDBACKS = [
		'¡Esta es la peor respuesta de todas! La paciente puede tener perfectamente un problema reumatológico. <br><br>La mejor opción es: “Por un lado, la presencia de cambios fibróticos apicales junto a EPID nos tiene que hacer pensar en la posibilidad de una EAS subyacente en el contexto de fibroelastosis pleuroparenquimatosa. Por otro lado, dadas las características clínicas de sequedad y autoinmunidad presentes, el primer cuadro a descartar es un síndrome de Sjögren”.',
		'No es una mala respuesta… Con el estómago lleno se piensa mejor. Pero la opción top es: “Por un lado, la presencia de cambios fibróticos apicales junto a EPID nos tiene que hacer pensar en la posibilidad de una EAS subyacente en el contexto de fibroelastosis pleuroparenquimatosa. Por otro lado, dadas las características clínicas de sequedad y autoinmunidad presentes, el primer cuadro a descartar es un síndrome de Sjögren”.',
		'La recomendación es buena, pero el infectólogo seguramente no sabrá interpretar los resultados. Y te asaltará otra vez a la hora de comer… <br><br>La mejor respuesta es: “Por un lado, la presencia de cambios fibróticos apicales junto a EPID nos tiene que hacer pensar en la posibilidad de una EAS subyacente en el contexto de fibroelastosis pleuroparenquimatosa. Por otro lado, dadas las características clínicas de sequedad y autoinmunidad presentes, el primer cuadro a descartar es un síndrome de Sjögren”.',
		'Es un buen consejo: podría haber un cuadro reumatológico detrás y conviene descartarlo. A la paciente se le debería hacer una buena anamnesis y completar el estudio de autoinmunidad, realizar una prueba de Schirmer… <br><br>Pero la mejor respuesta es: “Por un lado, la presencia de cambios fibróticos apicales junto a EPID nos tiene que hacer pensar en la posibilidad de una EAS subyacente en el contexto de fibroelastosis pleuroparenquimatosa. Por otro lado, dadas las características clínicas de sequedad y autoinmunidad presentes, el primer cuadro a descartar es un síndrome de Sjögren”.',
		'Es una buena elección, ya que una opción sería que la paciente presentara síndrome de Sjögren con EPID asociada. Pero la mejor respuesta es: “Por un lado, la presencia de cambios fibróticos apicales junto a EPID nos tiene que hacer pensar en la posibilidad de una EAS subyacente en el contexto de fibroelastosis pleuroparenquimatosa. Por otro lado, dadas las características clínicas de sequedad y autoinmunidad presentes, el primer cuadro a descartar es un síndrome de Sjögren”.',
		'',
	];

	const formatFeedback = (feedback) => {
		return `
		<div class='d-flex'>
			<img src=${paperIcon} alt='test' class='align-self-start'/>
			<span>
				${feedback}
			</span>
		</div>
		<p></p>
		<div class='d-flex justify-content-center align-items-center'>
			<img src=${pruebaInnecesariaIcon} alt='test'/>
			<span class='modal-diagnostic-title txtRed' >
				No has ganado los 50 puntos.
			</span>	
		</div>`;
	};

	function checkAnswer() {
		if (RIGHT_ANSWER === CHOICE) {
			setIsCompleted(true);
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${crackIcon} class='modal-crack-icon' alt='test'/>
						<span class='title3 txtGreen align-self-end'>
							¡Has dado en la diana!<br>
							<span class='bigTextB bold txtGrey mt-1'>
								Los 
								<span class='modal-points'>
									+50 puntos</span>
								son tuyos.
							</span>
						</span>
					</div>`,
				btnText: 'SEGUIMOS CON EL CASO DE MANOLO',
				allowEscape: false,
			}).then(() => submitAnswer(false));
		} else {
			modalDiagnostic({
				html: formatFeedback(FEEDBACKS[CHOICE]),
				btnText: 'SEGUIMOS CON EL CASO DE MANOLO',
				containerClass: 'modal-diagnostic-container',
				disableEscape: true,
			}).then(() => submitAnswer(true));
		}
	}

	async function submitAnswer(PENALTY) {
		const finalScore = PENALTY ? 0 : 50;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, '', 2));
						updateStats({
							...group,
							score: group.score + finalScore,
							time: totalTime,
							lastVisited: '/diagnosticoc2',
						});
						navigate('/diagnosticoc2');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (CHOICE != null) checkAnswer();
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setCHOICE(isChecked ? idx : null);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='d-flex flex-column align-items-center'>
					<div className='mt-3 bigText'>
						<div>
							{
								'Echa una mano a tu compañero infectólogo. Dale el mejor consejo y gana '
							}
							<span className='destacadoVerde title4'>{'+50 puntos.'}</span>
						</div>
						<p className='txtBlue'>
							Solo tienes <span className='bold'>un intento.</span>
						</p>
					</div>
					<div className='row w-100 justify-content-center'>
						<div className='col-5 d-flex align-items-center'>
							<input
								type='checkbox'
								checked={CHOICE === 0}
								onChange={(event) => handleInputChange(event, 0)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img1 })}>
								<img
									src={img1}
									alt='img1'
									style={{ height: '124px', width: '267px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-10%', top: '50px' }}
								/>
							</div>
						</div>
						<div
							className='col-5 d-flex align-items-center position-relative'
							style={{ bottom: '30px' }}>
							<input
								type='checkbox'
								checked={CHOICE === 3}
								onChange={(event) => handleInputChange(event, 3)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img4 })}>
								<img
									src={img4}
									alt='img4'
									style={{ height: '106px', width: '264px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-7%', top: '45px' }}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-5 ms-5 ps-5 w-100 justify-content-center'>
						<div className='col-5 ms-3 d-flex align-items-center'>
							<input
								type='checkbox'
								checked={CHOICE === 1}
								onChange={(event) => handleInputChange(event, 1)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img2 })}>
								<img
									src={img2}
									alt='img2'
									style={{ height: '113px', width: '256px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-7%', top: '30px' }}
								/>
							</div>
						</div>
						<div
							className='col-5 d-flex align-items-center position-relative'
							style={{ bottom: '70px' }}>
							<input
								type='checkbox'
								checked={CHOICE === 4}
								onChange={(event) => handleInputChange(event, 4)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img5 })}>
								<img
									src={img5}
									alt='img5'
									style={{ height: '122px', width: '328px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-10%', top: '43px' }}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-4 w-100 justify-content-center'>
						<div className='col-5 d-flex align-items-center'>
							<input
								type='checkbox'
								checked={CHOICE === 2}
								onChange={(event) => handleInputChange(event, 2)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img3 })}>
								<img
									src={img3}
									alt='img3'
									style={{ height: '117px', width: '256px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-10%', top: '21px' }}
								/>
							</div>
						</div>
						<div
							className='col-5 d-flex align-items-center position-relative'
							style={{ bottom: '65px' }}>
							<input
								type='checkbox'
								checked={CHOICE === 5}
								onChange={(event) => handleInputChange(event, 5)}></input>
							<div
								className='d-flex ms-3'
								onClick={() => modalImage({ image: img6 })}>
								<img
									src={img6}
									alt='img6'
									style={{ height: '142px', width: '344px' }}
								/>
								<img
									src={crossIcon}
									alt='cross'
									className='btn-cross'
									style={{ left: '-13%', top: '60px' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/diagnosticoc2'
				className={`btn-trap btn-next ${
					CHOICE != null ? 'bgYellow' : 'bgGrey'
				}`}
				onClick={handleNext}>
				{'COMPROBAR'}
			</Link>
			<ScenarioBar activeCase={2} activeScenario={5} />
		</div>
	);
}

export default injectIntl(Test);
