import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { postAnswer } from '../../../api/answer';
import ScenarioBar from '../../../components/ScenarioBar';
import { alertError, modalAnswer, modalConfirm } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import supertestIcon from '../../../assets/supertest-icon.svg';
import crackIcon from '../../../assets/crack-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import remember from '../../../assets/remember-icon.svg';
import {
	getTimer,
	clearItem,
	caseIsActive,
	updateStats,
	getTimePenalty,
} from '../../../utils/helpers';
import { useTimer } from '../../../hooks/useTimer';

const quizId = '632c2a9301c338bfcec980f1';
const quiz1Id = '631762f41bb651fb18116cc7';
const quiz2Id = '6317633a1bb651fb18116cc8';

const scenarioId = '62d7fd36b260ea1d73de0d3b';

const defaultAnswers = {
	0: null,
	1: null,
};

function Scenario4Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [ACTIVE, setACTIVE] = useState(0);
	const [ANSWERS, setANSWERS] = useState(defaultAnswers);
	const RIGHT_ANSWERS = [3, 2];
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState([0, 0]);

	useTimer(
		() => {
			setTime((time) => ({ ...time, [ACTIVE]: time[ACTIVE] + 1 }));
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		{
			txt: 'Uno de estos marcadores no se relaciona con mayor riesgo de EPID-AR. ¿Cuál?',
			options: [
				'Epítopo compartido',
				'MUC5B',
				'HLA-B54',
				'HLA-DQB2*0601',
				'HLA-B40',
			],
		},
		{
			txt: 'Uno de los siguientes no se considera factor de riesgo de desarrollar EPID en la artritis reumatoide. ¿Cuál? ',
			options: [
				'Fumar',
				'Enfermedad agresiva',
				'Sexo binario femenino',
				'Titulación alta de ACPA',
				'Edad avanzada',
			],
		},
	];

	function checkAnswer() {
		setIsCompleted(true);
		if (RIGHT_ANSWERS.some((answer, idx) => answer != ANSWERS[idx])) {
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${pruebaInnecesariaIcon} class='align-self-center' alt='test'/>
						<span class='title3 txtRed'>
							¡Vaya!<br>
							<span class='bigTextB txtGrey mt-2'>
								Al menos una de las respuestas no era correcta. <br>Estas son las respuestas acertadas:
							</span>
						</span>
					</div>
					<div class='bigTextB mt-4'>
						<span class='bold'>
							Pregunta 1:
						</span>
						el marcador que no se relaciona con riesgo de EPID-AR<span class='superindex'>5-6</span> es
						<span class='medium'>
							HLA-DQB2*0601.
						</span>
					</div>
					<div class='bigTextB mt-3'>
						<span class='bold'>
							Pregunta 2:
						</span>
						no se considera factor de riesgo de desarrollar EPID en la artritis reumatoide el
						<span class='medium'>
							sexo binario femenino<span class='normal superindex'>1</span>.
						</span>
					</div>
					`,
				btnText: 'AVANZAR',
			}).then(() => submitAnswer(true));
		} else {
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${crackIcon} class='modal-crack-icon' alt='test'/>
						<span class='title3 txtGreen align-self-end'>
							¡Enhorabuena!<br>
							<span class='bigTextB txtGrey'>
							<span>¡Has acertado las 2 respuestas del supertest! <span class='superindex'>1,5,6</span>.</span>
								<br/>
								Acabas de ganar 
								<span class='modal-points'>
									+50 puntos.
								</span>
							</span>
						</span>
					</div>`,
				btnText: 'AVANZAR',
			}).then(() => submitAnswer());
		}
	}

	async function submitAnswer(PENALTY) {
		const finalScore = PENALTY ? 0 : 50;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time[0] + time[1],
			})
				.then((res) => {
					if (res.status === 201) {
						postAnswer({
							scenario: scenarioId,
							quiz: quiz1Id,
							group: group?._id,
							time: time[0],
							correct: RIGHT_ANSWERS[0] === ANSWERS[0],
						})
							.then((res) => {
								if (res.status === 201) {
									postAnswer({
										scenario: scenarioId,
										quiz: quiz2Id,
										group: group?._id,
										time: time[1],
										correct: RIGHT_ANSWERS[1] === ANSWERS[1],
									})
										.then((res) => {
											if (res.status === 201) {
												clearItem('total');
												let timePenalty = getTimePenalty(
													totalTime,
													group.score + finalScore
												);
												dispatch(updateScore(finalScore, 'BONUS', 2));
												dispatch(updateScore(-timePenalty, 'PENALTY', 2));
												updateStats({
													...group,
													score: group.score + finalScore - timePenalty,
													bonus: group.bonus + finalScore,
													penalty: group.penalty - timePenalty,
													time: totalTime,
													lastVisited: '/end',
													finished: true,
												});
												navigate('/end');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Ya has respondido este escenario!',
											});
										});
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage: 'Ya has respondido este escenario!',
								});
							});
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));

			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (ANSWERS[ACTIVE] === null)
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='bold'>
							Recuerda
							<span class='normal'>
								que debes seleccionar una <br/>
								respuesta en cada pregunta para poder avanzar.
							</span>
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
			});
		else {
			modalConfirm({
				text: '¿Seguro que quieres marcar esta respuesta?<br> Si confirmas, ya no podrás modificarla.<br><br>',
				titleClass: 'modal-confirm-titleB',
			}).then((res) => {
				if (res.isConfirmed) {
					if (ACTIVE < 1) setACTIVE(ACTIVE + 1);
					else if (!Object.keys(ANSWERS).some((idx) => ANSWERS[idx] === null))
						checkAnswer();
				}
			});
		}
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setANSWERS((ANSWERS) => ({ ...ANSWERS, [ACTIVE]: isChecked ? idx : null }));
	}

	function renderQuestion() {
		return (
			<div className='col-9 d-flex justify-content-center'>
				<div
					className='col-6 title4 pe-5 d-flex'
					style={{ borderRight: '2px solid #24284f' }}>
					<div className='pe-2'>{ACTIVE + 1}.</div> {QUESTIONS[ACTIVE].txt}
				</div>
				<div className='col-6 ps-5'>
					{QUESTIONS[ACTIVE].options.map((option, idx) => (
						<div key={idx} className='my-2'>
							<div className='bigText d-flex'>
								<div>
									<input
										type='checkbox'
										checked={ANSWERS[ACTIVE] === idx}
										onChange={(event) => handleInputChange(event, idx)}></input>
								</div>
								<div className='mx-2'>
									<span className='title4 txtGrey'>{idx + 1}. </span>
									{option}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-25 justify-content-center'>
					<div className='col-4 d-flex mt-5 justify-content-center align-items-center'>
						<div className='d-flex justify-content-center align-items-center'>
							<img
								className='supertest-icon'
								src={supertestIcon}
								alt='Supertest'
							/>
							<h1
								className='txtBlue position-relative'
								style={{ right: '35px' }}>
								Supertest
							</h1>
						</div>
					</div>
				</div>
				<div className='row mt-5 justify-content-center'>
					{renderQuestion()}
				</div>
			</div>
			<Link
				to='/end'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{ACTIVE < 1 ? 'AVANZAR' : 'COMPROBAR'}
			</Link>
			<ScenarioBar activeCase={2} activeScenario={8} />
		</div>
	);
}

export default injectIntl(Scenario4Test);
