import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { modalImage } from '../../../utils/logger';
import ScenarioBar from '../../../components/ScenarioBar';
import img1 from '../../../assets/intranet/intranet1.png';
import img2 from '../../../assets/intranet/intranet2.png';
import img3 from '../../../assets/intranet/intranet3.png';
import img4 from '../../../assets/intranet/intranet4.png';
import fullscreenIcon from '../../../assets/fullscreen.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

function Scenario2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [ACTIVE, setACTIVE] = useState(0);

	const CONTENT = [
		{
			title: 'Artritis reumatoide',
			tab: (
				<span>
					Artritis
					<br />
					reumatoide
				</span>
			),
			img: img1,
			text: [
				<span>Es una artritis inflamatoria.</span>,
				<span>
					Cursa típicamente como una poliartritis simétrica de pequeñas
					articulaciones con <span class='bold'>FR y ACPA positivo</span>,
					frecuentemente con elevación de reactantes de fase aguda.
				</span>,
				<span>
					A veces puede iniciarse de forma oligoarticular y evolucionar a
					poliarticular o
					<span class='bold marked'>
						cursar con una forma polimiálgica, sobre todo en edad más avanzada
						<span className='superindex bold'>1</span>.
					</span>
				</span>,
			],
		},
		{
			title: 'Artritis psoriásica',
			tab: (
				<span>
					Artritis
					<br />
					psoriásica
				</span>
			),
			img: img2,
			text: [
				<span>
					Puede presentarse de forma poliarticular, aunque la forma
					oligoarticular asimétrica es más frecuente.
				</span>,
				<span>
					Suele cursar con psoriasis cutánea o con antecedentes familiares de
					psoriasis.
				</span>,
				<span class='bold marked'>
					En alrededor del 15 % de los pacientes, puede preceder la artritis a
					la psoriasis cutánea
					<span className='superindex bold'>2</span>.
				</span>,
				<span>
					Además, puede asociar otras manifestaciones como entesitis, dactilitis
					o dolor lumbar inflamatorio.
				</span>,
			],
		},
		{
			title: 'Polimialgia reumática',
			tab: (
				<span>
					Polimialgia
					<br />
					reumática
				</span>
			),
			img: img3,
			text: [
				<span>
					Es un trastorno inflamatorio caracterizado por dolor y rigidez de
					predominio matinal, que afecta sobre todo a la cintura escapular y, en
					menor grado, a la cintura pélvica.
				</span>,
				<span className='marked'>
					Se caracteriza por elevación de reactantes de fase aguda. La sinovitis
					periférica puede aparecer en algunos pacientes
					<span className='superindex'>2</span>.
				</span>,
				<span>
					El inicio de los síntomas suele ser rápido, generalmente durante unos
					pocos días.
				</span>,
			],
		},
		{
			title: 'Artritis por depósito de cristales de pirofosfato cálcico',
			tab: (
				<span>
					Artritis por depósito de
					<br />
					cristales de pirofosfato cálcico
				</span>
			),
			img: img4,
			text: [
				<span>
					Es una artritis inflamatoria que se caracteriza por la formación y el
					depósito de cristales de pirofosfato cálcico en el cartílago
					articular, donde llegan a formar calcificaciones muy peculiares
					(condrocalcinosis).
				</span>,
				<span class='bold marked'>
					Predomina en mujeres de edad avanzada (&gt;65 años)
					<span className='superindex bold'>3</span>.
				</span>,
				<span class='bold marked'>
					Puede afectar frecuentemente a manos, rodillas y hombros
					<span className='superindex bold'>3</span>.
				</span>,
			],
		},
	];

	function renderTabs() {
		return (
			<div className='row'>
				<div className='col-12'>
					<ul
						className='nav nav-tabs'
						style={{
							marginTop: '80px',
							borderBottomWidth: '2px',
							borderColor: '#24284fbd',
						}}>
						{CONTENT.map((item, idx) => (
							<li
								className='nav-item text-center'
								onClick={() => setACTIVE(idx)}>
								<div
									className={`nav-link px-4 ${ACTIVE === idx ? 'active' : ''}`}>
									{item.tab}
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	function renderContent() {
		let data = CONTENT[ACTIVE];
		return (
			<div className='row py-4'>
				<div className='col-4'>
					<img src={data.img} className='img-fluid' alt='Imagen intranet 1' />
					<img
						className='content-fullscreen'
						src={fullscreenIcon}
						alt='fullscreen'
						onClick={() => modalImage({ image: data.img, fullscreen: true })}
					/>
				</div>
				<div className='col-8 contentIntranet'>
					<div className='title4'>{data.title}</div>
					<ul className='bigTextB txtGrey'>
						{data.text.map((item) => (
							<li className='py-2'>{item}</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	async function handleNext(e) {
		e.preventDefault();
		if (ACTIVE === 3) {
			let time = getTimer();
			dispatch(updateTime(time, 1));
			if (await caseIsActive(gameId, 0)) {
				updateStats({
					...group,
					time: time,
					lastVisited: '/pruebasclinicasc1',
				});
				navigate('/pruebasclinicasc1');
			} else {
				let timePenalty = getTimePenalty(group.time, group.score);
				dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
				updateStats({
					...group,
					score: group.score - timePenalty,
					penalty: group.penalty - timePenalty,
					time: time,
					lastVisited: '/gameover',
					finished: true,
					finishedCase1: true,
				});
				navigate('/gameover');
			}
		} else setACTIVE(ACTIVE + 1);
	}

	return (
		<div className='h-100'>
			<div className='container h-100 bgIntranet px-5'>
				<div className='justify-content-center position-relative url'>
					{'https://www.hospitalreumatologia.es'}
				</div>
				{renderTabs()}
				{renderContent()}
			</div>
			<ScenarioBar activeCase={1} activeScenario={2} />
			<Link
				to='/pruebasclinicasc1'
				className='btn-trap btn-next bgYellow'
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
		</div>
	);
}

export default injectIntl(Scenario2);
