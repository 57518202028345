import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import { alertError, modalDiagnostic } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import paperIcon from '../../../assets/diagnostic-paper-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '62d57f762061eb5d07300aac';
const scenarioId = '62d57f762061eb5d07300aaa';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);

	const [PENALTY, setPENALTY] = useState(0);

	const RIGHT_ANSWER = 2;
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Arteritis de células gigantes.',
		'Artritis psoriásica.',
		'Artritis reumatoide seronegativa.',
		'Artritis por depósito de cristales de pirofosfato cálcico.',
		'Miopatía inflamatoria.',
		'Fibromialgia.',
		'Artritis séptica.',
		'Síndrome RS3PE.',
	];

	const FEEDBACKS = [
		`<p>Es una vasculitis que afecta a medianos y, sobre todo, grandes vasos. </p><p>Suele cursar con manifestaciones craneales típicas, como cefalea, hipersensibilidad a la palpación del cuero cabelludo, el dolor facial o la claudicación de la mandíbula. </p><p>También pueden aparecer manifestaciones isquémicas visuales, entre otras.</p><p>Parece que Pilar no presenta estos síntomas.</p>`,
		`<p>Puede presentarse de forma poli- u oligoarticular. Sin embargo, suele cursar con psoriasis cutánea o AF de psoriasis. Además, asocia frecuentemente otra clínica, como dactilitis, entesitis, DLI o uveítis. En el caso de Pilar, no se observa ninguna de estas condiciones.</p>`,
		`<p>Es una artritis inflamatoria. Cursa típicamente como una poliartritis simétrica de pequeñas articulaciones con FR y ACPA positivo, a menudo con <span class='bold'>elevación de reactantes de fase aguda</span>. A veces puede iniciarse de forma oligoarticular y evolucionar a poliarticular o cursar con una forma polimiálgica, sobre todo en artritis reumatoide de inicio tardío (>60 años).</p><p>Se caracteriza por elevación de reactantes de fase aguda, como es el caso de Pilar, que presentaba PCR 55 mg/l y VSG 50 mm/h.</p><p>En la ecografía observamos que la paciente presentaba <span class='bold'>bursitis subdeltoidea y leve derrame de la vaina bicipital</span>, hallazgos característicos polimiálgicos inflamatorios.</p><p>Al inicio, la artritis es frecuentemente asimétrica y no erosiva, aunque luego puede evolucionar a poliartritis. Afecta más comúnmente a manos y rodillas. En el caso de Pilar, también se observó artritis en carpo.</p>`,
		`<p>Es una artritis inflamatoria que se caracteriza por el depósito de cristales de pirofosfato cálcico en el cartílago articular, donde llegan a formar calcificaciones muy peculiares (condrocalcinosis). </p><p>No se han observado estos depósitos en las pruebas de imagen realizadas a Pilar.</p></p>`,
		`<p>Es una enfermedad autoinmune sistémica donde hay inflamación y debilidad musculares y, en algunos casos, dolor muscular. Sin embargo, presenta muchas otras manifestaciones clínicas que no aparecen en Pilar, y la autoinmunidad, en este caso, era negativa.</p>`,
		`<p>Síndrome de sensibilidad central que cursa con fatiga y poliartralgias. No se acompaña de patología inflamatoria y los reactantes de fase aguda no tienen por qué estar elevados.</p>`,
		`<p>Afectación inflamatoria aguda de la articulación, de origen infeccioso. Habitualmente es monoarticular, en la rodilla. La distribución oligoarticular de Pilar hace poco probable este diagnóstico, así como la falta de antecedentes relacionados con infección y la procalcitonina negativa.</p>`,
		`<p>Afectación articular inflamatoria distal asociada a edema con fóvea. Puede coexistir con polimialgia reumática y, en algunos casos, se ha asociado a síndrome paraneoplásico. En el caso de Pilar, no se observa ninguna de estas condiciones.</p>`,
	];

	const formatFeedback = (feedback, right) => {
		return `
		<div class='d-flex'>
			<img src=${paperIcon} alt='test' class='align-self-start'/>
			<span>
				${feedback}
			</span>
		</div>
		<p></p>
		<div class='d-flex justify-content-center'>
			${
				!right
					? `<img src=${pruebaInnecesariaIcon} alt='test'/>
				<span class='modal-diagnostic-title txtRed' >
					Este diagnóstico no es correcto.<br>Has perdido -25 puntos.
				</span>`
					: `
				<img src=${pruebaNecesariaIcon} alt='test'/>
				<div class='d-flex flex-column align-items-center'>
					<span class='modal-diagnostic-title txtGreen' >
						¡Diagnóstico correcto!
						${
							PENALTY
								? `<br><span class='normal'>
										Pero no lo has acertado a la primera, por lo que <br>
										<span class='bold'>
											no obtienes los 100 puntos extra.
										</span>			
									</span>`
								: ''
						}
						<br>Ahora, a por el tratamiento…<br><br>	
					</span>
					${
						!PENALTY
							? `<span class='modal-points' style='margin-right:45px'>
									+100 puntos
								</span>`
							: ''
					}
				</div>`
			}
		</div>`;
	};

	function checkAnswer(index) {
		if (RIGHT_ANSWER === index) {
			return 'right';
		}
		setPENALTY(PENALTY + 25);
		return 'wrong';
	}

	function answerHandler(index, feedback) {
		let res = checkAnswer(index - 1);
		modalDiagnostic({
			html: feedback,
			btnText: res === 'right' ? 'AVANZAR' : 'INTENTAR DE NUEVO',
			containerClass: 'modal-diagnostic-container',
		}).then((result) => {
			if (res === 'right') {
				if (result.isConfirmed) {
					submitAnswer();
				} else setIsCompleted(true);
			}
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -175) : 100;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 1));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/tratamientoc1',
						});
						navigate('/tratamientoc1');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={formatFeedback(FEEDBACKS[0])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={formatFeedback(FEEDBACKS[4])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={formatFeedback(FEEDBACKS[1])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={formatFeedback(FEEDBACKS[5])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={formatFeedback(FEEDBACKS[2], true)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={7}
							text={QUESTIONS[6]}
							feedback={formatFeedback(FEEDBACKS[6])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={formatFeedback(FEEDBACKS[3])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={8}
							text={QUESTIONS[7]}
							feedback={formatFeedback(FEEDBACKS[7])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/tratamientoc1'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={6} />
		</div>
	);
}

export default injectIntl(Test);
