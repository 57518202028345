export const SET_GAME = 'SET_GAME';
export const SET_GROUP = 'SET_GROUP';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const UPDATE_TIME = 'UPDATE_TIME';
export const RESET_STATS = 'RESET_STATS';
export const SUM_SCORES = 'SUM_SCORES';

export function setGame(game) {
	return {
		type: SET_GAME,
		game,
	};
}
export function setGroup(group) {
	return {
		type: SET_GROUP,
		group,
	};
}
export function updateScore(score, typeOfScore, actualCase) {
	return {
		type: UPDATE_SCORE,
		score,
		typeOfScore,
		actualCase,
	};
}
export function sumScores() {
	return {
		type: SUM_SCORES,
	};
}
export function updateTime(time, actualCase) {
	return {
		type: UPDATE_TIME,
		time,
		actualCase,
	};
}
export function resetStats() {
	return {
		type: RESET_STATS,
	};
}
