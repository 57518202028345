import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getDefaultGame, getGameById } from '../api/game';
import { postGroup, getGroupByTableAndGame } from '../api/group';
import { alertError, alertGroup } from '../utils/logger';
import { useLang } from '../translations/i18n';
import logo from '../assets/logo MTX_NEG.svg';
import icofoto from '../assets/register/ico-foto.svg';
import addIcon from '../assets/register/add-icon.svg';
import removeIcon from '../assets/register/remove-icon.svg';
import { useDispatch } from 'react-redux';
import { setGame, setGroup } from '../redux/actions';
import { getGroupTable, setGroupTable, updateTimer } from '../utils/helpers';

function getEmptyGroup() {
	return {
		fullName: '',
		game: null,
		image: '',
		tableNum: '',
		members: [],
	};
}

function Register({ intl }) {
	const [activeGame, setActiveGame] = useState(null);
	const [activeGroup, setActiveGroup] = useState(getEmptyGroup());
	const [actualMember, setActualMember] = useState('');
	const [members, setMembers] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const noTimeScreens = [
		'/start',
		'/end',
		'/results',
		'/ranking',
		'/finalranking',
		'/gameover',
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		const asyncFetch = async () => {
			if (prevState?.state?.game) {
				getGameById(prevState.state.game).then((res) => {
					if (res.status === 200) {
						handleData(res.data);
					}
				});
			} else {
				getDefaultGame().then((res) => {
					if (res.status === 200) {
						handleData(res.data);
					}
				});
			}
		};

		asyncFetch();
	}, []);

	function handleData(data) {
		dispatch(setGame(data));
		setActiveGame(data);
		setActiveGroup((prevGroup) => ({
			...prevGroup,
			game: data._id,
		}));
		let table = getGroupTable();
		if (table) {
			getGroupByTableAndGame(table, data._id).then((result) => {
				if (result.status === 200) reconnect(result.data.group);
			});
		}
	}

	function saveGroup(e) {
		e.preventDefault();
		if (members.length) {
			activeGroup.members = members;
			postGroup(activeGroup, selectedImage)
				.then((res) => {
					let group = res.data;
					if (res.status === 201) {
						group.score = 0;
						group.time = 0;
						group.penalty = 0;
						group.bonus = 0;
						dispatch(setGroup(group));
						setGroupTable(group.tableNum);
						navigate('/start');
					}
				})
				.catch((error) => {
					//change
					alertError({
						error: ' ',
						customMessage: error?.response?.data?.message || ' ',
					});
				});
		} else
			alertError({
				error: ' ',
				customMessage: 'No has añadido a ningún participante!',
			});
	}

	function reconnect(group) {
		group.score = group[`scoreCase${group.actualCase}`];
		group.time = group[`timeCase${group.actualCase}`];
		group.penalty = group[`penaltyCase${group.actualCase}`];
		group.bonus = group[`bonusCase${group.actualCase}`];
		dispatch(setGroup(group));
		setGroupTable(group.tableNum);
		if (group.lastVisited) {
			if (!noTimeScreens.includes(group.lastVisited) && !group.finished)
				updateTimer(group.time);
			navigate(group.lastVisited);
		} else navigate('/start');
	}

	const handleAddMember = () => {
		if (
			members.length < 6 &&
			actualMember.length != 0 &&
			actualMember.length < 45 &&
			actualMember.trim().indexOf(' ') != -1
		) {
			setMembers([...members, actualMember]);
			setActualMember('');
		} else
			alertError({
				error: ' ',
				customMessage: 'Cada participante debe tener nombre y apellido!',
			});
	};

	const handleChange = (element) => (event) => {
		setActiveGroup({ ...activeGroup, [element]: event.target.value });
	};

	const handleGroupTableChange = (value) => {
		setActiveGroup((prevGroup) => ({
			...prevGroup,
			tableNum: value || '',
		}));
	};

	async function handleGroupTable(event) {
		getGroupByTableAndGame(event.target.value, activeGame._id)
			.then(async (resGroup) => {
				if (resGroup.status === 200) {
					await alertGroup({ data: resGroup.data }).then((res) => {
						if (res.isConfirmed) {
							reconnect(resGroup.data.group);
						} else {
							handleGroupTableChange();
							document.getElementById('sector').selectedIndex = 0;
						}
					});
				}
			})
			.catch((error) => {
				handleGroupTableChange(event.target.value);
			});
	}

	function renderOptions() {
		return Array.from(
			{
				length: parseInt(activeGame?.numTables),
			},
			(_, index) => (index + 1).toString()
		).map((option, index) => (
			<option key={index} value={option}>
				{option}
			</option>
		));
	}

	function renderMembers() {
		return members.map((name, index) => (
			<div
				key={index}
				className='d-flex justify-content-between align-items-center mt-3 px-2'>
				<div className='textYellow px-4'>{name}</div>
				<img
					src={removeIcon}
					alt=''
					className='btn-remove d-flex align-items-center justify-content-center'
					onClick={() => setMembers(members.filter((member) => member != name))}
				/>
			</div>
		));
	}

	return (
		<div className='pt-4 h-100'>
			<div className='d-flex justify-content-between legalGebro px-5 pb-3'>
				<div>
					<img src={logo} alt='Clinicalxperience' className='img-fluid w-100' />
				</div>
				<div style={{ height: '53px' }}></div>
			</div>
			<div className='container-fluid bgBlue h-100'>
				<div className='row pt-4'>
					<div className='col-4 offset-1'>
						<div className='title1 mb-1'>{'Haz una foto de equipo'}</div>
						<div className='fotoButton mt-3 image-upload text-center'>
							<label htmlFor={'file-input'}>
								<img
									src={
										selectedImage ? URL.createObjectURL(selectedImage) : icofoto
									}
									alt='Haz una foto de equipo'
									className={`img-fluid icofoto ${
										selectedImage ? 'icofoto-selected' : ''
									}`}
								/>
							</label>
							<input
								id={'file-input'}
								name={'file-input'}
								type='file'
								accept='image/*'
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
					</div>
					<div className='offset-1 col-5'>
						<div className='title1 mb-1'>{'Añade los participantes'}</div>
						<div className='container-add'>
							<input
								id={`fullName`}
								InputLabelProps={{
									shrink: true,
								}}
								value={actualMember}
								onChange={(event) => {
									setActualMember(event.target.value);
								}}
								placeholder='Escribe el nombre y apellido'
								required
								className='form-control input-add'
							/>
							<img
								src={addIcon}
								alt=''
								className='btn-add d-flex align-items-center justify-content-center'
								onClick={handleAddMember}
							/>
						</div>
						<div className='row col-5 mt-4 position-absolute'>
							{renderMembers()}
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='offset-1 mt-4 col-5'>
						<div className='title1 mb-1'>{'Elige el número de mesa'}</div>
						<select
							id='sector'
							name='sector'
							defaultValue={'default'}
							className='form-control'
							onChange={handleGroupTable}>
							<option disabled hidden value={'default'}>
								{'Selecciona una mesa'}
							</option>
							{renderOptions()}
						</select>
					</div>
				</div>
				<div className='row'>
					<div className='col-5 offset-1 '>
						<div className='title1 mt-4 mb-1'>
							{'Pon un nombre a tu equipo '}
							<div className='d-inline-flex title1B'>
								{'(máximo 15 caracteres)'}
							</div>
						</div>
						<input
							id={`fullName`}
							InputLabelProps={{
								shrink: true,
							}}
							maxLength={15}
							onChange={handleChange('fullName')}
							placeholder='Escribe el nombre'
							required
							className='form-control'
						/>
					</div>
				</div>
			</div>
			<Link
				to='/start'
				className='bgYellow btn-trap btn-next'
				onClick={saveGroup}>
				{'AVANZAR'}
			</Link>
			<div className='lineBottom blueyellowBottom'></div>
		</div>
	);
}

export default injectIntl(Register);
