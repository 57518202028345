import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { alertResume } from '../utils/logger';
import { clearItem, getGroupTable } from '../utils/helpers';

function Home({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const gameId = searchParams.get('game');

	useEffect(() => {
		if (getGroupTable()) {
			if (gameId) {
				alertResume().then((res) => {
					if (res.isConfirmed) {
						navigate('/register');
					} else {
						clearItem('groupTable');
						clearItem('total');
					}
				});
			} else {
				navigate('/register');
			}
		} else {
			clearItem('total');
		}
	}, []);

	return (
		<div
			className='startbg txtWhite'
			onClick={() => {
				navigate('/register', { state: { game: gameId } });
			}}>
			<div className='row h-100'>
				<div className='col-6 offset-3 my-auto logodiv txtWhite'>
					<div>
						<div className='txtWhite'>BIENVENIDOS</div>
					</div>
				</div>
			</div>
			<div className='lineBottom yellowBottom'></div>
		</div>
	);
}

export default injectIntl(Home);
