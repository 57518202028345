import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import morePointsIcon from '../../../assets/ico-morepoints.svg';
import NextButton from '../../../components/NextButton';

function SupertestIntro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75 pt-5 justify-content-center'>
					<div className='d-flex align-items-center justify-content-center pt-5'>
						<div className='text-center'>
							<h1 className='txtBlue'>¡Gana más puntos!</h1>
							<div className='bigTextB py-4 text-left'>
								<p className='txtBlue'>
									{'Responde correctamente a las 2 preguntas del '}
									<span className='bold'>supertest</span>
									{' y ganarás '}
									<span className='destacadoVerde title4'>+50 puntos. </span>
								</p>
								<p>
									<span className='bold'>Cuidado:</span>
									{' para conseguir los 50 puntos, las '}
									<span className='bold'>
										2 respuestas deben ser correctas.
									</span>
									<br />
									Si fallas alguna, no hay bonificación.
								</p>
								<p>
									Marca una respuesta para cada pregunta; cuando tengas las 2
									respuestas, <br />
									pulsa
									<span className='bold'>{' Comprobar'}</span> para conocer el
									resultado.
								</p>
								<p className='bold'>Solo tienes un intento.</p>
							</div>
							<img src={morePointsIcon} alt='Más puntos' />
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/supertestc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={8} expanded />
		</div>
	);
}

export default injectIntl(SupertestIntro);
