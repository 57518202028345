import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { postAnswer } from '../../../api/answer';
import ScenarioBar from '../../../components/ScenarioBar';
import { alertError, modalAnswer, modalConfirm } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import supertestIcon from '../../../assets/supertest-icon.svg';
import crackIcon from '../../../assets/crack-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import remember from '../../../assets/remember-icon.svg';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import { useTimer } from '../../../hooks/useTimer';

const quizId = '632c2a6101c338bfcec980f0';
const quiz1Id = '62d581032061eb5d07300ad7';
const quiz2Id = '62d668c77245b9b4bedeec39';
const quiz3Id = '62d668f37245b9b4bedeec3a';

const scenarioId = '62d581032061eb5d07300ad5';

const defaultAnswers = {
	0: null,
	1: null,
	2: null,
};

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [ACTIVE, setACTIVE] = useState(0);
	const [ANSWERS, setANSWERS] = useState(defaultAnswers);
	const RIGHT_ANSWERS = [0, 2, 3];
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState([0, 0, 0]);

	useTimer(
		() => {
			setTime((time) => ({ ...time, [ACTIVE]: time[ACTIVE] + 1 }));
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		{
			txt: '1. ¿Cuál de los siguientes no es un criterio de clasificación de polimialgia reumática según EULAR/ACR 2012?',
			options: [
				'Rápida respuesta a prednisona (dosis ≤20 mg/día)',
				'Rigidez matutina >45 minutos',
				'Dolor/limitación de movilidad de cadera de reciente aparición',
				'FR y/o anti-PCC negativo',
				'Ausencia de afectación articular a otros niveles',
			],
		},
		{
			txt: '2. ¿Cuál de las siguientes afirmaciones es falsa en relación con la artritis reumatoide senil o de inicio tardío?',
			options: [
				'Suele afectar a grandes articulaciones, como los hombros',
				'Es frecuente la elevación de los reactantes de fase aguda y la anemia de trastornos crónicos',
				'Es más leve y con menor grado de discapacidad',
				'Se utilizan los FAMEsc como tratamiento de primera línea',
				'Suele acompañarse de síntomas constitucionales, como fiebre y pérdida de peso',
			],
		},
		{
			txt: '3. ¿Cuál de las siguientes afirmaciones es correcta respecto a la arteritis de células gigantes?',
			options: [
				'La manifestación oftálmica menos común es la neuropatía óptica isquémica',
				'Un 15-20 % de los pacientes con arteritis de células gigantes tienen clínica de polimialgia reumática al diagnóstico',
				'Aproximadamente un 50 % de los pacientes con polimialgia reumática presentan una vasculitis subclínica',
				'Son excepcionales (<5 %) los casos que cursan con normalidad de los reactantes de fase aguda',
				'La resonancia magnética es la primera prueba de imagen que se debe realizar en pacientes con sospecha de arteritis de células gigantes craneal',
			],
		},
	];

	function checkAnswer() {
		setIsCompleted(true);
		if (RIGHT_ANSWERS.some((answer, idx) => answer != ANSWERS[idx])) {
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${pruebaInnecesariaIcon} class='align-self-center' alt='test'/>
						<span class='title3 txtRed'>
							¡Vaya!<br>
							<span class='bigTextB txtGrey'>
								Al menos una de las respuestas no era correcta. Avanza para descubrir cuál.
							</span>
						</span>
					</div>
					`,
				btnText: 'VER SOLUCIÓN',
			}).then(() => {
				submitAnswer(true);
			});
		} else {
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${crackIcon} class='modal-crack-icon' alt='test'/>
						<span class='title3 txtGreen align-self-end'>
							¡Enhorabuena!<br>
							<span class='bigTextB txtGrey'>
								¡Has acertado las 3 respuestas del supertest! <br/>
								Acabas de ganar 
								<span class='modal-points'>
									+50 puntos
								</span>
							</span>
						</span>
					</div>`,
				btnText: 'VER SOLUCIÓN',
			}).then(() => submitAnswer());
		}
	}

	async function submitAnswer(PENALTY) {
		const finalScore = PENALTY ? 0 : 50;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time[0] + time[1] + time[2],
			})
				.then((res) => {
					if (res.status === 201) {
						postAnswer({
							scenario: scenarioId,
							quiz: quiz1Id,
							group: group?._id,
							time: time[0],
							correct: RIGHT_ANSWERS[0] === ANSWERS[0],
						})
							.then((res) => {
								if (res.status === 201) {
									postAnswer({
										scenario: scenarioId,
										quiz: quiz2Id,
										group: group?._id,
										time: time[1],
										correct: RIGHT_ANSWERS[1] === ANSWERS[1],
									})
										.then((res) => {
											if (res.status === 201) {
												postAnswer({
													scenario: scenarioId,
													quiz: quiz3Id,
													group: group?._id,
													time: time[2],
													correct: RIGHT_ANSWERS[2] === ANSWERS[2],
												})
													.then((res) => {
														if (res.status === 201) {
															updateStats({
																...group,
																score: group.score + finalScore,
																bonus: group.bonus + finalScore,
																time: totalTime,
																lastVisited: '/supertestsolucionc1',
															});
															dispatch(updateScore(finalScore, 'BONUS', 1));
															navigate('/supertestsolucionc1');
														}
													})
													.catch((error) => {
														alertError({
															error: error,
															customMessage:
																'Ya has respondido este escenario!',
														});
													});
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Ya has respondido este escenario!',
											});
										});
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage: 'Ya has respondido este escenario!',
								});
							});
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (ACTIVE < 2) setACTIVE(ACTIVE + 1);
		else if (!Object.keys(ANSWERS).some((idx) => ANSWERS[idx] === null))
			modalConfirm({ titleClass: 'modal-confirm-titleB' }).then((result) => {
				if (result.isConfirmed) checkAnswer();
				else setACTIVE(0);
			});
		else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='bold'>
							Recuerda
							<span class='normal'>
								que debes seleccionar una <br/>
								respuesta en cada pregunta para poder avanzar.
							</span>
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
			});
			setACTIVE(0);
		}
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setANSWERS((ANSWERS) => ({ ...ANSWERS, [ACTIVE]: isChecked ? idx : null }));
	}

	function renderQuestion() {
		return (
			<div className='col-10'>
				<div className='pb-3 title4'>{QUESTIONS[ACTIVE].txt}</div>
				{QUESTIONS[ACTIVE].options.map((option, idx) => (
					<div key={idx} className='my-2'>
						<div className='bigText d-flex'>
							<div>
								<input
									type='checkbox'
									checked={ANSWERS[ACTIVE] === idx}
									onChange={(event) => handleInputChange(event, idx)}></input>
							</div>
							<div className='mx-2'>
								<span className='title4 txtGrey'>{idx + 1}. </span>
								{option}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-25 justify-content-center'>
					<div className='col-4 d-flex mt-5 justify-content-center align-items-center'>
						<div className='d-flex justify-content-center align-items-center'>
							<img
								className='supertest-icon'
								src={supertestIcon}
								alt='Supertest'
							/>
							<h1
								className='txtBlue position-relative'
								style={{ right: '35px' }}>
								Supertest
							</h1>
						</div>
					</div>
				</div>
				<div className='row justify-content-center'>{renderQuestion()}</div>
			</div>
			<Link
				to='/supertestsolucionc1'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{ACTIVE < 2 ? 'AVANZAR' : 'COMPROBAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={8} />
		</div>
	);
}

export default injectIntl(Test);
