import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../translations/i18n';
import ScenarioBar from '../../components/ScenarioBar';
import crono40 from '../../assets/crono40.svg';
import NextButton from '../../components/NextButton';

function InstruccionesC2({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75 pt-5'>
					<div className='col d-flex align-items-center justify-content-center pb-5'>
						<div className='text-center'>
							<h1 className='txtBlue'>Averigua qué le pasa a Manolo.</h1>
							<div className='bigExplains px-5 py-4'>
								<p>
									Presta atención y elige las respuestas correctas para sumar
									puntos; las opciones erróneas tienen penalización.{' '}
									<span className='bold'>
										Ganará quien consiga la puntuación más alta.
									</span>
								</p>
								<p>
									Dispones de <span className='bold'>40 minutos</span> para
									resolver el caso.
								</p>
							</div>
							<img src={crono40} alt='Crono 40 minutos' />
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/controlatiempoc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={0} />
		</div>
	);
}

export default injectIntl(InstruccionesC2);
