import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../translations/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { clearItem, updateStats, updateTimer } from '../utils/helpers';
import openLockIcon from '../assets/scenariobar/ico-lockopened2.svg';
import closedLockIcon from '../assets/scenariobar/ico-lockclosed-white.svg';
import completedIcon from '../assets/prueba-superada.svg';
import { getGameById } from '../api/game';
import { alertCaso } from '../utils/logger';
import { resetStats } from '../redux/actions';

function Start({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const game = useSelector((state) => state.game);
	const group = useSelector((state) => state.group);
	const [isCompleted, setIsCompleted] = useState(false);
	const { state } = useLocation();
	const { completed } = state || false;

	useEffect(() => {
		if (group.actualCase === '2' || completed) {
			setIsCompleted(true);
			clearItem('total');
		}
	}, []);

	function handleCaseStatus(e) {
		e.preventDefault();
		startCase(isCompleted ? 1 : 0);
	}

	function startCase(selectedCase) {
		getGameById(game?._id).then((res) => {
			if (res.status === 200) {
				if (isCompleted && selectedCase === 0)
					alertCaso({
						caso: res.data.clinicCases[selectedCase],
						completed: true,
					});
				else if (res.data.clinicCases[selectedCase]?.status === 'running') {
					if (selectedCase === 1 && !group?.finishedCase1)
						alertCaso({
							caso: {
								...res.data.clinicCases[selectedCase - 1],
								status: 'not-played',
							},
						});
					else {
						dispatch(resetStats());
						let url = `/startc${selectedCase + 1}`;
						updateStats({
							...group,
							score: 0,
							time: 0,
							penalty: 0,
							bonus: 0,
							lastVisited: url,
							finished: false,
						});
						navigate(url);
						updateTimer(0);
					}
				} else {
					alertCaso({ caso: res.data.clinicCases[selectedCase] });
				}
			}
		});
	}

	function getIcon(index) {
		if (isCompleted) {
			return index === 1 ? completedIcon : openLockIcon;
		}
		return index === 1 ? openLockIcon : closedLockIcon;
	}

	function getClass(index) {
		if (isCompleted) {
			return index === 1 ? 'bgGreyToTransp75' : 'bgYellowToTransp75';
		}
		return index === 1 ? 'bgYellowToTransp75' : 'bgGreyToTransp75';
	}
	return (
		<div className='h-100'>
			<div className='container-fluid bgBlue h-100 pb-5 '>
				<div className='row h-100 pb-5'>
					<div className='col d-flex align-items-center pb-5 mb-5'>
						<div className='container mb-5'>
							<div className='row'>
								<div className='col-6' onClick={() => startCase(0)}>
									<div className={`${getClass(1)} titleNumCaso`}>CASO 1</div>
									<div className='container-case'>
										<div className='glowered-border'></div>
										<div className='titleNameCaso text-white'>
											<h2>
												Me levanto como
												<br /> un robot
											</h2>
											<div className='bigTextD'>
												<span className='dark'>Dr. Enigma</span>
												<br />
												Servicio de Investigación. Departamento de medicina,
												Universitat Autònoma.
											</div>
										</div>
									</div>
									<div className='position-relative'>
										<div className='lock-container'>
											<img
												className='img-fluid h-100 w-100'
												src={getIcon(1)}
												alt='unlocked'
											/>
										</div>
									</div>
								</div>
								<div className='col-6' onClick={() => startCase(1)}>
									<div className={`${getClass(2)} titleNumCaso`}>CASO 2</div>
									<div
										className={`${
											!isCompleted ? 'txtGreyB' : 'text-white'
										} container-case`}>
										<div className='glowered-border'></div>
										<div className='titleNameCaso '>
											<h2>
												¿Y si las cosas no <br />
												son lo que parecen?
											</h2>
											<div className='bigTextD'>
												<span className='dark'>Dr. Enigma</span>
												<br />
												Servicio de Investigación. Departamento de medicina,
												Universitat Autònoma.
											</div>
										</div>
									</div>
									<div className='position-relative'>
										<div className='lock-container'>
											<img
												className='img-fluid h-100 w-100'
												src={getIcon(2)}
												alt='unlocked'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/startc1'
				className='bgYellow btn-trap btn-next'
				onClick={handleCaseStatus}>
				{'AVANZAR'}
			</Link>
		</div>
	);
}

export default injectIntl(Start);
