import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import carpeta from '../../../assets/carpeta.svg';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-75'>
					<div className='col-10 pt-5 offset-1 d-flex align-items-center justify-content-center'>
						<div className='mb-5 px-5'>
							<div className='d-flex align-items-center recuadro1 py-4 w-75 mx-auto '>
								<img className='px-4' src={carpeta} alt='Carpeta' />
								<div className='bigTextB txtGrey px-3'>
									Abre la carpeta de materiales para ver las imágenes impresas.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario4testc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Intro);
