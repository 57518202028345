import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import { modalAnswer } from '../../../utils/logger';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';

function Answers({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const prevState = useLocation();

	function handleNext(e) {
		e.preventDefault();
		if (prevState.state.PENALTY)
			modalAnswer({
				html: `
				<div class='d-flex justify-content-center'>
					<img src=${pruebaInnecesariaIcon} class='align-self-center' alt='test'/>			
					<span>
						Como no has acertado a la primera,<br>
						no puedes llevarte los puntos.<br>
						<span class='bold mt-1'>
							Sigue con el caso.
						</span>
					</span>
					
				</div>`,
			}).then(() => navigate('/diagnosticoc1'));
		else navigate('/diagnosticoc1');
	}
	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-100 mt-5 px-3'>
					<div className='col-12 d-flex mt-5 px-5'>
						<div className='bigText px-5 mt-5 test-solution'>
							<ul>
								<li>
									{'Se estima que el '}
									<span className='title4 txtGrey'>
										{
											'30-40 % de la etiopatogenia del lupus eritematoso sistémico'
										}
									</span>
									{' puede explicarse por factores genéticos. Los individuos '}
									<span className='title4 txtGrey'>{'HLA-DR2, DR3 y DQ'}</span>
									{
										' tienen dos o tres veces más riesgo de padecer la enfermedad'
									}
									<span className='superindex'>{'4'}</span>.
								</li>
								<li>
									{'La '}
									<span className='title4 txtGrey'>
										{'artritis reumatoide'}
									</span>
									{' tiene un fuerte '}
									<span className='title4 txtGrey'>{'sustrato genético'}</span>
									{', cuya heredabilidad se sitúa en un '}
									<span className='title4 txtGrey'>{'60 %'}</span>
									{' aproximadamente'}
									<span className='superindex'>{'5,6'}</span>.
								</li>
								<li>
									{'La '}
									<span className='title4 txtGrey'>
										{'contribución genética en EspA'}
									</span>
									{' identificada hasta la fecha solo explica '}
									<span className='title4 txtGrey'>
										{'el 25 % de la heredabilidad'}
										<span className='superindex bold'>{'2'}</span>.
									</span>
								</li>
								<li>
									{'Diversos estudios describen la asociación de la '}
									<span className='title4 txtGrey'>
										{'arteritis de células gigantes'}
									</span>
									{
										'  con genes localizados en el complejo mayor de histocompatibilidad (alelos HLA-DRB1 0424)'
									}
									<span className='superindex'>{'7'}</span>.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/diagnosticoc1'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={6} />
		</div>
	);
}

export default injectIntl(Answers);
