import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import { modalImage } from '../../../utils/logger';
import testimg1 from '../../../assets/images/testimg1-sol.png';
import testimg2 from '../../../assets/images/testimg2-sol.png';
import testimg3 from '../../../assets/images/testimg3-sol.png';
import testimg4 from '../../../assets/images/testimg4-sol.png';
import dialogIcon from '../../../assets/dialog-icon.svg';
import fullscreenIcon from '../../../assets/fullscreen.png';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';

function Answers({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [ACTIVE, setACTIVE] = useState(0);

	const CONTENT = [
		{
			title: '¿Qué muestra esta radiografía de manos?',
			subtitle:
				'Signos de disminución de espacio articular y esclerosis subcondral de predominio en IFD e IFP compatible con artrosis...',
			text: '… aunque estas alteraciones, tal como están descritas, podrían darse en ese tipo de afectaciones. Podemos observar en la imagen disminución del espacio articular de predominio en IFD e IFP con esclerosis subcondral, sin erosiones, compatible con artrosis de manos.',
			img: testimg1,
		},
		{
			title: '¿Qué muestra esta ecografía de carpo derecho?',
			subtitle: 'Sinovitis grado II en articulación radiocarpiana.',
			text: 'El grado I implicaría la presencia de un vaso en una articulación pequeña y hasta tres vasos en una articulación grande; el grado II, presencia de más de un vaso en articulación pequeña o tres vasos en menos del 50 % de la cavidad sinovial; el grado III, compromiso de más del 50 % de la cavidad sinovial.',
			img: testimg2,
		},
		{
			title: '¿Qué muestran estas radiografías de hombros?',
			subtitle:
				'Disminución de espacio articular acromioclavicular, con signos de artrosis degenerativa.',
			text: 'No se observan calcificaciones ni erosiones glenohumerales.',
			img: testimg3,
		},
		{
			title: '¿Qué muestran estas ecografías de hombro derecho?',
			subtitle: 'Bursitis subdeltoidea y leve derrame en la vaina del bíceps.',
			text: `No se observó <span class=italics>doppler</span> al realizar la ecografía del hombro.`,
			img: testimg4,
		},
	];

	async function handleNext(e) {
		e.preventDefault();
		let time = getTimer();
		dispatch(updateTime(time, 1));
		if (await caseIsActive(gameId, 0)) {
			if (ACTIVE === 3) {
				updateStats({
					...group,
					time: time,
					lastVisited: '/geneticac1',
				});
				navigate('/geneticac1');
			} else setACTIVE(ACTIVE + 1);
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: time,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	return (
		<div className='h-100'>
			<div className='container-fluid pt-3 h-100'>
				<div className='row bluerow'>
					<div className='col text-center py-4'>
						<div className='row'>
							<div className='d-flex align-items-center justify-content-center'>
								<div className='active'>
									<img
										style={{ width: '450px', height: '253px' }}
										className='img-fluid m-0'
										src={CONTENT[ACTIVE].img}
										alt={`Test image ${ACTIVE}`}
									/>
									<img
										className='fullscreen'
										src={fullscreenIcon}
										alt='fullscreen'
										onClick={() =>
											modalImage({
												image: CONTENT[ACTIVE].img,
												fullscreen: true,
											})
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row h-100 justify-content-center'>
					<div className='col-10 mt-4 me-5'>
						<div className='title4 txtBlueB'>{CONTENT[ACTIVE].title}</div>
						<div className='bigTextB txtGrey'>{CONTENT[ACTIVE].subtitle}</div>
						<div className='col-10 d-flex bigTextB txtGrey pt-3'>
							<img
								src={dialogIcon}
								className='align-self-center me-4'
								style={{ width: '50px', height: '43px' }}
								alt='Dialog'
							/>

							<div bigTextB>
								{ACTIVE < 3 ? (
									CONTENT[ACTIVE].text
								) : (
									<div>
										No se observó <span className='italics'>doppler</span> al
										realizar la ecografía del hombro.
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/geneticac1'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={5} />
		</div>
	);
}

export default injectIntl(Answers);
