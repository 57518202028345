import React, { useState } from 'react';
import { modalConfirm, modalConfirmTest } from '../utils/logger';

/* estados class num+: selected / right / wrong */

function BoxPreguntaTest({
	index,
	text,
	feedback,
	handler,
	showConfirm,
	showConfirmTest,
	showSelectedIndicator,
	loadingStatus,
	selected,
	showExtraBtn,
	html,
}) {
	const [status, setStatus] = useState('');

	return (
		<div
			className='boxPreguntaTest d-flex align-items-center my-1'
			onClick={async () => {
				if (showSelectedIndicator) {
					if (selected) setStatus(loadingStatus);
					else setStatus('selected');
				}
				if (showConfirm) {
					modalConfirm().then((res) => {
						if (res.isConfirmed)
							setStatus(handler(index, feedback, showExtraBtn));
					});
				} else if (showConfirmTest) {
					modalConfirmTest({ html }).then((res) => {
						if (res.isConfirmed)
							setStatus(handler(index, feedback, showExtraBtn));
					});
				} else {
					setStatus(await handler(index, feedback, showExtraBtn));
				}
			}}>
			<div className={`num ${status}`}>{`${index}.`}</div>
			<div className='text'>{text}</div>
		</div>
	);
}
export default BoxPreguntaTest;
