import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { modalImage } from '../../../utils/logger';
import ScenarioBar from '../../../components/ScenarioBar';
import checklistIcon from '../../../assets/checklist-icon.svg';
import dialogIcon from '../../../assets/dialog-icon.svg';
import addIcon from '../../../assets/register/add-icon-blue.svg';
import table from '../../../assets/images/supertest-cuadro2.png';
import {
	clearItem,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore } from '../../../redux/actions';

function SupertestPost({ intl }) {
	const lang = useLang();
	const group = useSelector((state) => state.group);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [ACTIVE, setACTIVE] = useState(0);

	useEffect(() => {
		if (getTimer()) {
			clearItem('total');
			let timePenalty = getTimePenalty(group.time, group.score);
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				lastVisited: '/end',
				finishedCase1: true,
			});
			dispatch(updateScore(-timePenalty, 'PENALTY', 1));
		}
	}, []);

	const QUESTIONS = [
		{
			txt: '¿Cuál de los siguientes no es un criterio de clasificación de polimialgia reumática según EULAR/ACR 2012?',
			feedback: (
				<>
					<div className='row mt-2'>
						<div className='d-flex'>
							<img
								class='align-self-start'
								src={checklistIcon}
								alt='checklist'
							/>
							<span class='mx-3'>
								La respuesta a la prednisona no es un criterio de clasificación
								de polimialgia reumática según EULAR/ACR 2012
								<span className='superindex'>{'8'}</span>.
							</span>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='d-flex'>
							<img class='align-self-start' src={dialogIcon} alt='checklist' />
							<span class='mx-3'>
								En los{' '}
								<span className='italics light'>
									Criterios de clasificación PMR EULAR/ACR 2012: criterios
									aplicables a pacientes de edad mayor o igual a 50 años con
									dolor de ambos hombros de inicio reciente
								</span>
								, se considera polimialgia reumática una puntuación de 5 o
								superior en el algoritmo sin ecografía (S: 68 %, E: 78 %) o una
								puntuación de 4 o superior en el algoritmo con ecografía (S: 66
								%, E: 81 %)
								<span className='superindex'>{'8'}</span>.
							</span>
						</div>
					</div>
					<div className='row mt-5'>
						<div
							className='d-flex justify-content-center'
							onClick={() =>
								modalImage({
									image: table,
									fullscreen: true,
									paddingTop: 'pt-5',
								})
							}>
							<div className='bgYellow btn-trap ps-3 text-left'>Ver tabla</div>
							<img
								class='z-indez-100 supertest-add-icon'
								src={addIcon}
								alt='cross'
							/>
						</div>
					</div>
					<div className='row mt-5 justify-content-center'>
						<span class='mx-3 '>
							No se encuentra como criterio la respuesta a la prednisona.
						</span>
					</div>
				</>
			),
		},
		{
			txt: '¿Cuál de las siguientes afirmaciones es falsa en relación con la artritis reumatoide senil o de inicio tardío?',
			feedback: (
				<>
					<div className='row mt-2'>
						<div className='d-flex'>
							<img
								class='align-self-start'
								src={checklistIcon}
								alt='checklist'
							/>
							<span class='mx-3'>
								Es falso que la artritis reumatoide senil o de inicio tardío sea
								más leve y con menor discapacidad
								<span className='superindex'>{'6'}</span>.
							</span>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='d-flex'>
							<img class='align-self-start' src={dialogIcon} alt='checklist' />
							<span class='mx-3'>
								La afectación de articulaciones grandes, el síndrome
								constitucional y otras comorbilidades son más frecuentes en las
								formas de comienzo tardío
								<span className='superindex'>{'6'}</span>.
								<br />
								<br />
								Asimismo, se ha descrito que suele cursar con elevación de
								reactantes de fase aguda, que pueden ser incluso superiores a
								los de pacientes con artritis reumatoide más jóvenes
								<span className='superindex'>{'6'}</span>.
								<br />
								<br />
								Los pacientes de mayor edad con enfermedades inflamatorias
								presentan peores resultados en salud
								<span className='superindex'>{'6'}</span>.
							</span>
						</div>
					</div>
				</>
			),
		},
		{
			txt: '¿Cuál de las siguientes afirmaciones es correcta respecto a la arteritis de células gigantes?',
			feedback: (
				<>
					<div className='row mt-2'>
						<div className='d-flex'>
							<img
								class='align-self-start'
								src={checklistIcon}
								alt='checklist'
							/>
							<span class='mx-3'>
								{
									'Son excepcionales (<5 %) los casos que cursan con normalidad de los reactantes de fase aguda'
								}
								<span className='superindex'>{'7'}</span>.
							</span>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='d-flex'>
							<img class='align-self-start' src={dialogIcon} alt='checklist' />
							<span class='mx-3'>
								Aproximadamente un 50 % de los pacientes con arteritis de
								células gigantes tienen clínica de polimialgia reumática al
								diagnóstico, y entre un 15-20 % de los pacientes con polimialgia
								reumática presentan una vasculitis subclínica
								<span className='superindex'>{'7'}</span>.
								<br />
								<br />
								La neuropatía óptica isquémica es la manifestación oftálmica más
								común. La ecografía es la primera prueba de imagen que se
								debería realizar ante sospecha de arteritis de células gigantes
								<span className='superindex'>{'7'}</span>.
								<br />
								<br />
								La resonancia magnética de arterias craneales tiene igual
								sensibilidad y especificidad que la ecografía. Sin embargo, dada
								su menor disponibilidad, el coste y la necesidad de contraste
								endovenoso, se reserva para casos en que no esté disponible la
								ecografía o sea inconcluyente
								<span className='superindex'>{'7'}</span>.
							</span>
						</div>
					</div>
				</>
			),
		},
	];

	function handleNext(e) {
		e.preventDefault();
		if (ACTIVE < 2) setACTIVE(ACTIVE + 1);
		else
			navigate('/end', {
				state: { activeCase: 1 },
			});
	}

	function renderQuestion() {
		return (
			<div className='col-10'>
				<div className='pb-3 title4'>
					{`${ACTIVE + 1}. ${QUESTIONS[ACTIVE].txt}`}
				</div>
				<div className='bigText text-left'>{QUESTIONS[ACTIVE].feedback}</div>
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row justify-content-center mt-5'>
					{renderQuestion()}
				</div>
			</div>
			<Link
				to='/end'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={8} />
		</div>
	);
}

export default injectIntl(SupertestPost);
