import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import robotIcon from '../../../assets/ico-robot.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={robotIcon} alt='Pruebas' />
										<div className='title-scenario mx-2 text-left'>
											Demuestra que no
											<br />
											eres un robot
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='row h-50'>
							<div className='col-12 py-5 d-flex bigText align-items-center justify-content-center'>
								<div className='normal pb-5'>
									<p>
										Tu ordenador se ha desconectado del servidor.
										<span className='title1'> ¡Qué raro! </span>
									</p>
									<p>
										Cuando te conectas de nuevo, te pide tu nombre de usuario y
										contraseña: <br />
										los introduces y...
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/noeresunrobot2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={4} expanded />
		</div>
	);
}

export default injectIntl(Intro);
