import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { postAnswer } from '../../../api/answer';
import ScenarioBar from '../../../components/ScenarioBar';
import { alertError, modalImage, modalAnswer } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import img1 from '../../../assets/images/escenario-51-recortes-1.png';
import img2 from '../../../assets/images/escenario-51-recortes-2.png';
import img3 from '../../../assets/images/escenario-51-recortes-3.png';
import img4 from '../../../assets/images/escenario-51-recortes-4.png';
import img5 from '../../../assets/images/escenario-51-recortes-5.png';
import img6 from '../../../assets/images/escenario-51-recortes-6.png';
import crackIcon from '../../../assets/crack-icon.svg';
import crossIcon from '../../../assets/cross-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '62d57f712061eb5d07300aa1';
const scenarioId = '62d57f712061eb5d07300a9f';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [PENALTY, setPENALTY] = useState(false);
	const [CHOICE, setCHOICE] = useState(null);
	const RIGHT_ANSWER = 1;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	function checkAnswer() {
		if (RIGHT_ANSWER === CHOICE) {
			setIsCompleted(true);
			if (PENALTY) {
				modalAnswer({
					html: `
					<div class='d-flex justify-content-center'>
						<img src=${pruebaNecesariaIcon} alt='test'/>			
						<span class='title3 txtGreen align-self-end'>
							¡Efectivamente!<br>
							<span class='bigTextB txtGrey mt-1'>
								Esa es la afirmación correcta.
							</span>
						</span>
					</div>`,
				}).then(() => submitAnswer());
			} else {
				modalAnswer({
					html: `
					<div class='d-flex justify-content-center'>
						<img src=${crackIcon} class='modal-crack-icon' alt='test'/>
						<span class='title3 txtGreen align-self-end'>
							¡Correcto!<br>
							<span class='bigTextB bold txtGrey mt-1'>
								Has ganado 
								<span class='modal-points'>
									+50 puntos.
								</span>
								
							</span>
						</span>
					</div>`,
				}).then(() => submitAnswer());
			}
		} else {
			setPENALTY(true);
			setCHOICE(null);
			modalAnswer({
				html: `
					<div class='d-flex justify-content-center'>
						<img src=${pruebaInnecesariaIcon} class='align-self-center' alt='test'/>
						<span class='title3 txtRed align-self-end'>
							¡Lástima!<br>
							<span class='bigTextB txtGrey'>
								Esta no es la afirmación verdadera.
								<p class='bold mt-1'>
									Inténtalo de nuevo.
								</p>
							</span>
						</span>
					</div>
					`,
				btnText: 'INTENTAR DE NUEVO',
			});
		}
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? 0 : 50;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, '', 1));
						updateStats({
							...group,
							score: group.score + finalScore,
							time: totalTime,
							lastVisited: '/diagnosticoc1',
						});
						navigate('/scenario5solucionc1', { state: { PENALTY } });
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (CHOICE != null) checkAnswer();
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setCHOICE(isChecked ? idx : null);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-25'>
					<div className='col-12 d-flex mt-5 justify-content-center px-5'>
						<div className='bigText justify-content-center px-5'>
							<div>
								{'Haz click sobre la imagen de cada recorte para ampliarla.'}
							</div>
							<div className='pt-3 '>
								{'Aclara las ideas a tu compañero '}
								<span className='title4 txtGrey'>
									{'seleccionando la respuesta correcta'}
								</span>
								;
							</div>
							<div>
								{'consíguelo al primer intento y ganarás '}
								<span className='destacadoVerde title4'>{'+50 puntos.'}</span>
							</div>
						</div>
					</div>
					<div className='row h-50 mt-4 m-1 justify-content-center'>
						<div className='col-10 d-flex'>
							<input
								type='checkbox'
								checked={CHOICE === 0}
								onChange={(event) => handleInputChange(event, 0)}></input>{' '}
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img1 })}>
								<img src={img1} alt='img1' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
							<input
								type='checkbox'
								checked={CHOICE === 3}
								onChange={(event) => handleInputChange(event, 3)}></input>
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img4 })}>
								<img src={img4} alt='img4' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
						</div>
					</div>
					<div className='row h-50 mt-4 m-1 justify-content-center'>
						<div className='col-10 d-flex'>
							<input
								type='checkbox'
								checked={CHOICE === 1}
								onChange={(event) => handleInputChange(event, 1)}></input>
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img2 })}>
								<img src={img2} alt='img2' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
							<input
								type='checkbox'
								checked={CHOICE === 4}
								onChange={(event) => handleInputChange(event, 4)}></input>
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img5 })}>
								<img src={img5} alt='img5' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
						</div>
					</div>
					<div className='row h-50 mt-4 m-1 justify-content-center'>
						<div className='col-10 d-flex'>
							<input
								type='checkbox'
								checked={CHOICE === 2}
								onChange={(event) => handleInputChange(event, 2)}></input>
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img3 })}>
								<img src={img3} alt='img3' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
							<input
								type='checkbox'
								checked={CHOICE === 5}
								onChange={(event) => handleInputChange(event, 5)}></input>
							<div
								className='d-flex container-puzzle w-50'
								onClick={() => modalImage({ image: img6 })}>
								<img src={img6} alt='img6' />
								<img src={crossIcon} alt='cross' className='btn-cross' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/scenario5solucionc1'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{'COMPROBAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={5} />
		</div>
	);
}

export default injectIntl(Test);
