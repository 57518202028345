import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import servicioReumatologia from '../../../assets/servicioReumatologia.png';
import carpetaNoSel from '../../../assets/carpetaNoSel.svg';
import carpetaSel from '../../../assets/carpetaSel.svg';
import hand from '../../../assets/hand.svg';
import { useSelector, useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

function Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	async function handleNavigate(e) {
		let time = getTimer();
		dispatch(updateTime(time, 1));
		if (await caseIsActive(gameId, 0)) {
			updateStats({
				...group,
				time: time,
				lastVisited: '/intranet2',
			});
			navigate('/intranet2');
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: time,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-100 bgIntranet'>
				<div className='justify-content-center position-relative url'>
					{'https://www.hospitalreumatologia.es'}
				</div>
				<div className='row pb-3'>
					<div className='col-12 text-center'>
						<img
							className='servicioReumatologia'
							src={servicioReumatologia}
							alt='Servicio de Reumatología · Hospital'
						/>
					</div>
				</div>
				<div className='row d-flex justify-content-center pt-5'>
					<div className='col-2 px-1 text-center'>
						<img
							className='carpetasIntranet pb-4'
							src={carpetaNoSel}
							alt='Investigación'
						/>
						<h5 className='title7 pt-2'>Investigación</h5>
						<p className='title7B'>Proyectos y herramientas</p>
					</div>
					<div className='col-2 px-1 text-center'>
						<img
							className='carpetasIntranet pb-3'
							src={carpetaNoSel}
							alt='Investigación'
						/>
						<h5 className='title7'>Formación</h5>
						<p className='title7B'>Cursos, seminarios, MIR</p>
					</div>
					<div className='col-2 px-1 text-center'>
						<img
							className='carpetasIntranet pb-4'
							src={carpetaSel}
							alt='Investigación'
						/>
						<h5 className='title7 pt-2'>Fichas</h5>
						<p className='title7B'>Casos clínicos gamificados</p>
					</div>
					<div className='col-2 px-1 text-center'>
						<img
							className='carpetasIntranet pb-4'
							src={carpetaNoSel}
							alt='Investigación'
						/>
						<h5 className='title7 pt-2'>Publicaciones</h5>
						<p className='title7B'>Revistas, boletines, manuales</p>
					</div>
				</div>
				<div className='row justify-content-center'>
					<div className='col-9 p-5'>
						<div
							className='d-flex align-items-center recuadro1 py-1 mx-auto'
							onClick={() => handleNavigate()}>
							<img className='px-3' src={hand} alt='Hand' />
							<div className='title7 bold txtGrey pe-3'>
								Haz clic aquí para avanzar
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeCase={1} activeScenario={2} expanded />
		</div>
	);
}

export default injectIntl(Intro);
