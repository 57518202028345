import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../translations/i18n';
import backgroundImage from '../assets/ranking/fondo-ranking.svg';
import profileContainer from '../assets/ranking/perfil-ranking.svg';
import scoreContainer from '../assets/ranking/contador-estrella.svg';
import timeContainer from '../assets/ranking/contador-tiempo.svg';
import { getGroupsByGame } from '../api/group';
import { useDispatch, useSelector } from 'react-redux';
import { formatScore, formatTime, updateStats } from '../utils/helpers';
import { SERVER_URL } from '../api';
import { setGroup } from '../redux/actions';

function Ranking({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const game =
		useSelector((state) => state.game)?._id || '633c2a2df7c3563a99b64f6c';
	const group = useSelector((state) => state.group);
	const [img1, setImg1] = useState(null);
	const [img2, setImg2] = useState(null);
	const [img3, setImg3] = useState(null);

	useEffect(() => {
		const asyncFetch = async () => {
			getGroupsByGame(game, group.actualCase)
				.then((res) => {
					if (res.status === 200) {
						setData(res.data);
						setImg1(`${SERVER_URL}/${res.data[0].image}`);
						setImg2(`${SERVER_URL}/${res.data[1].image}`);
						setImg3(`${SERVER_URL}/${res.data[2].image}`);
					}
				})
				.catch((error) => {});
		};
		asyncFetch();
	}, []);

	return (
		<div className='h-100'>
			<div
				className='container-fluid bgBlue h-100'
				onClick={() => {
					let nextUrl = group.actualCase === '2' ? '/finalranking' : '/start';
					dispatch(
						setGroup({
							...group,
							lastVisited: nextUrl,
							actualCase: '2',
						})
					);
					updateStats({
						...group,
						lastVisited: nextUrl,
						actualCase: '2',
						conflict: true,
					});
					navigate(nextUrl);
				}}>
				<div className='row text-center pt-5 justify-content-center'>
					<h1 className='title6'>{`Ranking caso clínico ${group.actualCase}`}</h1>
				</div>
				<div className='row text-center mt-4 justify-content-center'>
					<div className='col-8 d-flex align-items-center'>
						<div
							className='position-relative align-self-end'
							style={{ left: '35px' }}>
							<img src={scoreContainer} alt='' className='rankingContainer' />
							<div className='rankingText title8 txtWhite'>
								{formatScore(data[2]?.[`scoreCase${group.actualCase}`])}
							</div>
							<img src={timeContainer} alt='' className='rankingContainer' />
							<div className='rankingText-time title8 txtWhite'>
								{formatTime(data[2]?.[`timeCase${group.actualCase}`])}
							</div>
							<div className='figure-3rd'>
								<div className='figure-line'></div>
							</div>
							<div className='position-relative mt-4'>
								<div className='teamfoto-small'>
									<img src={img3} alt='' />
								</div>
								<img src={profileContainer} alt='' />
							</div>
							<div className='bigTextB txtWhite mt-2 mb-1'>
								{data[2]?.fullName || 'Nombre Equipo'}
							</div>
							<div className='separator m-auto' style={{ width: '55%' }}></div>
							<div className='bigTextB txtWhite mt-1 '>{`${
								'Mesa ' + (data[2]?.tableNum || ' ')
							}`}</div>
						</div>
						<div className='position-relative align-self-end'>
							<img src={scoreContainer} alt='' className='rankingContainer' />
							<div className='rankingText title8 txtWhite'>
								{formatScore(data[0]?.[`scoreCase${group.actualCase}`])}
							</div>
							<img src={timeContainer} alt='' className='rankingContainer' />
							<div className='rankingText-time title8 txtWhite'>
								{formatTime(data[0]?.[`timeCase${group.actualCase}`])}
							</div>
							<div className='figure-1st'>
								<div className='figure-line'></div>
							</div>
							<div className='position-relative mt-4'>
								<div className='teamfoto-small'>
									<img src={img1} alt='' />
								</div>
								<img src={profileContainer} alt='' />
							</div>
							<div className='bigTextB txtWhite mt-2 mb-1'>
								{data[0]?.fullName || 'Nombre Equipo'}
							</div>
							<div className='separator m-auto' style={{ width: '55%' }}></div>
							<div className='bigTextB txtWhite mt-1 '>
								{`${'Mesa ' + (data[0]?.tableNum || ' ')}`}
							</div>
						</div>
						<div
							className='position-relative align-self-end'
							style={{ right: '35px' }}>
							<img src={scoreContainer} alt='' className='rankingContainer' />
							<div className='rankingText title8 txtWhite'>
								{formatScore(data[1]?.[`scoreCase${group.actualCase}`])}
							</div>
							<img src={timeContainer} alt='' className='rankingContainer' />
							<div className='rankingText-time title8 txtWhite'>
								{formatTime(data[1]?.[`timeCase${group.actualCase}`])}
							</div>
							<div className='figure-2nd'>
								<div className='figure-line'></div>
							</div>
							<div className='position-relative mt-4' style={{ left: '-5px' }}>
								<div className='teamfoto-small'>
									<img src={img2} alt='' />
								</div>
								<img src={profileContainer} alt='' />
							</div>
							<div className='bigTextB txtWhite mt-2 mb-1'>
								{data[1]?.fullName || 'Nombre Equipo'}
							</div>
							<div className='separator m-auto' style={{ width: '55%' }}></div>
							<div className='bigTextB txtWhite mt-1'>
								{`${'Mesa ' + (data[1]?.tableNum || ' ')}`}
							</div>
						</div>
					</div>
				</div>
			</div>
			<img
				className='background-image'
				style={{ left: '40px', bottom: '-85px' }}
				src={backgroundImage}
				alt=''
			/>
			<div className='lineBottom yellowBottom'></div>
		</div>
	);
}

export default injectIntl(Ranking);
