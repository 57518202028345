import { baseClient, API } from '../index';
import { upload, uploadSingleFile } from '../files';

export const postGroup = async (group, image) => {
	let res = await upload(image);
	group.image = res.fileURL;
	console.log(res, res.fileURL);
	return baseClient().post(`${API}/group`, group);
};

export const updateGroup = async (id, group, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'groups');
		group.image = response.data.fileURL;
	}
	return baseClient().put(`${API}/group/${id}`, group);
};

// Get group by game
export const getGroupsByGame = async (id, activeCase) => {
	return baseClient().get(`${API}/group/game${activeCase}/${id}`);
};

// Get group by table & game
export const getGroupByTableAndGame = async (table, game) => {
	return baseClient().get(`${API}/group/table/${table}/game/${game}`);
};
