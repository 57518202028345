import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import { modalImage } from '../../../utils/logger';
import testimg1 from '../../../assets/images/testimg21-sol.png';
import testimg2 from '../../../assets/images/testimg22-sol.png';
import testimg3 from '../../../assets/images/testimg23-sol.png';
import testimg4 from '../../../assets/images/testimg24-sol.png';
import fullscreenIcon from '../../../assets/fullscreen.png';
import { useSelector, useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [ACTIVE, setACTIVE] = useState(0);

	const DATA = [
		{
			text: 'Neumonía intersticial no específica (NINE)',
			answer: 'Presencia de reticulación y áreas de vidrio deslustrado',
			img: testimg1,
		},
		{
			text: 'Neumonía intersticial usual (NIU)',
			answer: 'Bronquiectasias de tracción y áreas de panalización',
			img: testimg2,
		},
		{
			text: 'Neumonía organizada',
			answer: 'Área de consolidación (organización)',
			img: testimg3,
		},
		{
			text: 'Bronquiolitis folicular',
			answer: 'Opacidades nodulares bilaterales',
			img: testimg4,
		},
	];

	const getColorClass = () => {
		switch (ACTIVE) {
			case 0:
				return 'bgBlueC';
			case 1:
				return 'bgOrange';
			case 2:
				return 'bgGreen';
			case 3:
				return 'bgYellowB';
		}
	};

	async function handleNext(e) {
		e.preventDefault();
		let time = getTimer();
		dispatch(updateTime(time, 2));
		if (await caseIsActive(gameId, 1)) {
			if (ACTIVE > 2) {
				updateStats({
					...group,
					time: time,
					lastVisited: '/interconsulta',
				});
				navigate('/interconsulta');
			} else setACTIVE(ACTIVE + 1);
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: time,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function renderImage() {
		let image = DATA[ACTIVE].img;
		return (
			<div className='active mx-2'>
				<img
					className='img-fluid m-0'
					src={image}
					alt={`Test image`}
					style={{ width: '481px', height: '339px' }}
				/>
				<img
					className='fullscreenB active'
					src={fullscreenIcon}
					alt='fullscreen'
					onClick={() => modalImage({ image: image, fullscreen: true })}
				/>
			</div>
		);
	}

	function renderAnswer() {
		return (
			<div className='m-5 px-4'>
				<div className='bigTextB bold mt-5 txtBlueB'>{DATA[ACTIVE].text}</div>
				<div className='d-flex align-items-center mt-4'>
					<div className={`forth-arrow ${getColorClass()}`}></div>
					<div className='ms-3 bigTextB'>{DATA[ACTIVE].answer}</div>
				</div>
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='align-items-center justify-content-center d-flex bgBlue'>
				{renderImage()}
			</div>

			{renderAnswer()}
			<Link
				to='/interconsulta'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				AVANZAR
			</Link>
			<ScenarioBar activeCase={2} activeScenario={5} />
		</div>
	);
}

export default injectIntl(Test);
