import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { postAnswer } from '../../../api/answer';
import ScenarioBar from '../../../components/ScenarioBar';
import { alertError, modalImage, modalAnswer } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import testimg1 from '../../../assets/images/testimg1.png';
import testimg2 from '../../../assets/images/testimg2.png';
import testimg3 from '../../../assets/images/testimg3.png';
import testimg4 from '../../../assets/images/testimg4.png';
import fullscreenIcon from '../../../assets/fullscreen.png';
import crackIcon from '../../../assets/crack-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import carpeta from '../../../assets/carpeta.svg';
import remember from '../../../assets/remember-icon.svg';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '62d57f6c2061eb5d07300a96';
const scenarioId = '62d57f6c2061eb5d07300a94';

const defaultAnswers = {
	0: null,
	1: null,
	2: null,
	3: null,
};
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [PENALTY, setPENALTY] = useState(0);
	const [ACTIVE, setACTIVE] = useState(-1);
	const [ANSWERS, setANSWERS] = useState(defaultAnswers);
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const RIGHT_ANSWERS = [1, 0, 2, 1];

	const QUESTIONS = [
		{
			txt: '¿Qué muestra esta radiografía de manos?',
			img: testimg1,
			options: [
				'Erosiones en los márgenes de las articulaciones compatibles con artritis reumatoide',
				'Signos de disminución de espacio articular y esclerosis subcondral de predominio en IFD e IFP compatible con artrosis',
				'Destrucción de articulaciones IFD con signo de lápiz-copa compatible con artritis psoriásica',
				'Signos de proliferación ósea y anquilosis compatible con artritis inflamatoria',
			],
		},
		{
			txt: '¿Qué muestra esta ecografía de carpo derecho?',
			img: testimg2,
			options: [
				'Sinovitis grado II en articulación radiocarpiana',
				'Sinovitis grado I en articulación radiocarpiana',
				'Tenosinovitis de tendones extensores del carpo',
				'Sinovitis grado I en articulación radiocarpiana',
			],
		},
		{
			txt: '¿Qué muestran estas radiografías de hombros?',
			img: testimg3,
			options: [
				'Fractura en la zona proximal del húmero compatible con posible osteoporosis',
				'Erosión en articulación acromioclavicular, con signos de artritis inflamatoria evolucionada',
				'Disminución de espacio articular acromioclavicular, con signos de artrosis degenerativa',
				'Importante esclerosis ósea subcondral de la cabeza humeral y destrucción del espacio articular compatible con hombro de Milwaukee',
			],
		},
		{
			txt: '¿Qué muestran estas ecografías de hombro derecho?',
			img: testimg4,
			options: [
				'Sinovitis de la articulación acromioclavicular y tendinopatía del supraespinoso',
				'Bursitis subdeltoidea y leve derrame en la vaina del bíceps',
				'Tenosinovitis bicipital y derrame en receso posterior',
				'Rotura completa del supraespinoso con calcificación en tendón del bíceps',
			],
		},
	];

	const formatIndex = (idx) => {
		return ['A', 'B', 'C', 'D'][idx];
	};

	function checkAnswer() {
		counter++;
		const lastTry = counter > 3;
		const firstTry = counter < 2;
		let wrong = 0;
		RIGHT_ANSWERS.forEach((answer, idx) => {
			if (answer != ANSWERS[idx]) wrong++;
		});
		if (wrong) {
			setPENALTY(PENALTY + 25);
			setANSWERS(defaultAnswers);
			setACTIVE(0);
			modalAnswer({
				title: '¡Lástima!',
				type: 'wrong',
				html: `
					<div class='d-flex'>
						<img src=${pruebaInnecesariaIcon} alt='test'/>
						<span>Has fallado en 
							<span class='bold'>
								${wrong > 1 ? wrong + ' imágenes' : wrong + ' imagen'} 
							</span>
							y el control del ordenador sigue en manos del hacker.
							${
								!lastTry
									? `<p class='txtBlue bold'>
									<br>Inténtalo de nuevo.
								</p>`
									: `<p>
									<br>Has agotado los <span class='bold'>4 intentos</span>. Avanza para descubrir la solución.
								</p>`
							}
						</span>
					</div>
					`,
				btnText: lastTry ? 'AVANZAR' : 'INTENTAR DE NUEVO',
			}).then(() => {
				if (lastTry) submitAnswer(false);
			});
		} else {
			setIsCompleted(true);
			modalAnswer({
				title: !firstTry ? '¡Ahora sí!' : '¡Eres un crack!',
				type: 'right',
				html: `
					<div class='d-flex'>
						<img src=${!firstTry ? pruebaNecesariaIcon : crackIcon} class='${
					!firstTry ? 'modal-crack-icon' : ''
				}' alt='test'/>
						${
							!firstTry
								? `<span class='bold'>Has superado la prueba
									<span class='normal'>
									 y has recuperado el control de tu ordenador... aunque has perdido
										<span class='bold'>
											${(counter - 1) * 25} puntos.
										</span>
										<p><br>Echa un vistazo a la solución y después podrás seguir con el caso de Pilar.</p>
									</span>							
								</span>`
								: `<span class='bold'>Has superado la prueba sin penalizaciones
							<span class='normal'>
								y has recuperado el control de tu ordenador.
								<p>Echa un vistazo a la solución y después podrás seguir con el caso de Pilar.</p>
							</span>							
						</span>`
						}
					</div>
					`,
				btnText: 'VER SOLUCIÓN',
			}).then(() => submitAnswer(true));
		}
	}

	async function submitAnswer(correct) {
		let penalty = correct ? (counter - 1) * 25 : counter * 25;
		const finalScore = counter ? Math.max(-Math.abs(penalty), -100) : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 1));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/scenario4solucionc1',
						});
						navigate('/scenario4solucionc1');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (ACTIVE < 0) setACTIVE(0);
		else {
			if (!Object.keys(ANSWERS).some((idx) => ANSWERS[idx] === null))
				checkAnswer();
			else {
				modalAnswer({
					html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='bold'>
							Recuerda
							<span class='normal'>
								que debes seleccionar una <br/>
								respuesta en cada imagen para poder avanzar.
							</span>
						</span>
					</div>
					`,
					btnText: 'SEGUIR',
				});
			}
		}
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setANSWERS((ANSWERS) => ({ ...ANSWERS, [ACTIVE]: isChecked ? idx : null }));
	}

	function renderCarousel() {
		return QUESTIONS.map((question, idx) => {
			let isActive = ACTIVE === idx;
			return (
				<div
					key={idx}
					className={isActive ? `active` : ''}
					style={{ height: '160px' }}
					onClick={() => setACTIVE(idx)}>
					<img
						className={`${ACTIVE < 0 ? 'px-1' : 'testimg'} img-fluid`}
						src={question.img}
						alt={`Test image ${idx + 1}`}
					/>
					{isActive && (
						<>
							<img
								className='fullscreen'
								src={fullscreenIcon}
								alt='fullscreen'
								onClick={() =>
									modalImage({ image: question.img, fullscreen: true })
								}
							/>
							<div className='img-number numberB textNumberB'>{idx + 1}</div>
						</>
					)}
					{ACTIVE < 0 && (
						<div className='img-number numberA textNumberA'>{idx + 1}</div>
					)}
				</div>
			);
		});
	}

	function renderQuestion() {
		return (
			<div
				className='col-10 offset-1 position-absolute z-indez-master'
				style={{ top: '350px' }}>
				<div className='pb-3 title4'>{QUESTIONS[ACTIVE].txt}</div>
				{QUESTIONS[ACTIVE].options.map((option, idx) => (
					<div key={idx} className='my-2'>
						<div className='bigText d-flex justify-content-start align-items-start'>
							<div>
								<input
									type='checkbox'
									checked={ANSWERS[ACTIVE] === idx}
									onChange={(event) => handleInputChange(event, idx)}></input>
							</div>
							<div className='mx-2'>
								<span className='title4 txtGrey'>{formatIndex(idx)}. </span>
								{option}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-100'>
					<div className='col-12 d-flex mt-5 justify-content-center'>
						<div className='d-flex justify-content-center'>
							{renderCarousel()}
						</div>
					</div>
					{ACTIVE < 0 ? (
						<div
							className='col-10 offset-1 pb-5'
							style={{ marginBottom: '115px' }}>
							<div className='recuadro1 py-4 px-5 mb-5 mx-5'>
								<div className='d-flex'>
									<img className='px-4' src={carpeta} alt='Carpeta' />
									<div className='bigTextB txtGrey'>
										Abre la carpeta de materiales para ver las imágenes
										impresas.
									</div>
								</div>
								<div className='bigTextB txtGrey mt-3 mx-3'>
									Recuerda que has de seleccionar una descripción por
									radiografía antes de confirmar.
								</div>
							</div>
						</div>
					) : (
						renderQuestion()
					)}
				</div>
			</div>
			<Link
				to='/scenario4solucionc1'
				className='bgYellow btn-trap btn-next'
				onClick={handleNext}>
				{ACTIVE < 0 ? 'AVANZAR' : 'CONFIRMAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Test);
