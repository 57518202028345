import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import info from '../../../assets/info.png';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75'>
					<div className='col-11 mx-auto d-flex align-items-center'>
						<div className='w-100'>
							<div className=''>
								<div className='bigTextB txtBlue top-arrow'>
									Pasa a la imagen anterior o a la siguiente (es indiferente),
									obsérvala y selecciona su patrón.
								</div>
								<div className='bottom-arrow'></div>
							</div>
							<div className='mx-auto d-flex'>
								<div className='bigTextB txtBlue bottom-right-arrow'>
									Observa la imagen y
									<span className='bold'> selecciona el patrón</span> que crees
									que muestra.
								</div>
								<img
									className='img-fluid'
									style={{ width: '546px', height: '280px' }}
									src={info}
									alt='capcha'
								/>
								<div className='bigTextB txtBlue bottom-left-arrow'>
									Cuando hayas relacionado todas las imágenes con un patrón, se
									activará el botón
									<div className='bgYellow btn-trap-small'>CONFIRMAR</div>
								</div>
							</div>
							<div className='bigTextB bold mt-5'>
								Dispones de 4 intentos para resolver esta prueba.
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/noeresunrobot5'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Intro);
