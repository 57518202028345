import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import App from './App';
import TopBar from './components/TopBar';
import Home from './pages/Home';
import Register from './pages/Register';
import Start from './pages/Start';

import StartC1 from './pages/case1/Startc1';
import InstruccionesC1 from './pages/case1/InstruccionesC1';
import ControlaTiempoC1 from './pages/case1/ControlaTiempoC1';
import AveriguaC1 from './pages/case1/AveriguaC1';

import Scenario1IntroC1 from './pages/case1/Scenario 1/Intro';
import Scenario1TestC1 from './pages/case1/Scenario 1/Test';

import Scenario2IntroC1 from './pages/case1/Scenario 2/Intro';
import Scenario2C1 from './pages/case1/Scenario 2/Scenario2';

import Scenario3IntroC1 from './pages/case1/Scenario 3/Intro';
import Scenario3TestC1 from './pages/case1/Scenario 3/Test';

import Scenario4Intro1C1 from './pages/case1/Scenario 4/Intro';
import Scenario4Intro2C1 from './pages/case1/Scenario 4/Intro2';
import Scenario4TestC1 from './pages/case1/Scenario 4/Test';
import Scenario4AnswersC1 from './pages/case1/Scenario 4/Answers';

import Scenario5IntroC1 from './pages/case1/Scenario 5/Intro';
import Scenario5TestC1 from './pages/case1/Scenario 5/Test';
import Scenario5AnswersC1 from './pages/case1/Scenario 5/Answers';

import Scenario6IntroC1 from './pages/case1/Scenario 6/Intro';
import Scenario6TestC1 from './pages/case1/Scenario 6/Test';

import Scenario7IntroC1 from './pages/case1/Scenario 7/Intro';
import Scenario7TestC1 from './pages/case1/Scenario 7/Test';

import SupertestIntroC1 from './pages/case1/Supertest/Intro';
import SupertestC1 from './pages/case1/Supertest/Test';
import SupertestAnswersC1 from './pages/case1/Supertest/Answers';

import StartC2 from './pages/case2/Startc2';
import InstruccionesC2 from './pages/case2/InstruccionesC2';
import ControlaTiempoC2 from './pages/case2/ControlaTiempoC2';
import AveriguaC2 from './pages/case2/AveriguaC2';

import Scenario1IntroC2 from './pages/case2/Scenario 1/Intro';
import Scenario1TestC2 from './pages/case2/Scenario 1/Test';

import Scenario2IntroC2 from './pages/case2/Scenario 2/Intro';
import Scenario2Intro2C2 from './pages/case2/Scenario 2/Intro2';
import Scenario2C2 from './pages/case2/Scenario 2/Scenario2';

import Scenario3IntroC2 from './pages/case2/Scenario 3/Intro';
import Scenario3TestC2 from './pages/case2/Scenario 3/Test';

import Scenario4IntroC2 from './pages/case2/Scenario 4/Intro';
import Scenario4Intro2C2 from './pages/case2/Scenario 4/Intro2';
import Scenario4Intro3C2 from './pages/case2/Scenario 4/Intro3';
import Scenario4Intro4C2 from './pages/case2/Scenario 4/Intro4';
import Scenario4Intro5C2 from './pages/case2/Scenario 4/Intro5';
import Scenario4TestC2 from './pages/case2/Scenario 4/Test';
import Scenario4AnswersC2 from './pages/case2/Scenario 4/Answers';

import Scenario5IntroC2 from './pages/case2/Scenario 5/Intro';
import Scenario5Intro3C2 from './pages/case2/Scenario 5/Intro3';
import Scenario5TestC2 from './pages/case2/Scenario 5/Test';

import Scenario6IntroC2 from './pages/case2/Scenario 6/Intro';
import Scenario6TestC2 from './pages/case2/Scenario 6/Test';

import Scenario7IntroC2 from './pages/case2/Scenario 7/Intro';
import Scenario7TestC2 from './pages/case2/Scenario 7/Test';

import SupertestIntroC2 from './pages/case2/Supertest/Intro';
import SupertestC2 from './pages/case2/Supertest/Test';

import End from './pages/End';
import Gameover from './pages/Gameover';
import Ranking from './pages/Ranking';
import FinalRanking from './pages/FinalRanking';
import Results from './pages/Results';
import { shallowEqual } from 'react-redux';

export default function Rutas() {
	const isRegistered = useSelector((state) => state.group, shallowEqual);

	return (
		<BrowserRouter>
			{isRegistered && <TopBar />}
			<Routes>
				<Route path='/' element={<App />}>
					<Route path='/' element={<Home />} />
					<Route path='/register' element={<Register />} />

					{!isRegistered ? (
						/*Render register page when not registered.*/
						<Route path='*' element={<Navigate to='/' replace />} />
					) : (
						<Route>
							<Route path='/start' element={<Start />} />
							<Route path='/end' element={<End />} />
							<Route path='/gameover' element={<Gameover />} />
							<Route path='/results' element={<Results />} />
							<Route path='/ranking' element={<Ranking />} />
							<Route path='/finalranking' element={<FinalRanking />} />

							{/* Cas 1 */}
							<Route path='/startc1' element={<StartC1 />} />
							<Route path='/instruccionesc1' element={<InstruccionesC1 />} />
							<Route path='/controlatiempoc1' element={<ControlaTiempoC1 />} />
							<Route path='/averiguac1' element={<AveriguaC1 />} />
							<Route path='/hablapilarc1' element={<Scenario1IntroC1 />} />
							<Route path='/scenario1testc1' element={<Scenario1TestC1 />} />
							<Route path='/intranet' element={<Scenario2IntroC1 />} />
							<Route path='/intranet2' element={<Scenario2C1 />} />
							<Route path='/pruebasclinicasc1' element={<Scenario3IntroC1 />} />
							<Route path='/scenario3testc1' element={<Scenario3TestC1 />} />
							<Route path='/hackeadoc1' element={<Scenario4Intro1C1 />} />
							<Route path='/scenario4introc1' element={<Scenario4Intro2C1 />} />
							<Route path='/scenario4testc1' element={<Scenario4TestC1 />} />
							<Route
								path='/scenario4solucionc1'
								element={<Scenario4AnswersC1 />}
							/>
							<Route path='/geneticac1' element={<Scenario5IntroC1 />} />
							<Route path='/scenario5testc1' element={<Scenario5TestC1 />} />
							<Route
								path='/scenario5solucionc1'
								element={<Scenario5AnswersC1 />}
							/>
							<Route path='/diagnosticoc1' element={<Scenario6IntroC1 />} />
							<Route path='/scenario6testc1' element={<Scenario6TestC1 />} />
							<Route path='/tratamientoc1' element={<Scenario7IntroC1 />} />
							<Route path='/scenario7Testc1' element={<Scenario7TestC1 />} />
							<Route path='/ganamaspuntosc1' element={<SupertestIntroC1 />} />
							<Route path='/supertestc1' element={<SupertestC1 />} />
							<Route
								path='/supertestsolucionc1'
								element={<SupertestAnswersC1 />}
							/>
							{/* Cas 2 */}
							<Route path='/startc2' element={<StartC2 />} />
							<Route path='/instruccionesc2' element={<InstruccionesC2 />} />
							<Route path='/controlatiempoc2' element={<ControlaTiempoC2 />} />
							<Route path='/averiguac2' element={<AveriguaC2 />} />
							<Route path='/hablamanoloc2' element={<Scenario1IntroC2 />} />
							<Route path='/scenario1testc2' element={<Scenario1TestC2 />} />
							<Route path='/unfolleto' element={<Scenario2IntroC2 />} />
							<Route path='/unfolleto2' element={<Scenario2Intro2C2 />} />
							<Route path='/unfolleto3' element={<Scenario2C2 />} />
							<Route path='/pruebasclinicasc2' element={<Scenario3IntroC2 />} />
							<Route path='/scenario3testc2' element={<Scenario3TestC2 />} />
							<Route path='/noeresunrobot' element={<Scenario4IntroC2 />} />
							<Route path='/noeresunrobot2' element={<Scenario4Intro2C2 />} />
							<Route path='/noeresunrobot3' element={<Scenario4Intro3C2 />} />
							<Route path='/noeresunrobot4' element={<Scenario4Intro4C2 />} />
							<Route path='/noeresunrobot5' element={<Scenario4Intro5C2 />} />
							<Route path='/scenario4testc2' element={<Scenario4TestC2 />} />
							<Route
								path='/scenario4solucionc2'
								element={<Scenario4AnswersC2 />}
							/>
							<Route path='/interconsulta' element={<Scenario5IntroC2 />} />
							<Route path='/interconsulta3' element={<Scenario5Intro3C2 />} />
							<Route path='/scenario5testc2' element={<Scenario5TestC2 />} />
							<Route path='/diagnosticoc2' element={<Scenario6IntroC2 />} />
							<Route path='/scenario6testc2' element={<Scenario6TestC2 />} />
							<Route path='/tratamientoc2' element={<Scenario7IntroC2 />} />
							<Route path='/scenario7testc2' element={<Scenario7TestC2 />} />
							<Route path='/ganamaspuntosc2' element={<SupertestIntroC2 />} />
							<Route path='/supertestc2' element={<SupertestC2 />} />
						</Route>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
