import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import {
	alertError,
	modalQuizCompleted,
	modalAudio,
	modalAnswer,
} from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import remember from '../../../assets/remember-icon.svg';

const quizId = '6310c7d10e9a26315a833393';
const scenarioId = '62d7fccdb260ea1d73de0d34';
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const RIGHT_ANSWERS = [3, 4];
	const [checkedAnswers, setCheckedAnswers] = useState([]);
	const [ANSWERS, setANSWERS] = useState([]);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'¿Presenta rigidez de las articulaciones por la mañana, cuando se levanta?',
		'¿Qué articulaciones se le inflaman?',
		'Las articulaciones que se le inflaman ¿lo hacen todas a la vez?',
		'¿Qué quiere decir con que además tiene cansancio?',
		'¿Qué medicación toma actualmente? ¿La toma siempre?',
		'¿Tiene alergia a alguna medicación?',
		'¿Le han operado alguna vez de algo?',
		'¿Tiene antecedentes en su familia de enfermedades destacables?',
	];
	const FEEDBACKS = [
		'Me levanto como un robot: hasta que no entro en calor con un par de carajillos y la ducha calentita, no tiro.',
		'¡Buuuuuf! Pues, oiga: casi todas. Es más, iría más rápido si le dijera las que no se me inflaman.',
		'Según va: a veces unas, a veces otras, pero siempre tengo varias articulaciones inflamadas… Generalmente, las de las manos, rodillas, codos y hombros. Es muy duro ser autónomo.',
		'Que me fatigo y me falta el aire. Además, muchas veces me entra una tos sin mocos, no arranco nada. Fumo menos desde que me pasa. Entre esto y lo de las manos, estoy hecho una braga.',
		`Pues tomo lo de la artritis: la cortisona que me dieron en su día, el Metoject<span class='superindex-big'>®</span> de 20 con la pastilla del día después, algún «profeno» y algún paracetamol de tanto en tanto. Y para el corazón tomo el enalapril, el acenocumarol, la amiodarona y la del colesterol. <br><br>Y en cuanto a si tomo siempre la medicación… a ver… las del reuma sí que las he tomado siempre. Lo del corazón lo dejé un tiempo después del infarto, aunque ahora sí lo tomo bien, desde que me dio la arritmia hace como un año o así.`,
		'Al marisco. Ya ve, tener un bar y ser alérgico al marisco…',
		'¡Eso me habría faltado! Qué va, doctor, qué va…',
		'Pues, ahora que lo dice, se ve que mi abuelo por parte de madre estaba muy mal del reuma, pero yo no lo conocí. A mi abuelo, me refiero.',
	];

	function checkAnswer(index) {
		let correct = RIGHT_ANSWERS.includes(index);
		if (!checkedAnswers.includes(index)) {
			setCheckedAnswers([...checkedAnswers, index]);
			if (RIGHT_ANSWERS.includes(index)) {
				if (!ANSWERS.includes(index)) {
					counter++;
					setANSWERS([...ANSWERS, index]);
				}
				return 'right';
			}
			return 'selected';
		}
		return correct ? 'right' : 'selected';
	}

	async function answerHandler(index) {
		let res = checkAnswer(index - 1);
		await modalAudio({
			title: `${index}. ${QUESTIONS[index - 1]}`,
			html: FEEDBACKS[index - 1],
		}).then(() => {
			if (counter === 2 && !isCompleted) {
				modalQuizCompleted({
					title: '¡Ya le has hecho a tu paciente las 2 preguntas clave!',
					html: `<p>Pero aún puede haber otras preguntas que aporten información útil.</p>
<p>¿Quieres seguir preguntando o prefieres dar por finalizada la visita de tu paciente? Una vez que salga de tu consulta, ya no prodrás preguntarle nada más...</p>
<p>Elijas lo que elijas, ya tienes <span class="modal-points">+300 puntos.</span></p>`,
					confirmText: 'ACABO LA VISITA',
					cancelText: 'SIGO PREGUNTANDO',
				}).then((res) => {
					if (res.isConfirmed) {
						submitAnswer();
					} else setIsCompleted(true);
				});
			}
		});
		return res;
	}

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: 300,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(300, '', 2));
						updateStats({
							...group,
							score: group.score + 300,
							time: totalTime,
							lastVisited: '/unfolleto',
						});
						navigate('/unfolleto');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		} else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='normal'>
							Aún no has hecho a Manolo las
							<span class='bold'>
								2 preguntas fundamentales.
							</span>
							<br><br>¡Sigue preguntando!
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
				containerClass: 'modal-answer-containerB',
			});
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={FEEDBACKS[0]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={FEEDBACKS[4]}
							showSelectedIndicator
							loadingStatus={'right'}
							selected={checkedAnswers.includes(4)}
							handler={answerHandler}
						/>
					</div>

					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={FEEDBACKS[1]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={FEEDBACKS[5]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={FEEDBACKS[2]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={7}
							text={QUESTIONS[6]}
							feedback={FEEDBACKS[6]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={FEEDBACKS[3]}
							showSelectedIndicator
							loadingStatus={'right'}
							selected={checkedAnswers.includes(3)}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={8}
							text={QUESTIONS[7]}
							feedback={FEEDBACKS[7]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/unfolleto'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={2} activeScenario={1} />
		</div>
	);
}

export default injectIntl(Test);
