import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { modalImage } from '../../../utils/logger';
import ScenarioBar from '../../../components/ScenarioBar';
import img1 from '../../../assets/folleto/folleto1.png';
import img2 from '../../../assets/folleto/folleto2.png';
import img3 from '../../../assets/folleto/folleto3.png';
import img4 from '../../../assets/folleto/folleto4.png';
import fullscreenIcon from '../../../assets/fullscreen.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

function Scenario2({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [ACTIVE, setACTIVE] = useState(0);

	const CONTENT = [
		{
			title:
				'Enfermedad pulmonar intersticial difusa-artritis reumatoide (EPID-AR)',
			tab: <span style={{ lineHeight: '37px' }}>EPID-AR</span>,
			img: img1,
			imgDescription:
				'En la imagen, TCAR de paciente con AR y EPID con patrón radiológico tipo neumopatía intersticial usual (NIU), con áreas de panalización extensa en ambos pulmones.',
			text: [
				<span>
					La EPID es una de las manifestaciones extraarticulares más frecuentes
					y graves de la AR. La prevalencia de EPID sintomática varía entre un
					10 % y un 40 %, siendo hoy en día la segunda causa de muerte en la AR,
					por detrás de las complicaciones cardiovasculares.
				</span>,
				<div className='smallText italics line-spacing-small'>
					Fuente:
					https://www.ser.es/recomendaciones-ser-separ-sobre-diagnostico-y-tratamiento-de-la-enfermedad-pulmonar-intersticial-difusa-epid-asociada-a-la-artritis-reumatoide-ar/
				</div>,
				<span className='txtBlueC lightB'>
					<span class='boldB'>Notas: </span>
					En el caso de Manuel, aparece disnea progresiva con tos seca en
					paciente con artritis reumatoide de largo tiempo de evolución que,
					además, tiene FR y ACPA positivos.
				</span>,
			],
		},
		{
			title: 'Exacerbación de EPOC',
			tab: (
				<span>
					Exacerbación
					<br />
					de EPOC
				</span>
			),
			img: img2,
			imgDescription:
				'El tabaco está muy implicado como causa de limitación crónica del flujo aéreo y es una de las causas más habituales de EPOC.',
			text: [
				<span>
					Es definida como un episodio agudo en el curso de la enfermedad de vía
					aérea crónica, caracterizada por un empeoramiento de los síntomas del
					paciente, más allá de las variaciones normales del día a día, por lo
					que se hace necesario un cambio en la medicación.
				</span>,
				<div className='smallText italics line-spacing-small'>
					Fuente:
					https://mgyf.org/exacerbacion-la-enfermedad-pulmonar-obstructiva-cronica/
				</div>,
				<span className='txtBlueC lightB'>
					<span class='boldB'>Notas: </span>
					Empeoramiento de la disnea en paciente fumador importante; en estos
					casos, se suele presentar expectoración matutina, además de la tos, y
					afectación de la vía aérea. ¿Será esto lo que tiene Manuel?
				</span>,
			],
		},
		{
			title: 'Insuficiencia cardiaca',
			tab: (
				<span>
					Insuficiencia
					<br />
					cardiaca
				</span>
			),
			img: img3,
			imgDescription:
				'Radiografía simple de paciente con insuficiencia cardiaca congestiva. La disnea, junto con la aparición de edemas en los miembros inferiores, son los signos guía de incompetencia cardiaca en estos pacientes.',
			text: [
				<span>
					Las manifestaciones clínicas clásicas de la IC son la astenia y la
					fatigabilidad, y los síntomas congestivos, como disnea de esfuerzo
					progresiva hasta el reposo, ortopnea, tos nocturna, disnea paroxística
					nocturna, distensión abdominal o edemas maleolares por retención
					hidrosalina, que pueden conllevar ganancia de peso, o dolor abdominal
					por hígado de estasis o ascitis a tensión.
				</span>,
				<div className='smallText italics line-spacing-small'>
					Fuente:
					https://www.elsevier.es/es-revista-seminarios-fundacion-espanola-reumatologia-274-articulo-abc-insuficiencia-cardiaca-S1577356611000030
				</div>,
				<span className='txtBlueC lightB'>
					<span class='boldB'>Notas: </span>
					Manuel es un paciente cardiópata con edemas en miembros inferiores;
					estos edemas pueden indicar la presencia de insuficiencia cardiaca en
					pacientes con factores de riesgo cardiovascular.
				</span>,
			],
		},
		{
			title: 'Toxicidad farmacológica',
			tab: (
				<span>
					Toxicidad
					<br />
					farmacológica
				</span>
			),
			img: img4,
			imgDescription:
				'TCAR de paciente con neumonía organizada tras exposición a amiodarona.',
			text: [
				<span>
					La amiodarona puede ocasionar una toxicidad pulmonar fatal (neumonitis
					o fibrosis pulmonar) y debe ser utilizada con suma precaución en
					pacientes con historia de enfermedades pulmonares. También deberá
					usarse con precaución en pacientes con insuficiencia cardiaca debido a
					sus efectos inotrópicos negativos, y en sujetos con la función
					hepática alterada.
				</span>,
				<div className='smallText italics line-spacing-small'>
					Fuente: https://www.iqb.es/cbasicas/farma/farma04/a047.html
				</div>,
				<span className='txtBlueC lightB'>
					<span class='boldB'>Notas: </span>
					Disnea progresiva en paciente en tratamiento activo con fármacos con
					potencial tóxico pulmonar (amiodarona, metotrexato).
				</span>,
			],
		},
	];

	function renderTabs() {
		return (
			<div className='row'>
				<div className='col-12' style={{ paddingLeft: '0px' }}>
					<ul className='nav nav-tabs' style={{ marginTop: '35px' }}>
						{CONTENT.map((item, idx) => (
							<li
								className='nav-item text-center'
								onClick={() => setACTIVE(idx)}>
								<div
									className={`nav-link px-4 ${ACTIVE === idx ? 'active' : ''}`}>
									{item.tab}
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}

	function renderContent() {
		let data = CONTENT[ACTIVE];
		return (
			<div className='row pb-5 folletoContainer'>
				<div className='col-6 contentIntranet'>
					<div className='title4'>{data.title}</div>
					<div className='bigTextB txtGrey'>
						{data.text.map((item) => (
							<div className='py-2'>{item}</div>
						))}
					</div>
				</div>
				<div className='col-6 ps-5'>
					<img
						src={data.img}
						className='img-fluid ps-4'
						alt='Imagen intranet 1'
					/>
					<img
						className='content-fullscreen'
						src={fullscreenIcon}
						alt='fullscreen'
						onClick={() =>
							modalImage({
								image: data.img,
								fullscreen: true,
								containerClass: 'modal-image-containerB',
							})
						}
					/>
					<div
						className='smallText bigTextB txtGrey ps-4'
						style={{ marginTop: '-15px' }}>
						{data.imgDescription}
					</div>
				</div>
			</div>
		);
	}

	async function handleNext(e) {
		e.preventDefault();
		if (ACTIVE === 3) {
			let time = getTimer();
			dispatch(updateTime(time, 2));
			if (await caseIsActive(gameId, 1)) {
				updateStats({
					...group,
					time: time,
					lastVisited: '/pruebasclinicasc2',
				});
				navigate('/pruebasclinicasc2');
			} else {
				let timePenalty = getTimePenalty(group.time, group.score);
				dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
				updateStats({
					...group,
					score: group.score - timePenalty,
					penalty: group.penalty - timePenalty,
					time: time,
					lastVisited: '/gameover',
					finished: true,
				});
				navigate('/gameover');
			}
		} else setACTIVE(ACTIVE + 1);
	}

	return (
		<div className='h-100'>
			<div className='container-fluid h-100 px-5'>
				{renderTabs()}
				{renderContent()}
			</div>
			<ScenarioBar activeCase={2} activeScenario={2} />
			<Link
				to='/pruebasclinicasc2'
				className='btn-trap btn-next bgYellow'
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
		</div>
	);
}

export default injectIntl(Scenario2);
