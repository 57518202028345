import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../translations/i18n';
import { useDispatch, useSelector } from 'react-redux';
import timeIcon from '../assets/results/time-icon.svg';
import scoreIcon from '../assets/results/score-icon.svg';
import bonusIcon from '../assets/results/bonus-icon.svg';
import penaltyIcon from '../assets/results/penalty-icon.svg';
import profileContainer from '../assets/results/circulo-perfil.svg';
import backgroundImage from '../assets/results/fondo-resultados.svg';
import { formatScore, updateStats } from '../utils/helpers';
import { SERVER_URL } from '../api';
import { setGroup } from '../redux/actions';

function Results({ intl }) {
	const lang = useLang();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [img, setImg] = useState(null);
	const group = useSelector((state) => state.group);
	const score = group?.score || 0;
	const penalty = group?.penalty || 0;
	const bonus = group?.bonus || 0;
	const time = group?.time || 0;

	useEffect(() => {
		setImg(`${SERVER_URL}/${group?.image}`);
	}, []);

	const formatTime = () => {
		return Math.ceil(time / 60);
	};

	return (
		<div className='h-100'>
			<div
				className='container-fluid bgBlue h-100'
				onClick={() => {
					const nextUrl = group.actualCase === '1' ? '/start' : '/results';
					dispatch(
						setGroup({
							...group,
							lastVisited: nextUrl,
							actualCase: '2',
						})
					);
					updateStats({
						...group,
						lastVisited: nextUrl,
						actualCase: '2',
						conflict: true,
					});
					if (nextUrl !== '/results') navigate(nextUrl);
				}}>
				<div className='row text-center pt-5 justify-content-center'>
					<h1 className='title6'>{`Resultados caso clínico ${group.actualCase}`}</h1>
				</div>
				<div className='row h-50 text-center mt-4 justify-content-center'>
					<div className='col-8 d-flex align-items-center'>
						<div className='position-relative mt-5'>
							<div className='teamfoto'>
								<img src={img} alt='' />
							</div>
							<img src={profileContainer} alt='' />
						</div>
						<div className='px-5 w-75'>
							<div className='d-flex justify-content-between'>
								<img src={timeIcon} alt='' className='score-icon' />
								<div className='d-flex w-100 justify-content-between align-items-end'>
									<div className='bigTextB text-white ms-3'>
										Tiempo de juego
									</div>
									<div className='title6B txtYellow'>{formatTime()} min</div>
								</div>
							</div>
							<div className='separator mt-2'></div>
							<div className='d-flex justify-content-between mt-3'>
								<img src={scoreIcon} alt='' className='score-icon' />
								<div className='d-flex w-100 justify-content-between align-items-end'>
									<div className='bigTextB text-white ms-3'>
										Puntos obtenidos
									</div>
									<div className='title6B txtYellow'>
										{score - penalty - bonus}
									</div>
								</div>
							</div>
							<div className='separator mt-2'></div>
							<div className='d-flex justify-content-between mt-3'>
								<img src={bonusIcon} alt='' className='score-icon' />
								<div className='d-flex w-100 justify-content-between align-items-end'>
									<div className='bigTextB text-white ms-3'>Bonificaciones</div>
									<div className='title6B txtYellow'>{bonus}</div>
								</div>
							</div>
							<div className='separator mt-2'></div>
							<div className='d-flex justify-content-between mt-3'>
								<img src={penaltyIcon} alt='' className='score-icon' />
								<div className='d-flex w-100 justify-content-between align-items-end'>
									<div className='bigTextB text-white ms-3'>Penalizaciones</div>
									<div className='title6B txtYellow'>{penalty}</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-8 px-5 text-right mt-4'>
						<div className='title6C txtYellow'>
							Puntuación total{' '}
							<span className='ms-5'>{formatScore(score)}</span>
						</div>
					</div>
				</div>
			</div>
			<img className='background-image' src={backgroundImage} alt='' />
			<div className='lineBottom yellowBottom'></div>
		</div>
	);
}

export default injectIntl(Results);
