import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import { postAnswer } from '../../../api/answer';
import ScenarioBar from '../../../components/ScenarioBar';
import { alertError, modalImage, modalAnswer } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import testimg1 from '../../../assets/images/testimg21.png';
import testimg2 from '../../../assets/images/testimg22.png';
import testimg3 from '../../../assets/images/testimg23.png';
import testimg4 from '../../../assets/images/testimg24.png';
import fullscreenIcon from '../../../assets/fullscreen.png';
import crackIcon from '../../../assets/crack-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import arrowForth from '../../../assets/arrowForth.svg';
import arrowBack from '../../../assets/arrowBack.svg';
import remember from '../../../assets/remember-icon.svg';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '6310cb230e9a26315a833397';
const scenarioId = '62d7fd15b260ea1d73de0d37';

const defaultAnswers = {
	0: null,
	1: null,
	2: null,
	3: null,
};
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [PENALTY, setPENALTY] = useState(0);
	const [ACTIVE, setACTIVE] = useState(0);
	const [ANSWERS, setANSWERS] = useState(defaultAnswers);
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const RIGHT_ANSWERS = [0, 1, 2, 3];

	const OPTIONS = [
		'Neumonía intersticial no específica (NINE)',
		'Neumonía intersticial usual (NIU)',
		'Neumonía organizada',
		'Bronquiolitis folicular',
	];
	const IMAGES = [testimg1, testimg2, testimg3, testimg4];

	function checkAnswer() {
		counter++;
		const lastTry = counter > 3;
		let wrong = 0;
		RIGHT_ANSWERS.forEach((answer, idx) => {
			if (answer != ANSWERS[idx]) wrong++;
		});
		if (wrong) {
			setPENALTY(PENALTY + 25);
			setANSWERS(defaultAnswers);
			setACTIVE(0);
			modalAnswer({
				title: '¡Lástima!',
				type: 'wrong',
				html: `
					<div class='d-flex'>
						<img src=${pruebaInnecesariaIcon} alt='test'/>
						<span>Has fallado en 
							<span class='bold'>
								${wrong > 1 ? wrong + ' imágenes' : wrong + ' imagen'} 
							</span>
							y no has conseguido reconectarte al servidor.
							${
								!lastTry
									? `<p class='txtBlue bold'>
									<br>Inténtalo de nuevo.
								</p>`
									: `<p>
									<br>Has agotado los <span class='bold'>4 intentos</span>. Avanza para descubrir la solución.
								</p>`
							}
						</span>
					</div>
					`,
				btnText: lastTry ? 'AVANZAR' : 'INTENTAR DE NUEVO',
			}).then(() => {
				if (lastTry) submitAnswer(false);
			});
		} else {
			setIsCompleted(true);
			modalAnswer({
				title: PENALTY ? '¡Ahora sí!' : '¡Eres un crack!',
				type: 'right',
				html: `
					<div class='d-flex'>
						<img src=${PENALTY ? pruebaNecesariaIcon : crackIcon} class='${
					!PENALTY ? 'modal-crack-icon' : ''
				}' alt='test'/>
						${
							PENALTY
								? `<span class='bold'>Has superado la prueba
									<span class='normal'>
									 y has conseguido reconectarte al servidor... aunque has perdido
										<span class='bold'>
											${Math.min(PENALTY, 100)} puntos.
										</span>
										<p><br>Echa un vistazo a la solución y después podrás seguir con la historia.</p>
									</span>							
								</span>`
								: `<span class='bold'>Has superado la prueba sin penalizaciones
							<span class='normal'>
								y has conseguido reconectarte al servidor.
								<p>Echa un vistazo a la solución y después podrás seguir con la historia.</p>
							</span>							
						</span>`
						}
					</div>
					`,
				btnText: 'VER SOLUCIÓN',
			}).then(() => submitAnswer(true));
		}
	}

	async function submitAnswer(correct) {
		let penalty = correct ? (counter - 1) * 25 : counter * 25;
		const finalScore = counter ? Math.max(-Math.abs(penalty), -100) : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 2));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/scenario4solucionc2',
						});
						navigate('/scenario4solucionc2');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (!Object.keys(ANSWERS).some((idx) => ANSWERS[idx] === null))
			checkAnswer();
		else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='bold'>
							Recuerda
							<span class='normal'>
								que debes seleccionar una <br/>
								respuesta en cada imagen para poder avanzar.
							</span>
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
			});
		}
	}

	function handleInputChange(event, idx) {
		let isChecked = event.target.checked;
		setANSWERS((ANSWERS) => ({ ...ANSWERS, [ACTIVE]: isChecked ? idx : null }));
	}

	function renderImage() {
		let image = IMAGES[ACTIVE];
		return (
			<div className='active mx-2'>
				<img
					className='img-fluid m-0'
					src={image}
					alt={`Test image`}
					style={{ width: '481px', height: '339px' }}
				/>
				<img
					className='fullscreen active'
					src={fullscreenIcon}
					alt='fullscreen'
					onClick={() => modalImage({ image: image, fullscreen: true })}
				/>
				<div className='number active title9'>{ACTIVE + 1}</div>
			</div>
		);
	}

	function renderQuestion() {
		return (
			<div className='col-5 pt-5 mt-5'>
				{OPTIONS.map((option, idx) => (
					<div key={idx} className='my-2'>
						<div className='bigTextB txtBlue d-flex justify-content-start align-items-start'>
							<div>
								<input
									type='checkbox'
									checked={ANSWERS[ACTIVE] === idx}
									onChange={(event) => handleInputChange(event, idx)}></input>
							</div>
							<div className='mx-2'>{option}</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div className='h-100'>
			<div className='h-75 pt-5'>
				<div className='h-75 align-items-center justify-content-center px-5 py-0 d-flex bgGrey mt-5 pt-5'>
					{renderQuestion()}
					<img
						src={arrowBack}
						alt='arrow-back'
						onClick={() => {
							if (ACTIVE > 0) setACTIVE(ACTIVE - 1);
						}}
					/>
					{renderImage()}
					<img
						src={arrowForth}
						alt='arrow-forth'
						onClick={() => {
							if (ACTIVE < 3) setACTIVE(ACTIVE + 1);
						}}
					/>
				</div>
			</div>
			{ACTIVE === 3 && (
				<Link
					to='/scenario4solucionc2'
					className='bgYellow btn-trap btn-next'
					onClick={handleNext}>
					CONFIRMAR
				</Link>
			)}

			<ScenarioBar activeCase={2} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Test);
