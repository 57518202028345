import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../translations/i18n';
import ScenarioBar from '../../components/ScenarioBar';
import carpeta from '../../assets/carpeta.svg';
import NextButton from '../../components/NextButton';

function AveriguaC2({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-75'>
					<div className='col-10 pt-5 offset-1 d-flex align-items-center justify-content-center'>
						<div className='mb-5'>
							<div className='bigExplainsB px-5 pt-4 pb-5 txtGrey'>
								<p>
									Ahora podrás hablar con tu paciente, pero ¿qué tal si antes
									echas un vistazo a lo más destacado de su
									<span className='bold'> historia clínica?</span>
								</p>
							</div>
							<div className='d-flex align-items-center recuadro1 py-4 w-75 mx-auto'>
								<img className='px-4' src={carpeta} alt='Carpeta' />
								<div className='bigExplainsB'>
									Abre la carpeta de materiales y consulta la historia clínica
									del paciente.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/hablamanoloc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={0} />
		</div>
	);
}

export default injectIntl(AveriguaC2);
