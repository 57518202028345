import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import { alertError, modalDiagnostic } from '../../../utils/logger';
import paperIcon from '../../../assets/diagnostic-paper-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '62d57f852061eb5d07300abd';
const scenarioId = '62d57f852061eb5d07300abb';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);

	const [PENALTY, setPENALTY] = useState(0);

	const RIGHT_ANSWER = 2;
	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Infiltración con trigón en ambos hombros.',
		'Bolos de metilprednisolona seguidos de prednisona 60 mg/día, pauta descendente.',
		'Prednisona 15 mg/día + metotrexato 10-15 mg/semanal + acfol/semanal.',
		'Colchicina 1 mg/día.',
		'Naproxeno 1/12 horas + omeprazol 1/24 horas.',
		'Prednisona 15 mg/día + tocilizumab s. c. .',
		'Cloxacilina 2 g/24 horas + ceftriaxona 2 g/24 horas.',
		'Alopurinol 300 mg/día.',
	];

	const FEEDBACKS = [
		`No controlaría la inflamación ni en el carpo ni sistémica`,
		`Este sería un buen tratamiento si Pilar tuviese arteritis de células gigantes`,
		`Según las distintas guías de práctica clínica, debe iniciarse un FAME de forma precoz. En la mayoría de las situaciones, metotrexato es el FAME recomendado`,
		`La colchicina se usa principalmente en artritis microcristalina`,
		`Sería insuficiente para controlar el proceso inflamatorio sistémico`,
		`No hay signos de vasculitis actual ni refractariedad como para iniciar tratamiento con tocilizumab`,
		`No hay signos de artritis séptica`,
		`El alopurinol se utiliza como tratamiento reductor de la uricemia en la artritis por depósito de cristales de ácido úrico o gota`,
	];

	const formatFeedback = (feedback, right) => {
		return `
			<div class='modal-treatment-item'>
				<img src=${paperIcon} alt='test' class='align-self-start'/>
				<p>
					${feedback}<span class='superindex'>4</span>.
				</p>
			</div>
			<div class='modal-treatment-item' style='margin-top:1em'>
				${
					!right
						? `<img src=${pruebaInnecesariaIcon} alt='test'/>
						<span class='modal-diagnostic-title txtRed' >
							Este tratamiento no es adecuado.<br>Has perdido -25 puntos.
						</span>
						`
						: `
					<img src=${pruebaNecesariaIcon} alt='test' class='align-self-start'/>
					<div class='d-flex flex-column align-items-center'>
						<span class='modal-diagnostic-title txtGreen' >
							¡Muy bien!<br>¡Este es el tratamiento adecuado!
							${
								PENALTY
									? `<br><span class='normal'>
											Pero no lo has acertado a la primera, por lo que
											<span class='bold'>
												no obtienes los 100 puntos extra.
											</span>			
										</span>`
									: ''
							}	
						</span>
						${
							!PENALTY
								? `<span class='modal-points' style='margin:15px 45px 0 0'>
										+100 puntos
									</span>`
								: ''
						}
					</div>`
				}
			</div>
		`;
	};

	function checkAnswer(index) {
		if (RIGHT_ANSWER === index) {
			return 'right';
		}
		setPENALTY(PENALTY + 25);
		return 'wrong';
	}

	function answerHandler(index, feedback) {
		let res = checkAnswer(index - 1);
		modalDiagnostic({
			html: feedback,
			btnText: res === 'right' ? 'AVANZAR' : 'INTENTAR DE NUEVO',
			containerClass: 'modal-treatment-container',
		}).then((result) => {
			if (res === 'right') {
				if (result.isConfirmed) {
					submitAnswer();
				} else setIsCompleted(true);
			}
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -175) : 100;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 1));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/ganamaspuntosc1',
						});
						navigate('/ganamaspuntosc1');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={formatFeedback(FEEDBACKS[0])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={formatFeedback(FEEDBACKS[4])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={formatFeedback(FEEDBACKS[1])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={formatFeedback(FEEDBACKS[5])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={formatFeedback(FEEDBACKS[2], true)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={7}
							text={QUESTIONS[6]}
							feedback={formatFeedback(FEEDBACKS[6])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={formatFeedback(FEEDBACKS[3])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={8}
							text={QUESTIONS[7]}
							feedback={formatFeedback(FEEDBACKS[7])}
							showConfirm
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/ganamaspuntosc1'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={7} />
		</div>
	);
}

export default injectIntl(Test);
