import './App.css';
import './customStyles/modals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Outlet } from 'react-router-dom';

function App() {
	return (
		<div className='App'>
			<Outlet />
		</div>
	);
}
export default App;
