import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import portada from '../../../assets/escenario-2-portada-libro.png';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-75'>
					<div className='col-10 mb-5 offset-1 d-flex align-items-center justify-content-center pb-5'>
						<div className='mb-5'>
							<div className='bigTextB px-5 pt-4 pb-5 txtGrey'>
								Navegando por la web de una sociedad científica de reumatología,
								encuentras un documento descargable con esta portada:
							</div>
							<div className='d-flex justify-content-center'>
								<img className='px-4' src={portada} alt='Portada' />
							</div>
							<div className='bigTextB px-5 pt-4 pb-5 txtGrey'>
								¡Qué oportuno! Decides echar un vistazo…
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/unfolleto2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={2} expanded />
		</div>
	);
}

export default injectIntl(Intro);
