import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../translations/i18n';
import ScenarioBar from '../components/ScenarioBar';
import trophy from '../assets/trophy-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateStats } from '../utils/helpers';
import { setGroup } from '../redux/actions';

function End({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);

	useEffect(() => {
		dispatch(
			setGroup({
				...group,
				lastVisited: '/end',
				finished: group.actualCase === '2',
				finishedCase1: true,
			})
		);
	}, []);

	return (
		<div className='h-100'>
			<div
				className='container-fluid endbg h-100'
				onClick={() => {
					updateStats({
						...group,
						lastVisited: '/results',
					});
					navigate('/results');
				}}>
				<div className='row h-100 justify-content-center'>
					<div className='d-flex align-items-center justify-content-center pb-5 mt-5'>
						<div className='text-center mt-3'>
							<div className='titleEnd'>¡Enhorabuena!</div>
							<div className='bigTextB py-4 text-center text-white'>
								<p className='bold txtWhite'>
									Has resuelto el caso. Muchas gracias por participar.
									<br />
									<span className='normal'>
										Y ahora, ¿quieres conocer tu puntuación final?
									</span>
								</p>
							</div>
							<img className='trophy-icon' src={trophy} alt='Trofeo' />
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeCase={group.actualCase} activeScenario={8} />
		</div>
	);
}

export default injectIntl(End);
