import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../translations/i18n';
import NextButton from '../../components/NextButton';

function StartC1({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-5'>
									<div className='title2 bold'>CASO 1</div>
									<div className='title2 normal'>Me levanto como un robot</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp'>
						<div className='container'>
							<div className='row'>
								<div className='col-6'>
									<div className='title3 py-3'>Dr. Enigma</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='container'>
							<div className='row'>
								<div className='col-7 pt-3 pb-5  bigTextC'>
									Servicio de Investigación. Departamento de medicina,
									Universitat Autònoma.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton
				navigateTo={'/instruccionesc1'}
				activeCase={1}
				text={'¡EMPEZAR!'}
			/>
		</div>
	);
}

export default injectIntl(StartC1);
