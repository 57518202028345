import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import pruebasIcon from '../../../assets/prueba-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={pruebasIcon} alt='Pruebas' />
										<div className='title-scenario mx-2 text-left'>
											¿Qué pruebas clínicas
											<br />
											le harías a Pilar?
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='container'>
							<div className='row justify-content-center'>
								<div className='col-11 py-5 bigText normal'>
									<p>
										Elige las que consideres oportunas, pero cuidado:{' '}
										<span className='title1'>
											{'cada prueba innecesaria se penaliza con '}
											<span className='destacadoRojo title4'>–25 puntos</span>
										</span>
									</p>
									<p>
										Una pista: con la información de la que dispones en estos
										momentos, hay <span className='title1'>2 pruebas</span> que
										pueden considerarse
										<span className='title1'> imprescindibles</span>. Cuando las
										hayas seleccionado, podrás avanzar en el caso.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario3testc1'} activeCase={1} />
			<ScenarioBar activeCase={1} activeScenario={3} expanded />
		</div>
	);
}

export default injectIntl(Intro);
