import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../translations/i18n';
import ScenarioBar from '../components/ScenarioBar';
import { clearItem, getTimePenalty, updateStats } from '../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setGroup, updateScore } from '../redux/actions';

function Gameover({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);

	useEffect(() => {
		clearItem('total');
		dispatch(
			setGroup({
				...group,
				lastVisited: '/end',
				finished: group.actualCase === '2',
				finishedCase1: true,
			})
		);
	}, []);

	return (
		<div className='h-100'>
			<div
				className='container-fluid bggameover h-100'
				onClick={() => {
					updateStats({
						...group,
						lastVisited: '/results',
					});
					navigate('/results');
				}}>
				<div className='row h-100 justify-content-center'>
					<div className='d-flex align-items-center justify-content-center pb-5 mt-5'>
						<div className='text-center mt-3'>
							<div className='title8B pt-5 mt-5 text-center text-white'>
								<p className='bold txtWhite'>
									Lo sentimos, el tiempo de juego ha finalizado.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar activeCase={group.actualCase} activeScenario={8} />
		</div>
	);
}

export default injectIntl(Gameover);
