import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../redux/actions';

function NextButton({ navigateTo, activeCase, text, classes }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	async function handleNext(e) {
		e.preventDefault();
		let time = getTimer();
		dispatch(updateTime(time, activeCase));
		updateStats({
			...group,
			time: time,
			lastVisited: navigateTo,
		});
		if (await caseIsActive(gameId, activeCase - 1)) {
			updateStats({
				...group,
				time: time,
				lastVisited: navigateTo,
			});
			navigate(navigateTo);
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: time,
				lastVisited: '/gameover',
				finished: group.actualCase === '2',
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	return (
		<Link
			to={navigateTo}
			onClick={handleNext}
			className={`bgYellow btn-trap btn-next ${classes} `}>
			{text || 'AVANZAR'}
		</Link>
	);
}
export default NextButton;
