import Swal from 'sweetalert2';
import audioDescriptionIcon from '../assets/audio-description-icon.svg';
import confirmationIcon from '../assets/confirmation-icon.svg';
import clapIcon from '../assets/clap-icon.svg';
import { formatClinicCaseName } from './helpers';

export const logError = ({
	error,
	customMessage = 'No custom message from backoffice',
}) => {
	error?.response?.status
		? console.log(
				'ERROR\n' +
					`Status: ${error.response.status}.\n` +
					`Status error: ${error.response.statusText}.\n` +
					`API Message: ${error.response.data?.message}\n` +
					`Backoffice Message: ${customMessage}`
		  )
		: console.log(
				'ERROR\n' + `Backoffice message: ${customMessage}\n` + `${error}`
		  );
};

export const logFormData = (formData) => {
	console.group('FormData');
	for (let pair of formData.entries())
		console.log('key:', pair[0] + ', value: ' + pair[1]);

	console.groupEnd('FormData');
};

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		'Error has no message';

	const params = {
		icon: 'error',
		title: (customMessage?.length < 55 && customMessage) || 'Ooops...',
		text: message,
	};

	Swal.fire(params);
};

export const alertSuccess = ({ customMessage } = {}) => {
	console.log('alertSuccess called');
	const message = customMessage || 'Successfull operation!';

	const params = {
		icon: 'success',
		title: 'Ok!',
		text: message,
	};

	Swal.fire(params);
};

export const alertResume = ({} = {}) => {
	const params = {
		icon: 'question',
		title: '¿Quieres seguir con la partida predeterminada?',
		confirmButtonText: 'Sí, continuar partida',
		cancelButtonText: 'No, jugar la de pruebas',
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			popup: 'modal-audio-container',
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgGrey modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const alertGroup = ({ data } = {}) => {
	const html = `
	<div class='text-start'>
		<div class='bold'>Nombre: <span class='regular'>${
			data.group.fullName
		}</span></div>
		<div class='bold'>Mesa: <span class='regular'>${
			data.group.tableNum
		}</span></div>
		<div class='bold'>Participantes:<br/> ${data.members
			.map((member) => {
				return `<span class='ps-3 my-1 regular'>${member.fullName}<br/></span>
		`;
			})
			.join('')}</div>
	</div>`;
	const params = {
		icon: 'question',
		html: html,
		title: '¿Es este tu grupo?',
		confirmButtonText: 'Sí, continuar partida',
		cancelButtonText: 'No, escoger otro',
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgGrey modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const alertCaso = ({ caso, completed } = {}) => {
	const message = completed
		? `¡Ya has finalizado el ${caso.fullName}!`
		: caso.status === 'not-played'
		? `¡Aún no has terminado el ${formatClinicCaseName(caso.fullName)}!`
		: caso.status === 'not-started'
		? `¡El ${formatClinicCaseName(caso.fullName)} aún no ha empezado!`
		: `¡El ${formatClinicCaseName(caso.fullName)} ya ha terminado!`;

	const params = {
		icon: 'warning',
		title: message,
		confirmButtonText: 'Cerrar',
		customClass: {
			confirmButton: 'bgYellow modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalText = ({ title, html } = {}) => {
	const params = {
		title: title || '',
		html: html || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-text-container',
			htmlContainer: 'modal-text-html',
			title: 'modal-text-title',
			closeButton: 'modal-btn-close',
		},
	};
	Swal.fire(params);
};

export const modalAudio = ({ title, html } = {}) => {
	let icon = `<img src=${audioDescriptionIcon} alt='test' class="modal-audio-icon"/>`;
	const params = {
		title: title || '',
		html: icon.concat(html) || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-audio-container',
			htmlContainer: 'modal-audio-html',
			title: 'modal-audio-title',
			closeButton: 'modal-btn-close',
		},
	};
	return Swal.fire(params);
};

export const modalQuizCompleted = ({
	title,
	html,
	confirmText,
	cancelText,
} = {}) => {
	let icon = `<img src=${clapIcon} alt='test' class="modal-quizCompleted-icon"/>`;
	const params = {
		title: title,
		html: icon.concat(html) || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-quizCompleted-container',
			title: 'modal-quizCompleted-title',
			htmlContainer: 'modal-quizCompleted-html',
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgYellow modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalTest = ({ type, title, html, extraBtnText } = {}) => {
	const params = {
		title: title,
		html: html || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: 'CERRAR',
		showCancelButton: extraBtnText,
		cancelButtonText: extraBtnText,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-test-container',
			title: `modal-test-title modal-test-title-${type} d-flex align-items-center justify-content-center`,
			htmlContainer: 'modal-test-html',
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgGrey modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalDiagnostic = ({
	html,
	btnText,
	containerClass,
	disableEscape,
} = {}) => {
	const params = {
		html: html || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		confirmButtonText: btnText,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: `${containerClass}`,
			htmlContainer: 'modal-diagnostic-html',
			confirmButton: 'bgYellow modal-btn-trap',
		},
	};
	if (disableEscape) {
		params.allowEscapeKey = false;
		params.allowOutsideClick = false;
	}
	return Swal.fire(params);
};

export const modalImage = ({
	image,
	fullscreen,
	paddingTop,
	containerClass,
} = {}) => {
	const params = {
		imageUrl: image,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: containerClass || 'modal-image-container',
			closeButton: 'modal-btn-close',
			image: `modal-image-image${fullscreen ? 'fullscreen' : ''} ${paddingTop}`,
		},
	};
	return Swal.fire(params);
};

export const modalAnswer = ({
	title,
	type,
	html,
	btnText,
	containerClass,
} = {}) => {
	const params = {
		title: title,
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		confirmButtonText: btnText || 'AVANZAR',
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: containerClass || 'modal-answer-container',
			title: `modal-answer-title modal-${type}`,
			htmlContainer: 'modal-answer-html',
			confirmButton: 'bgYellow modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalConfirm = ({ text, titleClass } = {}) => {
	let icon = `<img src=${confirmationIcon} alt='test' class="modal-confirm-icon"/>`;
	const params = {
		title: text || '¿Estás seguro de tu elección?',
		html: icon || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: 'ACEPTAR',
		cancelButtonText: 'CANCELAR',
		showCancelButton: true,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-confirm-container',
			title: titleClass || 'modal-confirm-title',
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgGrey modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalConfirmTest = ({ html }) => {
	let icon = `<img src=${confirmationIcon} alt='test' class="modal-confirm-icon"/>`;
	const params = {
		title: '¿Seguro que quieres solicitar esta prueba?',
		html: html.concat(icon) || '',
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: 'ACEPTAR',
		cancelButtonText: 'CANCELAR',
		showCancelButton: true,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-confirm-container',
			title: 'modal-confirm-title pt-5 mt-4',
			confirmButton: 'bgYellow modal-btn-trap',
			cancelButton: 'bgGrey modal-btn-trap',
		},
	};
	return Swal.fire(params);
};

export const modalPDF = ({ pdf, title } = {}) => {
	let html = `<iframe
			id='pdfviewer'
			src=${pdf}
			frameborder='0'
			width='90%'
			height='550px'
			type='application/pdf'
			></iframe>`;
	const params = {
		title: title || 'FICHA TÉCNICA',
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: 'rgba(36,40,79, .85)',
		customClass: {
			popup: 'modal-pdf-container',
			title: 'modal-text-title',
			closeButton: 'modal-btn-close',
		},
	};
	return Swal.fire(params);
};
