import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import backgroundImage from '../../../assets/fondo-robot.svg';
import checkedCapcha from '../../../assets/images/checkedCapcha.png';
import uncheckedCapcha from '../../../assets/images/uncheckedCapcha.png';
import { useSelector, useDispatch } from 'react-redux';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import { updateScore, updateTime } from '../../../redux/actions';

function Intro({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;

	const [isChecked, setIsChecked] = useState(false);

	async function handleNavigate(e) {
		e.preventDefault();
		let time = getTimer();
		dispatch(updateTime(time, 2));
		if (await caseIsActive(gameId, 1)) {
			if (isChecked) {
				updateStats({
					...group,
					time: time,
					lastVisited: '/noeresunrobot3',
				});
				navigate('/noeresunrobot3');
			}
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: time,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75'>
					<div className='col-10 pt-5 offset-1 d-flex align-items-center justify-content-center'>
						<div className='mb-5 px-5 w-100 d-flex flex-column'>
							<div className='title-scenarioB mb-5'>Marca la casilla:</div>
							<div
								className='py-4 mx-auto'
								onClick={() => {
									setIsChecked(!isChecked);
								}}>
								<img
									className='img-fluid'
									style={{ width: '480px' }}
									src={isChecked ? checkedCapcha : uncheckedCapcha}
									alt='capcha'
								/>
							</div>
							<div
								className='bigExplains mt-3 text-center'
								style={{ height: '15px' }}>
								{isChecked ? 'Tienes que demostrarlo.' : ' '}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Link
				to='/noeresunrobot3'
				className='bgYellow btn-trap btn-next'
				onClick={handleNavigate}>
				{'AVANZAR'}
			</Link>
			<img
				className='relojControla'
				style={{ bottom: '0px' }}
				src={backgroundImage}
				alt='Controla tu tiempo'
			/>
			<ScenarioBar activeCase={2} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Intro);
