import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../translations/i18n';
import ScenarioBar from '../../components/ScenarioBar';
import carpeta from '../../assets/carpeta.svg';
import NextButton from '../../components/NextButton';

function AveriguaC1({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid h-100'>
				<div className='row h-100'>
					<div className='col-10 mb-5 offset-1 d-flex align-items-center justify-content-center pb-5'>
						<div className='mb-5'>
							<h2 className='txtBlue text-center title-scenario'>
								Averigua qué le pasa a Pilar...
							</h2>
							<div className='bigExplains px-5 pt-4 pb-5'>
								<p>
									Ahora podrás hablar con tu paciente, pero ¿qué tal si antes
									echas un vistazo a lo más destacado de su historia clínica?
								</p>
							</div>
							<div className='d-flex align-items-center recuadro1 py-4 w-75 mx-auto'>
								<img className='px-4' src={carpeta} alt='Carpeta' />
								<div className='bigExplains'>
									Abre la carpeta de materiales y consulta la historia clínica
									de la paciente.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/hablapilarc1'} activeCase={1} />
			<ScenarioBar activeCase={1} activeScenario={0} />
		</div>
	);
}

export default injectIntl(AveriguaC1);
