import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import { alertError, modalDiagnostic } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import paperIcon from '../../../assets/diagnostic-paper-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '6310cb5b0e9a26315a833399';
const scenarioId = '62d7fd23b260ea1d73de0d39';

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const [PENALTY, setPENALTY] = useState(0);
	const RIGHT_ANSWER = 4;

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Insuficiencia cardiaca.',
		'Exacerbación de EPOC.',
		'Toxicidad pulmonar por amiodarona.',
		'Toxicidad pulmonar por metotrexato.',
		'EPID-AR.',
		'Nódulos reumatoideos.',
	];

	const FEEDBACKS = [
		'Manolo no presenta hallazgos compatibles, excepto la disnea. Las pruebas complementarias realizadas (ecocardiograma) no van a favor de este diagnóstico. Además, el paciente está cardiológicamente compensado con la nueva medicación.',
		'La disnea que presenta Manolo es progresiva, sin expectoración ni exacerbaciones previas. El antecedente de fumador explicaría la presencia de EPOC, pero no una exacerbación.',
		'Manolo presenta el antecedente epidemiológico, pero la clínica es anterior a la toma de amiodarona. El TCAR mostraría un patrón inflamatorio tipo organización, y no el de NIU presente.',
		'Manolo estaba tratado con metotrexato desde hacía años, por lo que es muy improbable que sea la causa de toxicidad pulmonar o de EPID; el riesgo de EPID por metotrexato es el de una neumonitis aguda. El patrón de NIU tampoco va a favor. Además, se ha demostrado que no existe asociación entre la toma de metotrexato y la aparición de EPID. Incluso parece que el metotrexato podría proteger de su aparición. ',
		'Manolo es un paciente con factores de riesgo para desarollar EPID-AR. Tiene mal control de la enfermedad, es fumador, ACPA, FR y sexo varón. Desarrolla la clínica compatible y presenta pruebas complementarias (ausculatación y TCAR) sugestivas de este diagnóstico',
		'Si bien en ocasiones son sintomáticos, con disnea, y en pacientes con mal control de la enfermedad, la TCAR efectuada a Manolo descarta su presencia. ',
	];

	const formatFeedback = (index) => {
		return `
		<div class='d-flex'>
			<img src=${paperIcon} alt='test' class='align-self-start'/>
			<span>
				${FEEDBACKS[index]}${
			RIGHT_ANSWER === index ? `<span class='superindex'>1</span>.` : ''
		}
			</span>
		</div>
		<p></p>
		<div class='d-flex justify-content-center'>
		
			${
				RIGHT_ANSWER === index
					? `
					 <img src=${pruebaNecesariaIcon} alt='test'/>
					<div class='d-flex flex-column justify-content-center align-items-center'>
						<span class='modal-diagnostic-title txtGreen' >
							¡Diagnóstico correcto!<br>
							Ahora, a por el tratamiento...<br><br>
						</span>
						${
							!PENALTY
								? `<span class='modal-points me-5'>
									+100 puntos
									</span>`
								: ''
						}
						
					</div>`
					: `
					<img src=${pruebaInnecesariaIcon} alt='test'/>
					<span class='modal-diagnostic-title txtRed' >
						Este diagnóstico no es correcto. <br>	
						Has perdido -25 puntos.
					</span>	
			`
			}
		</div>
		`;
	};

	function checkAnswer(index) {
		if (RIGHT_ANSWER === index) {
			setIsCompleted(true);
			return 'right';
		} else {
			setPENALTY(PENALTY + 25);
			return 'wrong';
		}
	}

	function answerHandler(index, feedback) {
		let res = checkAnswer(index - 1);
		modalDiagnostic({
			html: feedback,
			containerClass: 'modal-diagnostic-container',
			btnText: res === 'right' ? 'AVANZAR' : 'INTENTAR DE NUEVO',
		}).then((result) => {
			if (res === 'right' && result.isConfirmed) submitAnswer();
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -125) : 100;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 2));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/tratamientoc2',
						});
						navigate('/tratamientoc2');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={formatFeedback(0)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={formatFeedback(3)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={formatFeedback(1)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={formatFeedback(4)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={formatFeedback(2)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={formatFeedback(5)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/tratamientoc2'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={2} activeScenario={6} />
		</div>
	);
}

export default injectIntl(Test);
