import React, { useEffect, useState } from 'react';
import logo from '../assets/logo MTX_NEG.svg';
import star from '../assets/top/star.svg';
import {
	getTimePenalty,
	getTimer,
	updateStats,
	updateTimer,
} from '../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from '../hooks/useTimer';
import {
	formatScoreTopBar,
	formatMinutes,
	formatSeconds,
} from '../utils/helpers';
import { modalText } from '../utils/logger';
import { useNavigate } from 'react-router-dom';
import { updateScore, updateTime } from '../redux/actions';

export default function TopBar() {
	const timeout = 2400;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const score = useSelector((state) => state.group?.score);
	const [time, setTime] = useState(group?.time);
	let isACTIVE = false;

	useTimer(() => {
		if (group?.actualCase === '2' && !group?.finished) {
			setTime(group?.timeCase2);
		}
		if (!isACTIVE && getTimer()) isACTIVE = true;
		if (isACTIVE) handleTimer();
	}, 1000);

	const handleTimer = () => {
		if (!getTimer()) isACTIVE = false;
		else {
			setTime(time + 1);
			updateTimer(time);
		}
	};

	useEffect(() => {
		if (time === 3000 && group.lastVisited !== '/gameover') {
			if (
				!group.finishedCase1 ||
				(!group.finished && group.finishedCase1 && group.actualCase === '2')
			) {
				let timePenalty = getTimePenalty(group.time, group.score);
				dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
				dispatch(updateTime(3000, group.actualCase));
				updateStats({
					...group,
					score: group.score - timePenalty,
					penalty: group.penalty - timePenalty,
					time: 3000,
					lastVisited: '/gameover',
					finished: group.actualCase === '2',
					finishedCase1: true,
				});
				navigate('/gameover');
			}
		}
	}, [time]);

	return (
		<div className='pt-4'>
			<div className='d-flex justify-content-between legalGebro px-4 pb-3'>
				<div>
					<img src={logo} alt='Clinicalxperience' className='img-fluid w-100' />
				</div>
				<div className='d-flex justify-content-between'>
					{!isACTIVE && (
						<div className='container-score mx-2'>
							<img src={star} height={'16px'} width={'18px'} alt='star' />
							{formatScoreTopBar(score)}
						</div>
					)}
					<div
						className='roundBtn mx-2'
						onClick={() => {
							modalText({
								title: 'REFERENCIAS BIBLIOGRÁFICAS',
								html: `
								<p>
									<p style="color:#24284f; text-transform: uppercase; font-family: 'Source Sans Pro Bold';">
										Caso 1
									</p>
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>1</span>El-Labban AS, Omar HASA, El-Shereif RR, Ali F, El-Mansoury TM. Pattern of Young and Old Onset Rheumatoid Arthritis (YORA and EORA) Among a Group  of Egyptian Patients with Rheumatoid Arthritis. Clin Med Insights Arthritis Musculoskelet Disord. 2010;3:25-31. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>2</span>Busse K, Liao W. Which Psoriasis Patients Develop Psoriatic Arthritis? Psoriasis forum. 2010;16(4):17-25. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>3</span>Cipolletta E, Filippou G, Scirè CA, Di Matteo A, Di Battista J, Salaffi F, et al. The diagnostic value of conventional radiography and musculoskeletal  ultrasonography in calcium pyrophosphate deposition disease: a systematic literature review and meta-analysis. Osteoarthr Cartil. 2021;29(5):619-32. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>4</span>Nam JL, Takase-Minegishi K, Ramiro S, Chatzidionysiou K, Smolen JS, van der Heijde D, et al. Efficacy of biological disease-modifying antirheumatic drugs: a systematic  literature review informing the 2016 update of the EULAR recommendations for the management of rheumatoid arthritis. Ann Rheum Dis. 2017;76(6):1113-36. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>5</span>Kim K, Bang S-Y, Lee H-S, Bae S-C. Update on the genetic architecture of rheumatoid arthritis. Nat Rev Rheumatol. 2017;13(1):13-24. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>6</span>Spinel-Bejarano N, Quintana G, Heredia R, Yunis JJ, Caminos JE, Garcés MF, et al. Comparative study of elderly-onset rheumatoid arthritis and young-onset  rheumatoid arthritis in a Colombian population: clinical, laboratory and HLA-DRB1 findings. Clin Exp Rheumatol. 2013;31(1):40-6. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>7</span>Kermani TA, Schmidt J, Crowson CS, Ytterberg SR, Hunder GG, Matteson EL, et al. Utility of erythrocyte sedimentation rate and C-reactive protein for the  diagnosis of giant cell arteritis. Semin Arthritis Rheum. 2012;41(6):866-71. 
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>8</span>Dasgupta B, Cimmino MA, Maradit-Kremers H, Schmidt WA, Schirmer M, Salvarani C, et al. 2012 provisional classification criteria for polymyalgia rheumatica: a European  League Against Rheumatism/American College of Rheumatology collaborative initiative. Ann Rheum Dis. 2012;71(4):484-92.
								</p>
								<p>
									<p class='pt-4' style="color:#24284f; text-transform: uppercase; font-family: 'Source Sans Pro Bold';">
										Caso 2
									</p>
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>1</span>Narváez J, Aburto M, Seoane-Mato D, Bonilla G, Acosta O, Candelas G, et al.  Screening criteria for interstitial lung disease associated to rheumatoid arthritis: Expert proposal based on Delphi methodology. Reumatol Clin (Engl Ed). 2022 Jun 23:S2173-5743(22)00095-8 [Epub ahead of print].
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>2</span>Narváez J, Díaz del Campo Fontecha P, Brito García N, Bonilla G, Aburto M, Castellví I, et al. Recomendaciones SER-SEPAR para el manejo de la enfermedad pulmonar intersticial difusa asociada a la artritis reumatoide. Parte 2: tratamiento. Reumatol Clin. 2022. Disponible en: 
									<span class='text-decoration-underline pe-1'>https://www.sciencedirect.com/science/article/pii/S1699258X22001140?via%3Dihub</span>[última consulta: 1 de agosto de 2022].
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>3</span>Juge PA, Lee JS, Lau J, Kawano-Dourado L, Rojas Serrano J, Sebastiani M, et al. Methotrexate and rheumatoid arthritis associated interstitial lung disease. Eur Respir J. 2021;57(2):2000337.
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>4</span>Conway R, Low C, Coughlan RJ, O'Donnell MJ, Carey JJ. Methotrexate and lung disease in rheumatoid arthritis: a meta-analysis of randomized controlled trials. Arthritis Rheumatol. 2014;66(4):803-12.
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>5</span>Juge PA, Lee JS, Ebstein E, Furukawa H, Dobrinskikh E, Gazal S, et al. MUC5B promoter variant and rheumatoid arthritis with interstitial lung disease. N Engl J Med. 2018;379:2209-19.
								</p>
								<p>
									<span class='smallTextB align-text-top pe-2'>6</span>Juge PA, Borie R, Kannengiesser C, Gazal S, Revy P, Wemeau-Stervinou L, et al. Shared genetic predisposition in rheumatoid arthritis-interstitial lung disease and familial pulmonary fibrosis. Eur Respir J. 2017;49:1602-L2314.',
								</p>`,
							});
						}}>
						B
					</div>
					<div className='mx-2'></div>
					<div className='timer title1'>
						{formatMinutes(time, timeout, group?.finished)}
					</div>
					<div className='title1 px-1 pt-1 blink'>:</div>
					<div className='timer title1'>
						{formatSeconds(time, timeout, group?.finished)}
					</div>
				</div>
			</div>
		</div>
	);
}
