import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import {
	alertError,
	modalAnswer,
	modalQuizCompleted,
	modalTest,
} from '../../../utils/logger';
import { useSelector } from 'react-redux';
import checklistIcon from '../../../assets/checklist-icon.svg';
import dialogIcon from '../../../assets/dialog-icon.svg';
import remember from '../../../assets/remember-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import pruebaAdecuadaIcon from '../../../assets/prueba-adecuada-icon.svg';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '6310cafa0e9a26315a833395';
const scenarioId = '62d7fd0db260ea1d73de0d36';
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const [PENALTY, setPENALTY] = useState(0);
	const RIGHT_ANSWERS = [0, 5];
	const [ANSWERS, setANSWERS] = useState([]);
	const PENALTY_ANSWERS = [2, 4];

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Auscultación cardiopulmonar',
		'Pruebas de función pulmonar',
		'Ecografía de carpos',
		'Ecografía transtorácica',
		'Ecografía pulmonar',
		'TCAR',
	];

	function checkAnswer(index) {
		if (RIGHT_ANSWERS.includes(index)) {
			if (!ANSWERS.includes(index)) {
				counter++;
				setANSWERS([...ANSWERS, index]);
			}
			return 'right';
		} else if (PENALTY_ANSWERS.includes(index)) {
			setPENALTY(PENALTY + 25);
			return 'wrong';
		}
		return 'acceptable';
	}

	function answerHandler(index, feedback, extraBtn) {
		let res = checkAnswer(index - 1);
		modalTest({
			type: res,
			title: `<img src=${
				res === 'right'
					? pruebaNecesariaIcon
					: res === 'wrong'
					? pruebaInnecesariaIcon
					: pruebaAdecuadaIcon
			} alt='test' class="modal-test-icon"/>${
				res === 'right'
					? 'Prueba imprescindible'
					: res === 'wrong'
					? 'Prueba innecesaria'
					: `Prueba adecuada <br/> (aunque no imprescindible)`
			}`,
			html: feedback,
			extraBtnText: extraBtn,
		}).then(() => {
			if ((counter === 2) & !isCompleted)
				modalQuizCompleted({
					title: '¡Ya has seleccionado las 2 pruebas clave!',
					html: `<p>Pero aún puede haber otras pruebas que aporten información útil.</p>
				<p>¿Quieres seguir haciendo pruebas o consideras que ya tienes la información que necesitas?</p>`,
					confirmText: 'HE ACABADO AQUÍ',
					cancelText: 'HAGO MÁS PRUEBAS',
				}).then((res) => {
					if (res.isConfirmed) {
						submitAnswer();
					} else setIsCompleted(true);
				});
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -50) : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 2));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/noeresunrobot',
						});
						navigate('/noeresunrobot');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		} else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='normal'>
							Aún no has solicitado las
							<span class='bold'>
								2 pruebas fundamentales.
							</span>
							<br><br>¡Pide alguna prueba más!
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
				containerClass: 'modal-answer-containerB',
			});
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={`
								<div class='d-flex justify-content-center'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Crepitantes tipo velcro en 2/3 de ambos pulmones.
									</span>
								</div>
								<p></p>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>1. Auscultación cardiopulmonar<br></span>
										Auscultación de los campos pulmonares del paciente.
									</span>
								</div>
							`}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={`
								<div class='d-flex justify-content-center'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										FEVI 65 % sin derrames ni alteraciones valvulares. Sin discinesias en paredes. Sin signos de hipertensión pulmonar.
									</span>
								</div>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>4. Ecografía transtorácica<br></span>
										Ecografía cardiaca.
									</span>
								</div>
				
								
							`}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={`
								<div class='d-flex justify-content-center txtGrey'>
									<img src=${checklistIcon} alt='test'/>
									<div>
									<p>
										FVC: 64 % <span class='ps-5'>DLCO: 46 % <span class='ps-5'>MEF: 87 %</span></span><br>
									</p>
									FEV1: 62 %
									 <span class='ps-5'>KCO: 48 %</span>
									</div>
								</div>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>2. Pruebas de función pulmonar<br></span>
										Realización de estudio espirométrico de la función pulmonar por parte de un laboratorio.
									</span>
								</div>
							`}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={`
								<div class='d-flex justify-content-center'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Presencia de líneas B en varios territorios, así como de áreas hipoecogénicas.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Quizás en un futuro sea adecuada, pero en este momento del caso, con las características actuales, no se precisa.
									</span>
								</div>
								<p></p>
								<span class='modal-test-title txtRed' style="margin-left: 25%">
									Has perdido -25 puntos.
								</span>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>5. Ecografía pulmonar<br></span>
										Estudio ecográfico de diferentes espacios intercostales para valorar el pulmón.
									</span>
								</div>
							`}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={`
								<div class='d-flex justify-content-center'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Sinovitis de grado 3 con <span class='italics'>power doppler</span> en ambos carpos.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										El paciente ya tiene artritis clínica y un diagnóstico previo.
									</span>
								</div>
								<p></p>
								<span class='modal-test-title txtRed' style="margin-left: 25%">
									Has perdido -25 puntos.
								</span>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>3. Ecografía de carpos<br></span>
										Ecografía de las articulaciones de los carpos.
									</span>
								</div>
							`}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={`
								<div class='d-flex justify-content-center'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Presencia de áreas de enfisema paraseptal. También se identifica retracción del parénquima pulmonar con presencia de reticulación y panalización, siendo compatible con patrón de neumonía intersticial usual.
									</span>
								</div>
								<p></p>
							`}
							showConfirmTest
							html={`
								<div style="margin-top:75px">
									<span class='txtGrey bigTextB txtGrey'>
										<span class='bold'>6. TCAR<br></span>
										Tomografía axial computarizada torácico-pulmonar de alta resolución.
									</span>
								</div>
							`}
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/noeresunrobot'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={3} />
		</div>
	);
}

export default injectIntl(Test);
