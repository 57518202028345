import React from 'react';
import star from '../assets/star.svg';

function Puntos() {
	return (
		<div className='boxPuntos'>
			<img src={star} alt='Star' className='star' /> +300 puntos
		</div>
	);
}
export default Puntos;
