import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import {
	alertError,
	modalAnswer,
	modalPDF,
	modalQuizCompleted,
	modalTest,
} from '../../../utils/logger';
import { useSelector } from 'react-redux';
import folderIcon from '../../../assets/folder-icon.svg';
import checklistIcon from '../../../assets/checklist-icon.svg';
import remember from '../../../assets/remember-icon.svg';
import dialogIcon from '../../../assets/dialog-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import pruebaAdecuadaIcon from '../../../assets/prueba-adecuada-icon.svg';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import pdf from '../../../assets/pdf/4160-MENA-prueba-clinica.pdf';

const quizId = '62d57f662061eb5d07300a89';
const scenarioId = '62d57f662061eb5d07300a87';
let counter = 0;

function Scenario3Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const [PENALTY, setPENALTY] = useState(0);
	const RIGHT_ANSWERS = [0, 2];
	const [checkedAnswers, setCheckedAnswers] = useState([]);
	const [ANSWERS, setANSWERS] = useState([]);
	const PENALTY_ANSWERS = [1, 3];

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Analítica con hemograma, bioquímica, serología de virus, autoinmunidad.',
		'PET-TAC.',
		'Radiografía de manos, hombros, rodillas y pies.',
		'Resonancia magnética de la mano derecha.',
		'Ecografía de manos y hombros.',
		'Análisis de líquido sinovial MOLP, cultivo y bioquímica.',
	];

	function checkAnswer(index) {
		if (RIGHT_ANSWERS.includes(index)) {
			if (!ANSWERS.includes(index)) {
				counter++;
				setANSWERS([...ANSWERS, index]);
			}
			return 'right';
		} else if (PENALTY_ANSWERS.includes(index)) {
			setPENALTY(PENALTY + 25);
			return 'wrong';
		}
		return 'acceptable';
	}

	function answerHandler(index, feedback, extraBtn) {
		let res = checkAnswer(index - 1);
		modalTest({
			type: res,
			title: `<img src=${
				res === 'right'
					? pruebaNecesariaIcon
					: res === 'wrong'
					? pruebaInnecesariaIcon
					: pruebaAdecuadaIcon
			} alt='test' class="modal-test-icon"/>${
				res === 'right'
					? 'Prueba imprescindible'
					: res === 'wrong'
					? 'Prueba innecesaria'
					: `Prueba adecuada <br/> (aunque no imprescindible)`
			}`,
			html: feedback,
			extraBtnText: extraBtn,
		}).then((res) => {
			if (index === 1 && res.isDismissed && res.dismiss === 'cancel')
				modalPDF({ pdf: pdf, title: 'ANALÍTICA' }).then(() => {
					if (counter === 2 && !isCompleted)
						modalQuizCompleted({
							title: '¡Ya has seleccionado las 2 pruebas clave!',
							html: `<p>Pero aún puede haber otras pruebas que aporten información útil.</p>
				<p>¿Quieres seguir haciendo pruebas o consideras que ya tienes la información que necesitas?</p>`,
							confirmText: 'HE ACABADO AQUÍ',
							cancelText: 'HAGO MÁS PRUEBAS',
						}).then((res) => {
							if (res.isConfirmed) {
								submitAnswer();
							} else setIsCompleted(true);
						});
				});
			else {
				if (counter === 2 && !isCompleted)
					modalQuizCompleted({
						title: '¡Ya has seleccionado las 2 pruebas clave!',
						html: `<p>Pero aún puede haber otras pruebas que aporten información útil.</p>
				<p>¿Quieres seguir haciendo pruebas o consideras que ya tienes la información que necesitas?</p>`,
						confirmText: 'HE ACABADO AQUÍ',
						cancelText: 'HAGO MÁS PRUEBAS',
					}).then((res) => {
						if (res.isConfirmed) {
							submitAnswer();
						} else setIsCompleted(true);
					});
			}
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -50) : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 1));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/hackeadoc1',
						});
						navigate('/hackeadoc1');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		} else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='normal'>
							Aún no has solicitado las
							<span class='bold'>
								2 pruebas fundamentales.
							</span>
							<br><br>¡Pide alguna prueba más!
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
				containerClass: 'modal-answer-containerB',
			});
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={`
								<div class='d-flex'>
									<img src=${folderIcon} alt='test'/>
									<span class='txtBlue'>
										Accede a la carpeta de materiales y consulta el documento 
										<span class='txtBlue bold'>«Pruebas clínicas»</span>.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Efectivamente, solicitaríamos analítica completa para estudio de artritis inflamatoria y enfermedad autoinmune sistémica.
									</span>
								</div>
								<p></p>
							`}
							showConfirm
							showExtraBtn={'CONSULTAR ANALÍTICA'}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={`
								<div class='d-flex'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Sinovitis y edema óseo radiocarpiano derecho.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										No parece adecuado solicitar de inicio una resonancia magnética, a no ser que se produzca alguna complicación.
									</span>
								</div>
								<p></p>
								<span class='modal-test-title txtRed' style="margin-left: 25%">
									Has perdido -25 puntos.
								</span>
							`}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={`
								<div class='d-flex'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Captación en hombros y carpo derecho compatible con proceso inflamatorio. Se descarta afectación vascular y proceso neoplásico. Pequeño nódulo tiroideo de aspecto benigno sin captación.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										En este momento, no se justificaría la realización de una PET-TAC, ya que no hay datos clínicos de vasculitis.
									</span>
								</div>
								<p></p>
								<span class='modal-test-title txtRed' style="margin-left: 25%">
									Has perdido -25 puntos.
								</span>
							`}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={`
								<div class='d-flex'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Sinovitis<span class='italics'> doppler</span> grado II en articulación radiocarpiana derecha, bursitis subdeltoidea en hombro derecho con sinovitis glenohumeral bilateral. No se observan imágenes compatibles con depósito de cristales de pirofosfato cálcico.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Podría ayudarnos a detectar sinovitis, bursitis y si hay depósito de cristales.
									</span>
								</div>
								<p></p>
							`}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={`
								<div class='d-flex'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Disminución de espacio articular de predominio en IFD, acromioclavicular, femorotibial, sin erosiones.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Efectivamente, solicitaríamos esta prueba para hacer diagnóstico diferencial con otras afectaciones. Buscaríamos datos compatibles con artrosis, erosiones óseas o condrocalcinosis.
									</span>
								</div>
								<p></p>
							`}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={`
								<div class='d-flex'>
									<img src=${checklistIcon} alt='test'/>
									<span class='txtGrey'>
										Un cristal de colesterol, no otros cristales. Cultivo negativo. Bioquímica del líquido:  leucocitos: 5000/mm<span class='superindex'>3</span>, PMN: 50 %, glucosa: 32 mg/dl.
									</span>
								</div>
								<p></p>
								<div class='d-flex'>
									<img src=${dialogIcon} alt='test'/>
									<span class='txtGrey'>
										Podría ayudarnos a realizar el diagnóstico diferencial con artritis microcristalina e infecciosa.
									</span>
								</div>
								<p></p>
							`}
							showConfirm
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/hackeadoc1'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={3} />
		</div>
	);
}

export default injectIntl(Scenario3Test);
