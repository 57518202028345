import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import backgroundImage from '../../../assets/fondo-16.svg';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-100 pb-5'>
					<div className='col d-flex align-items-center justify-content-center pb-5 mx-5 mb-5'>
						<div className='mx-5 px-5 d-flex flex-column text-left'>
							<div className='title-scenarioB'>
								A continuación aparecerá una batería de 4 imágenes…
							</div>
							<div className='bigTextB py-4 pe-2 me-5'>
								<p>
									Para cada imagen se proponen 4 descripciones, de las cuales
									solo una es correcta. Márcala.
								</p>
								<p>
									Una vez hayas asignado una descripción a las 4 imágenes, pulsa
									el botón <span className='bold'>Confirmar</span> y comprueba
									cuántas has acertado.
								</p>
								<p>
									Dispones de <span className='bold'>4 intentos</span> para
									resolver esta prueba.
								</p>
								<p>
									Si la descripción no es correcta, deberás volver a intentarlo
									hasta agotar los intentos.
								</p>
								<p>
									{'¡Ten cuidado! Cada error se penalizará con '}
									<span className='destacadoRojo bold'>–25 puntos</span>
									{' (hasta un máximo de '}
									<span className='destacadoRojo bold'>–100 puntos</span>).
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario4testc1'} activeCase={1} />
			<img
				className='relojControla'
				style={{ bottom: '0px' }}
				src={backgroundImage}
				alt='Controla tu tiempo'
			/>
			<ScenarioBar activeCase={1} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Intro);
