import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import resultsIcon from '../../../assets/medical-results-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={resultsIcon} alt='Habla con tu paciente' />
										<div className='title-scenario mx-2'>
											Interconsulta con <br />
											el estómago vacío
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp'>
						<div className='container'>
							<div className='row'>
								<div className='col-6'>
									<div className='title4 py-3'>
										Un paréntesis en el caso de Manolo...
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='container'>
							<div className='row justify-content-center'>
								<div className='col-11 p-5 bigText normal'>
									<p>
										Hoy se te presenta un nuevo reto en la consulta de
										reumatología. Visualiza el vídeo y toma nota de este caso
										clínico: tu consejo ayudará a resolver las dudas de tu
										colega y te dará algún punto extra.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/interconsulta3'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={5} expanded />
		</div>
	);
}

export default injectIntl(Intro);
