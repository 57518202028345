import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import treatmentIcon from '../../../assets/treatment-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={treatmentIcon} alt='Tratamiento' />
										<div className='title-scenario mx-2'>Tratamiento</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp'>
						<div className='container'>
							<div className='row'>
								<div className='col-6'>
									<div className='title4 py-2'>
										Ahora ya sabemos que Pilar tiene artritis
										<br />
										reumatoide seronegativa.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='row h-50'>
							<div className='col-12 pb-5 d-flex bigText align-items-center justify-content-center'>
								<div className='normal mb-5 txtWhite'>
									<p className='title1'>
										¿Cuál de los siguientes tratamientos sería el adecuado para
										ella?
									</p>
									<p>
										<span className='title1 txtWhite'>
											¡Acierta a la primera!
										</span>
										{' Si lo haces ganarás '}
										<span className='destacadoVerde title4'>+100 puntos</span>
									</p>
									<p>
										{'Cada diagnóstico incorrecto se penaliza con '}
										<span className='destacadoRojo title4'>–25 puntos</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario7Testc1'} activeCase={1} />
			<ScenarioBar activeCase={1} activeScenario={7} expanded />
		</div>
	);
}

export default injectIntl(Intro);
