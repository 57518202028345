import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../translations/i18n';
import ScenarioBar from '../../components/ScenarioBar';
import reloj from '../../assets/reloj.svg';
import NextButton from '../../components/NextButton';

function ControlaTiempo({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75'>
					<div className='col d-flex align-items-center justify-content-center  pb-5'>
						<div className='text-center'>
							<h1 className='txtBlue'>¡Controla el tiempo!</h1>
							<div className='bigExplains px-5 py-4'>
								<p>
									Si te excedes en <span className='bold'>5 minutos</span>{' '}
									(tardas 45 minutos en llegar al final), se te restará el{' '}
									<span className='bold'>25 % de tu puntuación final</span>, y
									si te excedes en <span className='bold'>10 minutos</span> (50
									minutos de tiempo en total), tu puntuación{' '}
									<span className='bold'>se reducirá a la mitad</span>.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/averiguac1'} activeCase={1} />
			<img className='relojControla' src={reloj} alt='Controla tu tiempo' />
			<ScenarioBar activeCase={1} activeScenario={0} />
		</div>
	);
}

export default injectIntl(ControlaTiempo);
