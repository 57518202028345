import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import mosaic from '../../../assets/mosaic.svg';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid bgYellowLight h-100'>
				<div className='row h-75'>
					<div className='col-11 mx-auto d-flex align-items-center'>
						<div className='mx-4 px-1 w-100'>
							<div className='mx-auto d-flex'>
								<img className='img-fluid' src={mosaic} alt='capcha' />
								<div className='ms-4 bigExplains'>
									Demuestra que no eres un robot relacionando cada imagen de
									TCAR en pacientes con artritis reumatoide con el patrón al que
									corresponde. <br />
									<br />
									<span className='bold'>¡Ten cuidado!</span> Cada error se
									penalizará con{' '}
									<span className='destacadoRojo bold'>–25 puntos</span> (hasta
									un máximo de{' '}
									<span className='destacadoRojo bold'>–100 puntos</span>
									).
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/noeresunrobot4'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={4} />
		</div>
	);
}

export default injectIntl(Intro);
