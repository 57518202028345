import { getDefaultGame, getGameById } from '../api/game';
import { updateGroup } from '../api/group';

export function formatScore(score) {
	if (!score) return '0000';
	else if (score < 0) {
		return (score > -10 ? '-00' : score > -100 ? '-0' : '-') + Math.abs(score);
	} else {
		return (
			(score < 10 ? '000' : score < 100 ? '00' : score < 1000 ? '0' : '') +
			score
		);
	}
}

export function formatScoreTopBar(score) {
	if ((score === undefined) | isNaN(score)) return '0000';
	if (score < 0) {
		return (score > -10 ? '-00' : score > -100 ? '-0' : '-') + Math.abs(score);
	} else {
		return (
			(score < 10 ? '000' : score < 100 ? '00' : score < 1000 ? '0' : '') +
			score
		);
	}
}

export function formatTime(time) {
	if (!time) return ` 0' 00"`;
	else return `${Math.floor(time / 60)}' ${time - Math.floor(time / 60) * 60}"`;
}

export function formatMinutes(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return '00';
	let remaining = (timeout - time) / 60;
	if (remaining <= 0)
		return '-' + (remaining > -9 ? '0' : '') + Math.floor(Math.abs(remaining));
	return (remaining < 10 ? '0' : '') + Math.floor(remaining);
}
export function formatSeconds(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return '00';
	let remaining = (timeout - time) % 60;
	if (remaining < 0) return (remaining > -10 ? '0' : '') + Math.abs(remaining);
	return (remaining < 10 ? '0' : '') + remaining;
}

export function updateTimer(value) {
	localStorage.setItem('total', value);
}

export function getTimer() {
	return localStorage.getItem('total');
}

export function clearItem(item) {
	localStorage.removeItem(item);
}

export function getGroupTable() {
	return localStorage.getItem('groupTable');
}

export function setGroupTable(groupTable) {
	localStorage.setItem('groupTable', groupTable);
}

export async function caseIsActive(gameId, caso) {
	return getGameById(gameId)
		.then((res) => {
			if (res.status === 200) {
				return res.data.clinicCases[caso].status === 'running';
			}
		})
		.catch((error) => {
			return false;
		});
}

export async function updateStats(group) {
	if (!group.conflict) {
		await updateGroup(group?._id, {
			...group,
			[`scoreCase${group.actualCase}`]: group.score,
			[`penaltyCase${group.actualCase}`]: group.penalty,
			[`bonusCase${group.actualCase}`]: group.bonus,
			[`timeCase${group.actualCase}`]: group.time,
		});
	} else await updateGroup(group?._id, group);
}

export function getTimePenalty(time, score) {
	let penalty = 0;
	if (score > 0 && time >= 2400) {
		if (time >= 2700) penalty = score * 0.5;
		else penalty = score * 0.25; //time >= 2400

		if (penalty % 5 === 0 || penalty % 0 === 0) return penalty;
		return Math.round(penalty / 5) * 5;
	}
	return penalty;
}

export function formatClinicCaseName(name) {
	if (name === 'Caso Dra. Mena') return 'caso 1 del Dr. Enigma';
	else return 'caso 2 del Dr. Enigma';
}
