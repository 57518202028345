import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import treatmentIcon from '../../../assets/treatment-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={treatmentIcon} alt='Tratamiento' />
										<div className='title-scenario mx-2'>Tratamiento</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp55'>
						<div className='container'>
							<div className='row'>
								<div className='col-8'>
									<div className='title4 py-3'>
										Ahora ya sabemos que Manolo tiene EPID-AR.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='row h-50 justify-content-center'>
							<div className='col-9 mb-4 pb-5 d-flex bigText align-items-center justify-content-center'>
								<div className='normal mb-5'>
									<p className='title1'>
										¿Cuáles de las siguientes medidas y tratamientos serían
										adecuados para él?
									</p>
									<p>
										Pista: de los 8 tratamientos propuestos, hay 3 que son
										adecuados. Debes marcarlos para avanzar.
									</p>
									<p>
										¡Acierta a la primera! Cada tratamiento incorrecto se
										penaliza con{' '}
										<span className='destacadoRojo title4'>–25 puntos</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario7testc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={7} expanded />
		</div>
	);
}

export default injectIntl(Intro);
