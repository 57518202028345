import axios from 'axios';

const PRO =
	process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pro';

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
	});
};

export const SERVER_URL = PRO
	? 'https://exterior.mtxperiencegame.com:4015'
	: 'http://localhost:4001';

export const API = `${SERVER_URL}/api`;
