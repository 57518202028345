import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import Puntos from '../../../components/Puntos';
import dialog from '../../../assets/dialog.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<Puntos />
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<img src={dialog} alt='Habla con tu paciente' />
										<div className='title-scenario mx-2'>
											Habla con tu paciente
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp'>
						<div className='container'>
							<div className='row'>
								<div className='col-6'>
									<div className='title4 py-2'>
										Es la primera vez que visitas a Manolo...
										<br />
										¿Cuáles de las siguientes preguntas le harás?
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='container'>
							<div className='row'>
								<div className='col-12 py-5 bigText normal'>
									<p>
										¡Escógelas bien! Algunas no te aportarán información útil y
										te harán perder tiempo… pero otras son importantes para
										resolver el caso.
									</p>
									<p>
										Hay <span className='title1'>2 preguntas</span> que son{' '}
										<span className='title1'>fundamentales</span> para saber qué
										le pasa a tu paciente. Podrás avanzar cuando hayas
										visualizado las respuestas a esas 2 preguntas (y, además,
										sumarás{' '}
										<span className='destacadoVerde title4'>+300 puntos</span>).
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario1testc2'} activeCase={2} />
			<ScenarioBar activeCase={2} activeScenario={1} expanded />
		</div>
	);
}

export default injectIntl(Intro);
