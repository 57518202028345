import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import hackerIcon from '../../../assets/hacker-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center'>
						<div className='container'>
							<div className='row justify-content-center'>
								<div className='col-9 pt-2 pb-5'>
									<div className='d-flex text-left'>
										<img src={hackerIcon} alt='Tratamiento' />
										<div className='bigTextB txtBlue mx-3'>
											Vuelves a tu puesto después de ir a buscar un café, pulsas
											una tecla de tu ordenador para iniciar su desbloqueo y te
											aparece el siguiente mensaje:
											<div className='title-scenarioB mt-2'>
												¡¡Malas noticias!!
												<br />
												He hackeado tu ordenador.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow'>
					<div className='col bgYellowToTransp'>
						<div className='container'>
							<div className='row'>
								<div className='col-6'>
									<div className='title4 py-2'>
										Solo puedes recuperar su control de una de estas dos formas
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='row mt-4'>
							<div className='col-12 d-flex bigText align-items-center justify-content-center'>
								<div className='col-8 normal mb-5'>
									<div className='d-flex justify-content-evenly'>
										<div className='text-center d-flex flex-column align-items-center'>
											<div className='sphere titleSphere d-flex align-items-center justify-content-center'>
												A
											</div>
											<span className='mt-2'>
												Ingresando en mi cuenta <br />
												una cantidad de 6 cifras
												<br /> en criptomonedas.
											</span>
										</div>
										<div className='text-center d-flex flex-column align-items-center'>
											<div className='sphere-filled titleSphere d-flex align-items-center justify-content-center'>
												B
											</div>
											<span className='mt-2'>
												Superando una <br /> «pequeña prueba»
											</span>
										</div>
									</div>
								</div>
							</div>
							<p className='title1 mx-5'>
								Decides intentarlo con la opción de la «pequeña prueba».
							</p>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario4introc1'} activeCase={1} />
			<ScenarioBar activeCase={1} activeScenario={4} expanded />
		</div>
	);
}

export default injectIntl(Intro);
