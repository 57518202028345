import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import {
	alertError,
	modalQuizCompleted,
	modalAudio,
	modalAnswer,
} from '../../../utils/logger';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';
import remember from '../../../assets/remember-icon.svg';

const quizId = '62d576f171914e59859bbbd8';
const scenarioId = '62d576f171914e59859bbbd6';
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const RIGHT_ANSWERS = [0, 9];
	const [checkedAnswers, setCheckedAnswers] = useState([]);
	const [ANSWERS, setANSWERS] = useState([]);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'¿Cuándo comenzó con el dolor y la sensación de inflamación?',
		'¿Ha notado dolor o inflamación en las lumbares que le despertara por las noches?',
		'¿Ha notado si últimamente tiene más dificultades para respirar o tragar?',
		'¿Ha tenido fiebre?',
		'¿Ha tenido dolores de cabeza intensos o diferentes de lo habitual en las últimas semanas?',
		'¿Tiene dificultad para peinarse por las mañanas?',
		'¿Se alivia el tratamiento con medicamentos como paracetamol o metimazol?',
		'¿Se alivia el dolor cuando toma medicamentos como ibuprofeno (lo que los médicos llamamos AINE)?',
		'¿Ha notado si tiene más dificultad para levantarse por las mañanas por dolor en la cintura o las caderas?',
		'¿Ha tenido alguna vez lesiones en la piel, rojeces, inflamaciones…? ¿Padece psoriasis?',
	];
	const FEEDBACKS = [
		'El dolor y la inflamación de los hombros empezaron hace unos 3 meses; después se fue añadiendo la mano… y el resto.',
		'No. Y me cuesta mucho dormir, pero no es por las lumbares.',
		'No. Yo respiro bien y tragar, trago sin problemas. Lo que pasa es que, con esto del dolor, tengo muy poquita hambre.',
		'Sí que he tenido ratos como de estar destemplada. Pero no sé si he tenido fiebre porque nunca me he puesto el termómetro.',
		'Hace años que tengo migraña y mi dolor de cabeza es siempre el mismo.',
		'Tengo dificultades para peinarme, para levantarme de la cama, para preparar el desayuno… ¡para todo!',
		'No… Casi nada, la verdad. Son como si tomara agua.',
		'Sí, un poquito, pero el efecto no dura mucho y luego el dolor vuelve.',
		'Ahora que lo dice, sí que noto algo de dolor ahí, pero es muy poca cosa… O igual es que los hombros me duelen mucho…',
		'Pues ahora que lo pregunta, resulta que tengo rosácea, pero mi médico de cabecera la va controlando y de momento no me da muchos problemas.',
	];

	function checkAnswer(index) {
		let correct = RIGHT_ANSWERS.includes(index);
		if (!checkedAnswers.includes(index)) {
			setCheckedAnswers([...checkedAnswers, index]);
			if (correct) {
				if (!ANSWERS.includes(index)) {
					counter++;
					setANSWERS([...ANSWERS, index]);
				}
				return 'right';
			}
			return 'selected';
		}
		return correct ? 'right' : 'selected';
	}

	async function answerHandler(index) {
		let res = checkAnswer(index - 1);
		await modalAudio({
			title: `${index}. ${QUESTIONS[index - 1]}`,
			html: FEEDBACKS[index - 1],
		}).then(() => {
			if (counter === 2 && !isCompleted) {
				modalQuizCompleted({
					title: '¡Ya le has hecho a tu paciente las 2 preguntas clave!',
					html: `<p>Pero aún puede haber otras preguntas que aporten información útil.</p>
<p>¿Quieres seguir preguntando o prefieres dar por finalizada la visita de tu paciente?</p>
<p>Elijas lo que elijas, ya tienes <span class="modal-points">+300 puntos.</span></p>`,
					confirmText: 'ACABO LA VISITA',
					cancelText: 'SIGO PREGUNTANDO',
				}).then((res) => {
					if (res.isConfirmed) {
						submitAnswer();
					} else setIsCompleted(true);
				});
			}
		});
		return res;
	}

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		if (await caseIsActive(gameId, 0)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: 300,
				time: time,
			})
				.then(async (res) => {
					if (res.status === 201) {
						dispatch(updateScore(300, '', 1));
						updateStats({
							...group,
							score: group.score + 300,
							time: totalTime,
							lastVisited: '/intranet',
						});
						navigate('/intranet');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
				finishedCase1: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		} else {
			modalAnswer({
				html: `
					<div class='d-flex'>
						<img src=${remember} alt='test'/>
						<span class='normal'>
							Aún no has hecho a Pilar las
							<span class='bold'>
								2 preguntas fundamentales.
							</span>
							<br><br>¡Sigue preguntando!
						</span>
					</div>
					`,
				btnText: 'SEGUIR',
				containerClass: 'modal-answer-containerB',
			});
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-100'>
				<div className='row mt-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={FEEDBACKS[0]}
							showSelectedIndicator
							loadingStatus={'right'}
							selected={checkedAnswers.includes(0)}
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={FEEDBACKS[5]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={FEEDBACKS[1]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={7}
							text={QUESTIONS[6]}
							feedback={FEEDBACKS[6]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={FEEDBACKS[2]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={8}
							text={QUESTIONS[7]}
							feedback={FEEDBACKS[7]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={FEEDBACKS[3]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={9}
							text={QUESTIONS[8]}
							feedback={FEEDBACKS[8]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={FEEDBACKS[4]}
							showSelectedIndicator
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={10}
							text={QUESTIONS[9]}
							feedback={FEEDBACKS[9]}
							showSelectedIndicator
							loadingStatus={'right'}
							selected={checkedAnswers.includes(9)}
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/intranet'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'AVANZAR'}
			</Link>
			<ScenarioBar activeCase={1} activeScenario={1} />
		</div>
	);
}

export default injectIntl(Test);
