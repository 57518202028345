import React, { useState } from 'react';
import icochevrondown from '../assets/scenariobar/ico-chevrondown.svg';
import icochevronup from '../assets/scenariobar/ico-chevronup.svg';
import icocompleted from '../assets/scenariobar/ico-completed.svg';
import icolockclosed from '../assets/scenariobar/ico-lockclosed.svg';
import icolockopened from '../assets/scenariobar/ico-lockopened.svg';
import icovideo from '../assets/scenariobar/ico-video.svg';
import icopruebasclinicas from '../assets/scenariobar/ico-pruebasclinicas.svg';
import icopruebasclinicaswhite from '../assets/scenariobar/ico-pruebasclinicas-white.svg';
import icodiagnostico from '../assets/scenariobar/ico-diagnostico.svg';
import icodiagnosticowhite from '../assets/scenariobar/ico-diagnostico-white.svg';
import icotratamientos from '../assets/scenariobar/ico-tratamientos.svg';
import icotratamientoswhite from '../assets/scenariobar/ico-tratamientos-white.svg';
import icohacker from '../assets/scenariobar/ico-hacker.svg';
import icohackerwhite from '../assets/scenariobar/ico-hacker-white.svg';
import icogenetica from '../assets/scenariobar/ico-genetica.svg';
import icogeneticawhite from '../assets/scenariobar/ico-genetica-white.svg';
import icointranet from '../assets/scenariobar/ico-intranet.svg';
import icointranetwhite from '../assets/scenariobar/ico-intranet-white.svg';
import icopregunta from '../assets/scenariobar/ico-pregunta.svg';
import icopreguntawhite from '../assets/scenariobar/ico-pregunta-white.svg';
import icofolleto from '../assets/scenariobar/ico-folleto.svg';
import icofolletowhite from '../assets/scenariobar/ico-folleto-white.svg';
import icorobot from '../assets/scenariobar/ico-robot.svg';
import icorobotwhite from '../assets/scenariobar/ico-robot-white.svg';
import icoinforme from '../assets/scenariobar/ico-informe.svg';
import icoinformewhite from '../assets/scenariobar/ico-informe-white.svg';

function ScenarioBar({ activeCase, activeScenario, expanded }) {
	const [scenariomenuOpen, setScrenariomenuOpen] = useState(expanded || false);

	function handleClick(e) {
		if (!scenariomenuOpen) setScrenariomenuOpen(true);
		else setScrenariomenuOpen(false);
	}

	const getClasses = (idx) => {
		if (idx < activeScenario) return 'bgBlue text-white';
		else if (idx === activeScenario) return 'bgGreyDark';
		return '';
	};

	const getIcon = (idx) => {
		if (idx === activeScenario) return icolockopened;
		else if (idx < activeScenario) return icocompleted;
		return icolockclosed;
	};

	return (
		<div
			className={
				scenariomenuOpen
					? 'scenarioBar py-2 px-3 active'
					: 'scenarioBar py-2 px-3 inactive'
			}>
			<div className='buttonDown' onClick={handleClick}>
				{scenariomenuOpen && <img src={icochevrondown} alt='Esconder menú' />}
				{!scenariomenuOpen && <img src={icochevronup} alt='Mostrar menú' />}
				{/* <div>Escenarios</div> */}
			</div>
			<div className='row h-100 align-items-center'>
				{!scenariomenuOpen && (
					<div className='scenarioIndicator'>Escenarios</div>
				)}

				<div className='col px-2'>
					<div
						className={`scenarioBox ${getClasses(
							0
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img src={icovideo} alt='Video de contexto' />
							<div className='mt-1'>Video de contexto</div>
						</div>
						<div className='lock'>
							<img src={getIcon(0)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							1
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={1 <= activeScenario ? icopreguntawhite : icopregunta}
								alt='Video de contexto'
							/>
							<div className='mt-1'>
								{activeCase === 1
									? 'Pregunta al paciente'
									: 'Habla con tu paciente'}
							</div>
						</div>
						<div className='lock'>
							<img src={getIcon(1)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							2
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={
									activeCase === 1
										? 2 <= activeScenario
											? icointranetwhite
											: icointranet
										: 2 <= activeScenario
										? icofolletowhite
										: icofolleto
								}
								alt='Video de contexto'
							/>
							<div className='mt-1'>
								{activeCase === 1
									? 'Consulta la intranet'
									: 'Un folleto muy oportuno'}
							</div>
						</div>
						<div className='lock'>
							<img src={getIcon(2)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							3
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={
									3 <= activeScenario
										? icopruebasclinicaswhite
										: icopruebasclinicas
								}
								alt='Video de contexto'
							/>
							<div className='mt-1'>Pruebas clínicas</div>
						</div>
						<div className='lock'>
							<img src={getIcon(3)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							4
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={
									activeCase === 1
										? 4 <= activeScenario
											? icohackerwhite
											: icohacker
										: 4 <= activeScenario
										? icorobotwhite
										: icorobot
								}
								alt='Video de contexto'
							/>
							<div className='mt-1'>
								{activeCase === 1
									? 'Enfréntate a un virus'
									: 'Demuestra que no eres un robot'}
							</div>
						</div>
						<div className='lock'>
							<img src={getIcon(4)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							5
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-1'>
							<img
								src={
									activeCase === 1
										? 5 <= activeScenario
											? icogeneticawhite
											: icogenetica
										: 5 <= activeScenario
										? icoinformewhite
										: icoinforme
								}
								alt='Video de contexto'
							/>
							<div className='mt-1'>
								{activeCase === 1
									? 'Con la genética hemos topado'
									: 'Interconsulta con estómago vacío'}
							</div>
						</div>
						<div className='lock'>
							<img src={getIcon(5)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							6
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={6 <= activeScenario ? icodiagnosticowhite : icodiagnostico}
								alt='Video de contexto'
							/>
							<div className='mt-1'>Diagnóstico</div>
						</div>
						<div className='lock'>
							<img src={getIcon(6)} alt='Video de contexto' />
						</div>
					</div>
				</div>
				<div className='col px-2'>
					<div
						className={`scenarioBox  ${getClasses(
							7
						)} d-flex align-items-center justify-content-center`}>
						<div className='text-center px-2'>
							<img
								src={
									7 <= activeScenario ? icotratamientoswhite : icotratamientos
								}
								alt='Video de contexto'
							/>
							<div className='mt-1'>Tratamiento</div>
						</div>
						<div className='lock'>
							<img src={getIcon(7)} alt='Video de contexto' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ScenarioBar;
