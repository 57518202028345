import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { postAnswer } from '../../../api/answer';
import { useLang } from '../../../translations/i18n';
import ScenarioBar from '../../../components/ScenarioBar';
import BoxPreguntaTest from '../../../components/BoxPreguntaTest';
import { alertError, modalDiagnostic } from '../../../utils/logger';
import { useSelector } from 'react-redux';
import paperIcon from '../../../assets/diagnostic-paper-icon.svg';
import pruebaNecesariaIcon from '../../../assets/prueba-necesaria-icon.svg';
import pruebaInnecesariaIcon from '../../../assets/prueba-innecesaria-icon.svg';
import { useDispatch } from 'react-redux';
import { updateScore, updateTime } from '../../../redux/actions';
import { useTimer } from '../../../hooks/useTimer';
import {
	caseIsActive,
	getTimePenalty,
	getTimer,
	updateStats,
} from '../../../utils/helpers';

const quizId = '6310cb790e9a26315a83339a';
const scenarioId = '62d7fd2bb260ea1d73de0d3a';
let counter = 0;

function Test({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const group = useSelector((state) => state.group);
	const gameId = useSelector((state) => state.game)?._id;
	const [isCompleted, setIsCompleted] = useState(false);
	const [time, setTime] = useState(0);

	const [PENALTY, setPENALTY] = useState(0);
	const [ANSWERS, setANSWERS] = useState([]);
	const RIGHT_ANSWERS = [1, 2, 6];

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const QUESTIONS = [
		'Añadir micofenolato.',
		'Añadir abatacept.',
		'Añadir rituximab.',
		'Retirar metotrexato.',
		'Añadir anti-TNF.',
		'Añadir antifibrótico.',
		'Añadir rituximab y antifibrótico.',
		'Añadir tofacitinib.',
	];

	const FEEDBACKS = [
		`<span>El micofenolato sería una buena opción para tratar el parénquima pulmonar, pero el enfermo también presenta clínica articular (y es ineficaz en esta esfera).</span>`,
		`<span>Abatacept es uno de los fármacos cuyo uso se recomienda en caso de que se requiera intensificar el tratamiento de la artritis en pacientes con EPID<span class='superindex'>2</span>.</span>`,
		`<span>Rituximab ha demostrado eficacia para tratar la afección articular rebelde a metotrexato, además de ser útil en pacientes con EPID asociada a enfermedades reumáticas. Probablemente, sería la primera opción que se debería considerar<span class='superindex'>3</span>.</span>`,
		`<span>Si bien existe un riesgo de desarrollar neumonitis por metotrexato al inicio de su uso, parece que no hay asociación entre la toma de metotrexato y la aparición de EPID. Además, se ha observado una relación inversa entre la exposición a metotrexato en pacientes con AR y el desarrollo de EPID, probablemente al conseguir un mejor control global de la enfermedad<span class='superindex'>2-4</span>. <br><br> En los casos de EPID-AR, el control adecuado de la artritis reumatoide también ayuda a un mejor pronóstico de la EPID, por lo que metotrexato no tendría que retirarse. Además, en el caso de añadir rituximab para tratar la EPID, es plausible pensar que metotrexato tendría un efecto adyuvante sinérgico en el control de la enfermedad inflamatoria sistémica<span class='superindex'>2-4</span>.</span>`,
		`<span>Si bien sería una opción adecuada para tratar la artritis reumatoide, no tendría efecto en la EPID e incluso podría exacerbarla.</span>`,
		`<span>Buena opción para las EPID fibrosantes progresivas; sin embargo, en el caso actual, no nos sirve para intensificar la respuesta a nivel articular.</span>`,
		`<span>Probablemente, la mejor opción desde todos los puntos de vista. Sin embargo, resulta difícil de justificar en la situación actual en nuestro país<span class='superindex'>2</span>.</span>`,
		`<span>Si bien sería una opción adecuada para tratar la artritis reumatoide, no se le conoce efecto en la EPID.</span>`,
	];

	const formatFeedback = (index, correct) => {
		return `
		<div class='d-flex'>
			<img src=${paperIcon} alt='test' class='align-self-start'/>
			${FEEDBACKS[index]}
		</div>
		<p></p>
		<div class='d-flex justify-content-center'>
		
			${
				correct
					? `
					 <img src=${pruebaNecesariaIcon} alt='test'/>
					<div class='d-flex flex-column justify-content-center align-items-center'>
						<span class='modal-diagnostic-title txtGreen' >
							¡Muy bien! Este es uno de <br> los tratamientos adecuados!
						</span>
					</div>`
					: `
					<img src=${pruebaInnecesariaIcon} alt='test'/>
					<span class='modal-diagnostic-title txtRed' >
						Este tratamiento no es adecuado. <br>	
						Has perdido -25 puntos.
					</span>	
			`
			}
		</div>
		`;
	};

	function checkAnswer(index) {
		if (RIGHT_ANSWERS.includes(index)) {
			if (!ANSWERS.includes(index)) {
				counter++;
				setANSWERS([...ANSWERS, index]);
			}
			return 'right';
		} else {
			setPENALTY(PENALTY + 25);
			return 'wrong';
		}
	}

	function answerHandler(index, feedback) {
		let res = checkAnswer(index - 1);
		modalDiagnostic({
			html: feedback,
			containerClass: 'modal-diagnostic-containerB py-4',
			btnText: res === 'right' ? 'AVANZAR' : 'INTENTAR DE NUEVO',
		}).then((result) => {
			if (counter === 3 && result.isConfirmed) submitAnswer();
		});
		return res;
	}

	async function submitAnswer() {
		const finalScore = PENALTY ? Math.max(-Math.abs(PENALTY), -125) : 0;
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 2));
		if (await caseIsActive(gameId, 1)) {
			postAnswer({
				scenario: scenarioId,
				quiz: quizId,
				group: group?._id,
				score: finalScore,
				time: time,
			})
				.then((res) => {
					if (res.status === 201) {
						dispatch(updateScore(finalScore, PENALTY ? 'PENALTY' : '', 2));
						updateStats({
							...group,
							score: group.score + finalScore,
							penalty: group.penalty + finalScore,
							time: totalTime,
							lastVisited: '/ganamaspuntosc2',
						});
						navigate('/ganamaspuntosc2');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Ya has respondido este escenario!',
					});
				});
		} else {
			let timePenalty = getTimePenalty(group.time, group.score);
			dispatch(updateScore(-timePenalty, 'PENALTY', group.actualCase));
			updateStats({
				...group,
				score: group.score - timePenalty,
				penalty: group.penalty - timePenalty,
				time: totalTime,
				lastVisited: '/gameover',
				finished: true,
			});
			navigate('/gameover');
		}
	}

	function handleNext(e) {
		e.preventDefault();
		if (isCompleted) {
			submitAnswer();
		}
	}

	return (
		<div className='h-100'>
			<div className='container h-75 d-flex align-items-center justify-content-center'>
				<div className='row mb-5'>
					<div className='col-6'>
						<BoxPreguntaTest
							index={1}
							text={QUESTIONS[0]}
							feedback={formatFeedback(0)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={5}
							text={QUESTIONS[4]}
							feedback={formatFeedback(4)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={2}
							text={QUESTIONS[1]}
							feedback={formatFeedback(1, true)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={6}
							text={QUESTIONS[5]}
							feedback={formatFeedback(5)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={3}
							text={QUESTIONS[2]}
							feedback={formatFeedback(2, true)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={7}
							text={QUESTIONS[6]}
							feedback={formatFeedback(6, true)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={4}
							text={QUESTIONS[3]}
							feedback={formatFeedback(3)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
					<div className='col-6'>
						<BoxPreguntaTest
							index={8}
							text={QUESTIONS[7]}
							feedback={formatFeedback(7)}
							showConfirm
							handler={answerHandler}
						/>
					</div>
				</div>
			</div>
			<Link
				to='/ganamaspuntosc2'
				className={`btn-trap btn-next ${isCompleted ? 'bgYellow' : 'bgGrey'}`}
				onClick={handleNext}>
				{'COMPROBAR'}
			</Link>
			<ScenarioBar activeCase={2} activeScenario={7} />
		</div>
	);
}

export default injectIntl(Test);
