import React from 'react';
import { injectIntl } from 'react-intl';
import { useLang } from '../../../translations/i18n';
import adnIcon from '../../../assets/adn-icon.svg';
import ScenarioBar from '../../../components/ScenarioBar';
import NextButton from '../../../components/NextButton';

function Intro({ intl }) {
	const lang = useLang();

	return (
		<div className='h-100'>
			<div className='container-fluid pt-5 h-100'>
				<div className='row'>
					<div className='col text-center py-5'>
						<div className='container'>
							<div className='row'>
								<div className='col pt-2 pb-5'>
									<div className='d-flex align-items-center justify-content-center'>
										<div className='title-scenario mx-2'>
											Con la genética
											<br />
											hemos topado
										</div>
										<img src={adnIcon} alt='Diagnostico' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row bluerow h-100'>
					<div className='col'>
						<div className='row h-50 justify-content-center'>
							<div className='col-8 py-5 d-flex bigText align-items-center justify-content-center'>
								<div className='normal mb-5 d-flex flex-column align-items-center'>
									<p>
										Un compañero del hospital ha leído un artículo sobre el
										componente genético de las enfermedades reumáticas y tiene
										algunas dudas que quiere consultar contigo.
									</p>
									<div className='d-flex txtYellow text-center italics m-3'>
										<span className='quote align-self-start'>"</span>
										<span className='normal bigText mx-3'>
											Sé que todas las afirmaciones siguientes <br />
											son falsas –te comenta–, excepto una… <br />
											¿Tú sabes cuál es la verdadera?
										</span>
										<span className='quote align-self-end '>"</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NextButton navigateTo={'/scenario5testc1'} activeCase={1} />
			<ScenarioBar activeCase={1} activeScenario={5} expanded />
		</div>
	);
}

export default injectIntl(Intro);
